import { FiberNew, Iso, CallSplit } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EventIcon from "@mui/icons-material/Event";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CardActionArea,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from "@mui/material";
import {
  ProductCard,
  ProgressButton,
  ConfirmationModal,
  formatUtcDate,
  DatePicker,
  useToast
} from "@qubit/autoparts";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { warehouseService } from "~/api/warehouse";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import {
  checkIsExpiration,
  generateLocationNameFromBin,
  inventoryDateLabel,
  getInventoryDateObj,
  getInventoryDateObjDayjs
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import { usePatchInventoryNetAdjustmentMutation } from "~/redux/warehouse/inventory.hooks";
import {
  useGetPutawayTasksQuery,
  usePostPutItemAwayMutation
} from "~/redux/warehouse/putAwayTasks.hooks";
import { PutAwayTaskSummaryDto, SearchBinRecord } from "~/types/api";

import { PutawayAdjustModal } from "./PutawayAdjustModal";
import { clearSelectedPutawayTasks } from "./putaway.slice";

export type PutawayProps = { viewTitle?: ViewNameTranslation };

export const PutawayTask = (props: PutawayProps) => {
  const { viewTitle } = props;

  const dispatch = useAppDispatch();
  const { variantId } = useParams<{ variantId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const {
    data: putawayInventoryByVariant,
    isLoading,
    isFetching
  } = useGetPutawayTasksQuery(
    { variantId, status: "scheduled" },
    { refetchOnMountOrArgChange: true }
  );

  const [putAwayItem] = usePostPutItemAwayMutation();

  const inv_inventoryDateLabel = useAppSelector(
    (state) => state.site.clientConfig.inv_inventoryDateLabel
  );
  const workstationId = useAppSelector(selectWorkstationId);

  const [adjustInventoryNet] = usePatchInventoryNetAdjustmentMutation();

  useNavbar({ viewTitle });

  // active putaway is task being operated on (skip, partial, new bin etc)
  const [activePutaway, setActivePutaway] =
    useState<PutAwayTaskSummaryDto | null>(null);
  const [partialQtyModalOpen, setPartialQtyModalOpen] =
    useState<boolean>(false);
  const [partialQuantity, setPartialQuantity] = useState<number | null>(null);
  const [newBinModalOpen, setNewBinModalOpen] = useState<boolean>(false);
  const [newBinLocation, setNewBinLocation] = useState<string | null>(null);
  const [inventoryDate, setInventoryDate] = useState<Date>(
    moment().utc().add(1, "year").toDate()
  );
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [putawayForAdjustModal, setPutawayForAdjustModal] =
    useState<PutAwayTaskSummaryDto | null>(null);

  const handleAdjustExpiration = async (date: Dayjs | null) => {
    if (!activePutaway || !date) return;
    const countObj = { units: activePutaway.quantity.units, value: 0 };

    try {
      await adjustInventoryNet({
        netAdjustment: countObj,
        netCommittedAdjustment: countObj,
        inventoryId: activePutaway.inventoryId || "",
        reasonCode: "Expiration Corrected",
        ...getInventoryDateObjDayjs(inv_inventoryDateLabel, date),
        workstationId
      }).unwrap();

      successToast(t("inventory adjusted"));

      setDatePickerIsOpen(false);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  useEffect(() => {
    if (!putawayInventoryByVariant?.length && !isFetching) {
      dispatch(clearSelectedPutawayTasks());
      navigate("/putaway");
    }
  }, [dispatch, isFetching, navigate, putawayInventoryByVariant]);

  // happy path putaway
  const handleInventoryPutaway = async (putaway: PutAwayTaskSummaryDto) => {
    if (putaway.target && putaway.inventoryId) {
      try {
        const invDate = getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(
            checkIsExpiration(inv_inventoryDateLabel)
              ? putaway.expirationDate
              : putaway.manufactureDate
          )
        );
        await putAwayItem({
          putAwayTaskId: putaway.putAwayTaskId,
          request: {
            quantity: putaway.remaining,
            targetBinId: putaway.target.bin.binId,
            targetPort: undefined,
            targetExpiration: invDate.expiration,
            targetManufactureDate: invDate.manufactureDate,
            workstationId
          }
        }).unwrap();

        successToast(t("putaway complete"));

        setIsConfirmationModalOpen(false);
        setActivePutaway(null);

        dispatch(clearSelectedPutawayTasks());

        navigate("/putaway");
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    } else if (!putaway.target) {
      errorToast("Putaway task does not have a target.");
    } else if (!putaway.inventoryId) {
      errorToast("Putaway task does not have an Inventory ID.");
    }
  };

  const handlePartialPutaway = async () => {
    if (activePutaway && activePutaway.inventoryId && activePutaway.target) {
      try {
        const invDate = getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(
            checkIsExpiration(inv_inventoryDateLabel)
              ? activePutaway.expirationDate
              : activePutaway.manufactureDate
          )
        );
        await putAwayItem({
          putAwayTaskId: activePutaway.putAwayTaskId,
          request: {
            quantity: {
              units: activePutaway.remaining.units,
              value: partialQuantity || 0
            },
            targetBinId: activePutaway.target.bin.binId,
            targetPort: undefined,
            targetExpiration: invDate.expiration,
            targetManufactureDate: invDate.manufactureDate,
            workstationId
          }
        }).unwrap();

        successToast(t("putaway complete"));

        setPartialQtyModalOpen(false);
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    }
  };

  const handleNewBinPutaway = async () => {
    const binSearch = await warehouseService.get<SearchBinRecord[]>(
      `/api/bins/search/${newBinLocation || ""}`
    );
    const binRecords = binSearch.data;
    const bin = binRecords && binRecords.length === 1 ? binRecords[0] : null;
    if (!bin) {
      errorToast("No Bin Found");
    }

    if (!activePutaway || !bin) return;

    try {
      const invDate = getInventoryDateObj(
        inv_inventoryDateLabel,
        moment(
          checkIsExpiration(inv_inventoryDateLabel)
            ? activePutaway.expirationDate
            : activePutaway.manufactureDate
        )
      );
      await putAwayItem({
        putAwayTaskId: activePutaway.putAwayTaskId,
        request: {
          quantity: {
            units: activePutaway.remaining.units,
            value: activePutaway.remaining.value
          },
          targetBinId: bin.bin_id,
          targetPort: undefined,
          targetExpiration: invDate.expiration,
          targetManufactureDate: invDate.manufactureDate,
          workstationId
        }
      }).unwrap();

      successToast(t("putaway complete"));

      dispatch(clearSelectedPutawayTasks());
      navigate("/putaway");
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  useKeyDownHandler();
  useBarcodeScanner<PutAwayTaskSummaryDto | false>({
    findScanMatch: (buffer: string) => {
      if (newBinModalOpen) {
        // user is scanning new bin
        setNewBinLocation(buffer);
        return false;
      } // user is scanning target bin provided by warehouse
      if (partialQtyModalOpen && !partialQuantity) {
        errorToast("Set quantity before scanning bin");
        return false;
      }
      const putawayMatch = putawayInventoryByVariant?.find((putaway) => {
        const target = putaway.target || null;
        return (
          target &&
          generateLocationNameFromBin(target.bin)
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "") === buffer.toLowerCase()
        );
      });
      return putawayMatch || false;
    },
    processScanMatch: async (match) => {
      if (match) {
        if (partialQtyModalOpen) {
          await handlePartialPutaway();
        } else {
          await handleInventoryPutaway(match);
        }
      }
    },
    deps: [
      inventoryDate,
      putawayInventoryByVariant,
      newBinModalOpen,
      partialQtyModalOpen,
      partialQuantity
    ]
  });

  return (
    <>
      <Container sx={{ my: 2 }}>
        {putawayInventoryByVariant &&
          !isLoading &&
          putawayInventoryByVariant.map((putaway: PutAwayTaskSummaryDto, i) => {
            const targetBin = (!!putaway.target && putaway.target.bin) || null;

            return (
              <Box key={`${putaway.inventoryId || ""}-${i}`} my={3}>
                <ProductCard.ProductCardContainer sx={{ pb: 2 }}>
                  <CardHeader
                    avatar={
                      <ProductCard.CardAvatar
                        quantity={putaway.remaining.value}
                        quantityIconVariant="filled"
                        unitOfMeasure={putaway.remaining.units}
                      />
                    }
                    title={putaway.product.name}
                    subheader={
                      <>
                        <Typography variant="body2">
                          {putaway.product.brandName}
                        </Typography>
                        {putaway.purchaseOrderLineItem?.purchaseOrderNumber && (
                          <Typography variant="body2">
                            {putaway.purchaseOrderLineItem.purchaseOrderNumber}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  <ProductCard.BinLocation bin={targetBin} />
                  <CardActionArea
                    onClick={(): void => {
                      if (targetBin) {
                        setActivePutaway(putaway);
                        setIsConfirmationModalOpen(true);
                      }
                    }}
                    style={{ height: 220 }}
                    data-testid="card-action"
                  >
                    <ProductCard.ProductImage
                      imageFilename={putaway.product.imageFilename}
                      modalStatus="none"
                      modalStatusText={null}
                      upc={putaway.product.upc}
                      sku={putaway.product.sku}
                      data-testid={`product-image-${i}`}
                    />
                  </CardActionArea>
                  <Container sx={{ backgroundColor: "background.default" }}>
                    <Typography
                      variant="overline"
                      color="initial"
                      component="p"
                      align="center"
                    >
                      {` ${t(inventoryDateLabel(inv_inventoryDateLabel))}: ${
                        putaway.manufactureDate || putaway.expirationDate
                          ? formatUtcDate(
                              putaway.manufactureDate || putaway.expirationDate
                            )
                          : "No date set"
                      }`}
                    </Typography>
                  </Container>
                  <BottomNavigation showLabels sx={{ marginTop: 1 }}>
                    <BottomNavigationAction
                      label={t("change quantity")}
                      icon={<Iso />}
                      onClick={() => setPutawayForAdjustModal(putaway)}
                    />
                    {targetBin && (
                      <BottomNavigationAction
                        label={t("split putaway")}
                        icon={<CallSplit />}
                        onClick={() => {
                          setActivePutaway(putaway);
                          setPartialQtyModalOpen(true);
                        }}
                      />
                    )}
                    <BottomNavigationAction
                      label={t("select new bin")}
                      icon={<FiberNew />}
                      onClick={() => {
                        setActivePutaway(putaway);
                        setNewBinModalOpen(true);
                      }}
                    />
                    <BottomNavigationAction
                      label={t(
                        inv_inventoryDateLabel === "manufacture"
                          ? `change manufacture`
                          : `change expiration`
                      )}
                      icon={<EventIcon />}
                      onClick={() => {
                        setActivePutaway(putaway);
                        setDatePickerIsOpen(true);
                      }}
                    />
                  </BottomNavigation>
                </ProductCard.ProductCardContainer>
              </Box>
            );
          })}
      </Container>

      {/* Partial Putaway Modal */}
      <Dialog
        key="partial-putaway-dialog"
        open={!!activePutaway && partialQtyModalOpen}
        onClose={() => {
          setPartialQtyModalOpen(false);
          setActivePutaway(null);
        }}
      >
        <DialogTitle>{t("split putaway")}</DialogTitle>
        <DialogContent sx={{ textAlign: "center", mt: 1 }}>
          <Box maxWidth="300px">
            <FormControl style={{ margin: 10 }}>
              <TextField
                inputProps={{
                  id: "putaway-qty"
                }}
                variant="outlined"
                type="number"
                label={t("putaway qty")}
                value={partialQuantity || ""}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  if ((e.target.value as number) < 0) {
                    setPartialQuantity(0);
                  } else if (
                    activePutaway?.remaining.value &&
                    (e.target.value as number) > activePutaway?.remaining.value
                  ) {
                    setPartialQuantity(activePutaway?.remaining.value);
                  } else {
                    setPartialQuantity(e.target.value as number);
                  }
                }}
              />
            </FormControl>
            <Chip
              label={t("scan bin confirm")}
              disabled={
                !!(
                  partialQuantity &&
                  activePutaway &&
                  partialQuantity >= (activePutaway.remaining.value || 0)
                )
              }
              icon={<DoneIcon />}
              color="1"
              style={{ margin: 2 }}
              onClick={handlePartialPutaway}
            />
          </Box>
        </DialogContent>
      </Dialog>

      {/* New Bin Location Modal */}
      <Dialog
        key="new-bin-dialog"
        open={!!activePutaway && newBinModalOpen}
        onClose={() => {
          setNewBinModalOpen(false);
          setActivePutaway(null);
        }}
      >
        <DialogTitle>{t("new bin")}</DialogTitle>
        <DialogContent sx={{ textAlign: "center", mt: 1 }}>
          <FormControl style={{ margin: 10 }}>
            <TextField
              variant="outlined"
              type="text"
              value={newBinLocation}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setNewBinLocation(e.target.value as string);
              }}
              placeholder={t("scan new bin")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ProgressButton
            autoFocus
            onClick={() => {
              setNewBinModalOpen(false);
              setActivePutaway(null);
            }}
            color="secondary"
            startIcon={<CloseIcon />}
            buttonSize="medium"
            emphasis="high"
            responsive
            fullWidth
          >
            {t("cancel")}
          </ProgressButton>
          <ProgressButton
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              handleNewBinPutaway();
              setNewBinModalOpen(false);
              setActivePutaway(null);
            }}
            color="primary"
            autoFocus
            startIcon={<CheckIcon />}
            buttonSize="medium"
            emphasis="high"
            responsive
            fullWidth
          >
            {t("confirm")}
          </ProgressButton>
        </DialogActions>
      </Dialog>

      {/* Adjust Quantity Modal */}
      <Dialog
        open={!!putawayForAdjustModal}
        onClose={() => setPutawayForAdjustModal(null)}
      >
        {!!putawayForAdjustModal && (
          <PutawayAdjustModal
            putawayTask={putawayForAdjustModal}
            closeModal={() => setPutawayForAdjustModal(null)}
            successCallback={() => {}}
          />
        )}
      </Dialog>

      <DatePicker
        isOpen={datePickerIsOpen}
        setIsOpenCb={(isOpen) => setDatePickerIsOpen(isOpen)}
        onDateChange={(date: Date | null): void => {
          if (date) setInventoryDate(date);
        }}
        selectedDate={inventoryDate}
        onAcceptDate={handleAdjustExpiration}
        minDate={
          checkIsExpiration(inv_inventoryDateLabel) ? new Date() : undefined
        }
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={async () => {
          if (!activePutaway) return;
          await handleInventoryPutaway(activePutaway);
        }}
        closeCb={() => {
          setIsConfirmationModalOpen(false);
          setActivePutaway(null);
        }}
        modalTitle={t("complete putaway confirm")}
        modalText=""
      />
    </>
  );
};
