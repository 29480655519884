import mixpanel from "mixpanel-browser";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { store } from "~/app/store";
import envConstants from "~/config/envConstants";
import { GetStatusCountsResponse } from "~/types/api";

type mixpanelActionType =
  | "Login"
  | "Logout"
  | "Button Click"
  | "Select Row"
  | "Unselect Row"
  | "Select Search Result"
  | "Search"
  | "Scan"
  | "PTL"
  | "Status Change"
  | "Error";

export type mixpanelActionPayload = {
  trackedPageName: string;
  type: mixpanelActionType;
  label: string;
  eventProperties?: unknown;
};

export const mixpanelInit = () => {
  // only log mixpanel events in production
  if (envConstants.RUNTIME_ENVIRONMENT !== "production") return;
  try {
    mixpanel.init("9e861e808460a3125424233f149ddf4a", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage"
    });
  } catch (error) {
    // Ensure that mixpanel failures do not impact the software
    console.log({ message: "Mixpanel error", error });
  }
};

export const mixpanelLogout = () => {
  // only log mixpanel events in production
  if (envConstants.RUNTIME_ENVIRONMENT !== "production") {
    return;
  }
  try {
    mixpanel.track("Logout");
    mixpanel.reset();
  } catch (error) {
    // Ensure that mixpanel failures do not impact the software
    console.log({ message: "Mixpanel error", error });
  }
};

export const mixpanelTrack = (payload: mixpanelActionPayload) => {
  // only log mixpanel events in production
  if (envConstants.RUNTIME_ENVIRONMENT !== "production") {
    return;
  }
  try {
    const state = store.getState();
    mixpanel.track(payload.type, {
      ClientLoginPath: state?.login?.profile?.login_path || "undefined", // security in case ClientName is not consistent
      ClientName:
        state?.store?.usersFulfillmentCenter?.clientConfiguration
          ?.clientConfigurationName || "undefined",
      FcName: state?.store?.usersFulfillmentCenter?.name || "undefined",
      FcAbbv: state?.store?.usersFulfillmentCenter?.abbreviation || "undefined",
      GridName:
        state?.workstations?.siteWorkstation?.autostoreGridName || "undefined",
      Page: payload.trackedPageName,
      Label: payload.label,
      Properties: payload.eventProperties,
      Environment: envConstants.RUNTIME_ENVIRONMENT
    });
  } catch (error) {
    // Ensure that mixpanel failures do not impact the software
    console.log({ message: "Mixpanel error", error });
  }
};

export const mixpanelTrackError = (
  trackedPageName: string,
  message: string
) => {
  mixpanelTrack({
    trackedPageName,
    type: "Error",
    label: message,
    eventProperties: {}
  });
};

export const mixpanelStartPickingWrapper = (
  trackedPageName: string,
  typeValue: mixpanelActionType,
  labelValue: string,
  batchCount: GetStatusCountsResponse | null | undefined
) => {
  const trackingPayload: mixpanelActionPayload = {
    trackedPageName,
    type: typeValue,
    label: labelValue,
    eventProperties: batchCount
      ? {
          "Cart Ready": batchCount["cartReady"],
          Scheduled: batchCount["scheduled"],
          Suspended: batchCount["suspended"],
          Dropped: batchCount["dropped"]
        }
      : {}
  };
  mixpanelTrack(trackingPayload);
};

export const mixpanelPickingConfirmCartWrapper = (
  trackedPageName: string,
  typeValue: mixpanelActionType,
  labelValue: string,
  values: {
    cartNumber: string | null;
    preppedCartNumber: string | null;
  }
) => {
  const trackingPayload: mixpanelActionPayload = {
    trackedPageName,
    type: typeValue,
    label: labelValue,
    eventProperties: {
      "Scanned Cart": values.cartNumber || "",
      "Prepped Cart": values.preppedCartNumber || ""
    }
  };
  mixpanelTrack(trackingPayload);
};

export const mixpanelTrackPickWrapper = (
  trackedPageName: string,
  typeValue: mixpanelActionType,
  labelValue: string,
  value: number | undefined
) => {
  const trackingPayload: mixpanelActionPayload = {
    trackedPageName,
    type: typeValue,
    label: labelValue,
    eventProperties: { "Tote Position": value || 0 }
  };
  mixpanelTrack(trackingPayload);
};

export const mixpanelSetHoldConfirm = (
  trackedPageName: string,
  typeValue: mixpanelActionType,
  labelValue: string,
  value: string
) => {
  const trackingPayload: mixpanelActionPayload = {
    trackedPageName,
    type: typeValue,
    label: labelValue,
    eventProperties: { "Reason Type": value }
  };
  mixpanelTrack(trackingPayload);
};

type PageName =
  | ""
  | "Autostore Main"
  | "Autostore Inventory"
  | "Inventory Holds V2"
  | "Cycle Counts V2"
  | "Induction V2"
  | "Manual Inventory"
  | "Inventory Holds V1"
  | "Induction V1"
  | "Cycle Counts V1";

/**
 * Hook that returns the page name for mixpanel tracking
 */
export function useMixpanelPageName() {
  const { pathname } = useLocation();

  const view = useMemo(() => {
    let pageName: PageName;

    switch (true) {
      case pathname.includes("/autostore-main"):
        pageName = "Autostore Main";
        break;
      case pathname.includes("/autostore-inventory/holds"):
        pageName = "Inventory Holds V2";
        break;
      case pathname.includes("/autostore-inventory"):
      case pathname.includes("/autostore-inventory/product"):
      case pathname.includes("/autostore-inventory/bin"):
        pageName = "Autostore Inventory";
        break;
      case pathname.includes("/autostore-cyclecountv2"):
        pageName = "Cycle Counts V2";
        break;
      case pathname.includes("/autostore-putaway-v2"):
        pageName = "Induction V2";
        break;
      case pathname.includes("autostore-putaway"):
        pageName = "Induction V1";
        break;
      case pathname.includes("/manual-inventory"):
      case pathname.includes("/manual-inventory/bin"):
      case pathname.includes("/manual-inventory/product"):
        pageName = "Manual Inventory";
        break;
      case pathname.includes("/autostore-cyclecount"):
        pageName = "Cycle Counts V1";
        break;
      case pathname.includes("/autostore-inventory-holds"):
        pageName = "Inventory Holds V1";
        break;
      default:
        pageName = "";
    }
    return pageName;
  }, [pathname]);

  return view;
}
