import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useSetThisWorkstation } from "~/hooks/useSetThisWorkstation";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

export function WorkstationPanel() {
  const { t } = useTranslation();
  const { successToast } = useToast();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const { setThisWorkstation, isLoading: isSettingWorkstation } =
    useSetThisWorkstation();
  const [selectedWorkstation, setSelectedWorkstation] =
    useState(siteWorkstation);
  const { sortedWorkstations, isLoading } = useGetWorkstationsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      sortedWorkstations: data?.toSorted(
        (a, b) =>
          a.autostoreGridName.localeCompare(b.autostoreGridName) ||
          a.deviceId.localeCompare(b.deviceId)
      )
    })
  });

  const handleWorkstationChanged = useCallback(
    (e: SelectChangeEvent) => {
      const workstation = sortedWorkstations?.find(
        (workstation) => workstation.id === e.target.value
      );
      setSelectedWorkstation(workstation || null);
    },
    [setSelectedWorkstation, sortedWorkstations]
  );

  const isWorkstationDirty = selectedWorkstation?.id !== siteWorkstation?.id;

  const handleSetThisWorkstationClicked = useCallback(async () => {
    if (!isWorkstationDirty) {
      return;
    }
    await setThisWorkstation(selectedWorkstation, true);
    if (selectedWorkstation) {
      successToast(t("workstation set"), {
        description: t("workstation set description", {
          workstation: selectedWorkstation.deviceId
        })
      });
    } else {
      successToast(t("workstation unset"), {
        description: t("workstation unset description")
      });
    }
  }, [
    isWorkstationDirty,
    setThisWorkstation,
    selectedWorkstation,
    successToast,
    t
  ]);

  if (!sortedWorkstations && isLoading) {
    return (
      <Stack spacing={2}>
        <Typography variant="h6">{t("workstation")}</Typography>
      </Stack>
    );
  }

  if (!sortedWorkstations?.length && !isLoading) {
    return (
      <Stack spacing={2}>
        <Typography variant="h6">{t("workstation")}</Typography>
        <Typography variant="subtitle1">
          {t("no workstations found")}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{t("workstation")}</Typography>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormControl sx={{ maxWidth: "sm" }}>
          <FormHelperText>
            {t("selected workstation description")}
          </FormHelperText>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={selectedWorkstation?.id || ""}
            onChange={handleWorkstationChanged}
            displayEmpty
          >
            {sortedWorkstations?.map((workstation) => (
              <MenuItem key={workstation.deviceId} value={workstation.id}>
                {`${workstation.deviceId}`}
              </MenuItem>
            ))}
            <MenuItem value="">
              <em>{t("no workstation selected")}</em>
            </MenuItem>
          </Select>
        </FormControl>
        <Box pb="2px">
          <LoadingButton
            variant="contained"
            loading={isSettingWorkstation}
            disabled={!isWorkstationDirty}
            onClick={handleSetThisWorkstationClicked}
          >
            {t("assign")}
          </LoadingButton>
        </Box>
      </Stack>
      {siteWorkstation && (
        <>
          <FormControl>
            <FormLabel>{t("workstation mode")}</FormLabel>
            <FormHelperText>{t("workstation mode description")}</FormHelperText>
            <Typography>{siteWorkstation?.mode}</Typography>
          </FormControl>

          <Box>
            <FormLabel>{t("ports")}</FormLabel>
            <Stack direction="row" spacing={1} paddingTop={0.5}>
              {siteWorkstation?.ports.map((port) => (
                <Paper
                  key={port.portId}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    gap: 0.5,
                    p: 2,
                    alignItems: "center",
                    columnGap: 2
                  }}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("autostore port")}
                  </Typography>
                  <Typography>{port.portId}</Typography>
                  {port.parentPortId && (
                    <>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("autostore parent port")}
                      </Typography>
                      <Typography>{port.parentPortId}</Typography>
                    </>
                  )}
                  {port.inductionBinConfiguration && (
                    <>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("bin configuration")}
                      </Typography>
                      <Typography>{port.inductionBinConfiguration}</Typography>
                    </>
                  )}
                  {port.cleaningDirection &&
                    port.cleaningDirection !== "No Direction" && (
                      <>
                        <Typography variant="subtitle1" color="textSecondary">
                          {t("port direction")}
                        </Typography>
                        <Typography>{port.cleaningDirection}</Typography>
                      </>
                    )}
                </Paper>
              ))}
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
}
