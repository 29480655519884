import PrintIcon from "@locaisolutions/icons/dist/icons20px/Print20Px";
import ReloadIcon from "@locaisolutions/icons/dist/icons20px/Reload20Px";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
  Stack,
  Alert,
  StackProps
} from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { useGetPrinterStatus } from "~/hooks/useGetPrinterStatus";

import { useSetDefaultPrinter } from "~/hooks/useSetDefaultPrinter";

export const PrinterSettings = (props: StackProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const initialZpl =
    "^XA^CFA,30^FO20,50^FDZebra Test!^FS^FO20,100^FD100 Main Street^FS^BY2,2,100^FO40,200^BC^FD12345678^FS^XZ";

  const [zplValue, setZplValue] = useState<string>(initialZpl);
  // const [previewUrl, setPreviewUrl] = useState<string>(
  //   `http://api.labelary.com/v1/printers/8dpmm/labels/2.5x5/0/${initialZpl}`
  // );

  const {
    defaultPrinter,
    readyToPrint,
    statusLoading,
    error: printerError
  } = useAppSelector((state) => state.printer);
  const setDefaultPrinter = useSetDefaultPrinter();
  const getPrinterStatus = useGetPrinterStatus();

  // get printer status
  useEffect(() => {
    if (defaultPrinter) {
      getPrinterStatus(defaultPrinter);
    }
  }, [defaultPrinter, getPrinterStatus]);

  const handlePrintTestLabel = () => {
    if (defaultPrinter) {
      defaultPrinter.send(zplValue);
    }
  };

  const handleZplChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZplValue(event.target.value);
  };

  // const handleGetLabelPreview = () => {
  //   setPreviewUrl(
  //     `http://api.labelary.com/v1/printers/8dpmm/labels/2.5x5/0/${zplValue}`
  //   );
  // };

  return (
    <>
      <Stack gap={2} {...props}>
        {!statusLoading && defaultPrinter && (
          <Typography
            variant="h5"
            color={readyToPrint ? "primary" : "default"}
          >{`${defaultPrinter.name}`}</Typography>
        )}
        {!statusLoading && !defaultPrinter && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{ flexDirection: "row" }}
          >
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Typography>{printerError || t("no printer found")}</Typography>
              <Button
                variant="subtle"
                startIcon={<ReloadIcon />}
                onClick={setDefaultPrinter}
              >
                {t("retry").charAt(0).toUpperCase() +
                  t("retry").slice(1).toLowerCase()}
              </Button>
            </Stack>
          </Alert>
        )}
        {statusLoading && <CircularProgress size={20} />}
        <TextField
          id="standard-multiline-flexible"
          label="Test ZPL"
          variant="outlined"
          multiline
          maxRows={40}
          value={zplValue || ""}
          onChange={handleZplChange}
          fullWidth
        />
        <Button
          onClick={handlePrintTestLabel}
          disabled={!defaultPrinter}
          variant="subtle"
          sx={{
            alignSelf: "flex-start"
          }}
          startIcon={
            <PrintIcon
              aria-label="print-icon"
              style={{
                fill: defaultPrinter
                  ? palette.secondary.contrastText
                  : palette.disabled.dark
              }}
            />
          }
        >
          {t("print test label")}
        </Button>
      </Stack>
    </>
  );
};
