import { AnalyticsDashboardDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const analyticsApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboard: build.query<AnalyticsDashboardDto, { dashboardType: string }>(
      {
        query: ({ dashboardType }) => ({
          url: `/analytics/dashboards/${dashboardType}`
        }),
        providesTags: ["analytics"]
      }
    )
  })
});
