import {
  andonColors,
  formatUtcDate,
  formatUtcDateDayjs,
  mainColours
} from "@qubit/autoparts";
import { AxiosError } from "axios";
import { Dayjs } from "dayjs";
import { getRealFormat, upcE } from "gtin";
import { t } from "i18next";
import moment, { Moment } from "moment-timezone";
import { parse } from "qs";
import { Layout } from "react-grid-layout";
import { v4 } from "uuid";

import { Auth0Profile } from "~/api/usersTypes/auth0Profile";
import { warehouseService } from "~/api/warehouse";
import { AS_HomepageType } from "~/config/clientConfig";

import { AndonEvent, AndonStatus } from "~/features/andon/andon.slice";
import { BatchStatus, BatchType } from "~/features/batch/batch.type";

import { InventoryHoldReasonCode } from "~/redux/public/inventory.openApi";
import { searchProductsFromWarehouseApi } from "~/redux/warehouse/product";
import {
  AutostorePickingState,
  BatchOrderSummaryDto,
  BatchSummaryDto,
  BinDto,
  DisplayPickDataRequest,
  FulfillmentCenterDto,
  HoldDto,
  InventorySummaryDto,
  MeasuredValueDto,
  PickingStatePickInfoByTote,
  PickingStateV2TotePick,
  ProductSearchProduct,
  ProductSearchVariant,
  RecurringScheduleDto,
  SystemMode,
  VariantDto,
  VariantFrontendDto,
  WorkstationPortDto,
  WorkstationSummaryDto
} from "~/types/api";

import { hasEventData, hasEventType, isAutostoreEvent } from "./andonHelpers";

export const expired = "expired";
export const damaged = "damaged";
export const recalled = "recalled";
export const external = "external";
export const archived = "archived";
export const outOfStock = "out of stock";
export const dirtyBin = "dirty bin";
export const damagedBin = "damaged bin";
export const misconfiguredBin = "misconfigured bin";

/**
 * Call the Qubit backend endpoint to search for matching products.
 *
 * @input The search text that identifies the product(s) to find.
 */
export const searchProduct = async (
  input: string | string[]
): Promise<ProductSearchProduct[]> => {
  const search = typeof input === "string" ? input : input.join("%20"); // Join with URL spaces.

  const searchResults = await searchProductsFromWarehouseApi(search);

  if (Array.isArray(searchResults)) {
    return searchResults;
  } else {
    return [];
  }
};

/**  Windows from /orders will be already formatted to their timeZone,
windows from /warehouse are in utc and need to be formatted by timezone  */
export const formatTime = (time: Date, timezone?: string): string => {
  const momentTime = moment.utc(time);
  return timezone
    ? momentTime.tz(timezone).format("hA")
    : momentTime.format("hA");
};

export function createPickPathComparer(
  fulfillmentCenter?: FulfillmentCenterDto | null
): (a: string, b: string) => number {
  const aisles = fulfillmentCenter?.pickPath?.length
    ? fulfillmentCenter.pickPath
    : fulfillmentCenter?.aisles || [];
  return (a: string, b: string): number => {
    let formattedAisleA = a;
    let formattedAisleB = b;
    if (a.search("(Store)") !== -1) {
      formattedAisleA = a.replace("(Store)", "").trim();
    }
    if (b.search("(Store)") !== -1) {
      formattedAisleB = b.replace("(Store)", "").trim();
    }
    const diff =
      aisles.indexOf(formattedAisleA) - aisles.indexOf(formattedAisleB);

    if (diff !== 0) {
      return diff;
    }
    return formattedAisleA.localeCompare(formattedAisleB);
  };
}

interface BinPieces {
  binType: string;
  autostoreBinId?: number;
  aisle?: string;
  shelfBay?: string;
  palletBay?: string;
  shelf?: string;
  position?: string;
  storeBinCode?: string;
}

// bin pieces will come from a bin or inventory summary
export const generateLocationNameFromBinPieces = (
  binPieces: BinPieces
): string => {
  const {
    binType,
    autostoreBinId,
    aisle,
    shelfBay,
    palletBay,
    shelf,
    position,
    storeBinCode
  } = binPieces;

  if (binType === "autostore") {
    if (!autostoreBinId) return "Autostore";
    return autostoreBinId.toString();
  }

  if (storeBinCode) {
    return storeBinCode;
  }

  return `${aisle || ""} ${shelfBay || palletBay || ""} ${
    shelf ? `- ${shelf}` : ""
  } ${position ? `- ${position}` : ""}`;
};

export const generateLocationNameFromBin = (bin: BinDto): string => {
  const { binType, aisle, shelfBay, palletBay, shelf, position, storeBinCode } =
    bin;

  return generateLocationNameFromBinPieces({
    binType,
    autostoreBinId: bin.autostoreBin?.autostoreBinId,
    aisle,
    shelfBay,
    palletBay,
    shelf,
    position,
    storeBinCode
  });
};

export const generateLocationNameFromInventorySummary = (
  inventorySummary: InventorySummaryDto
): string => {
  const { binType, aisle, shelfBay, palletBay, shelf, position } =
    inventorySummary;

  return generateLocationNameFromBinPieces({
    binType,
    autostoreBinId: inventorySummary.autostoreBinNumber,
    aisle,
    shelfBay,
    palletBay,
    shelf,
    position
  });
};

export const isWeightedBarcode = (barcode: string): boolean => {
  const firstTwoDigits = barcode.slice(0, 2);
  return (
    (barcode.length === 12 && barcode[0] === "2") || // 12 digit weighted barcodes lead with 2
    (barcode.length === 13 &&
      (firstTwoDigits === "02" || firstTwoDigits === "20")) // 13 digit weighted barcodes lead with 02 or 20
  );
};

export const weightedBarcodeSKU = (barcode: string): string =>
  barcode.slice(-13, -6); // slice backwards to account for one or two digit prefix (02, 20, 2)

export const weightedBarcodeWeight = (barcode: string): number =>
  parseInt(barcode.slice(-6, -1), 10) / 1000;

export const createWeightedBarcode = (upc: string, weight: number): string => {
  const weightString = weight.toFixed(3).toString().replace(".", "");
  return (
    upc.slice(-14, -1 - weightString.length) + weightString + upc.slice(-1)
  );
};

export const matchesUpc = (allUpcs: string[], buffer: string): boolean => {
  const barcode = isWeightedBarcode(buffer)
    ? weightedBarcodeSKU(buffer)
    : buffer;

  return allUpcs.some((upc) =>
    upc.toLowerCase().includes(barcode.toLowerCase())
  );
};

export const matchExactUpcsOrSkus = (
  allUpcsOrSkus: string[],
  buffer: string
): boolean => {
  const maybeWeightedBarcode = isWeightedBarcode(buffer)
    ? weightedBarcodeSKU(buffer)
    : buffer;

  return allUpcsOrSkus.some(
    (upcOrSku) =>
      upcOrSku.toLowerCase() === buffer.toLowerCase() ||
      upcOrSku.toLowerCase() === maybeWeightedBarcode.toLowerCase()
  );
};

export const isPlu = (barcode: string): boolean =>
  // PLUs have 4-5 digits
  barcode.length <= 5;

/**
 * Function that takes a barcode and checks if it's of type GTIN-8/UPC-E of 8 digits and if so it returns a new 12-digit UPC-A barcode.
 * @param barcode
 * @returns new expanded barcode value.
 */
export const getBarcodeValue = (barcode: string): string => {
  try {
    if (getRealFormat(barcode) === "GTIN-8" && barcode.length === 8) {
      const expandedBarcode = upcE.expand(barcode);
      return expandedBarcode;
    }
    return barcode;
  } catch {
    // in case the format is not recognized or if fails expanding the upc then return the initial value
    return barcode;
  }
};

/**
 * Function that takes a guid and returns a shortend, base64 encoded version
 * @param guid
 * @returns encoded guid (for tote label barcode)
 */
// from https://stackoverflow.com/questions/55356285/how-to-convert-a-string-to-base64-encoding-using-byte-array-in-javascript/55357729#55357729
export function guidToBase64(guid: Guid): string {
  const bytes: number[] = [];
  guid.split("-").forEach((subStr, index) => {
    const bytesInChar =
      index < 3 ? subStr.match(/.{1,2}/g)?.reverse() : subStr.match(/.{1,2}/g);
    bytesInChar?.forEach((byte) => {
      bytes.push(parseInt(byte, 16));
    });
  });
  let binary = "";
  const bytesTo8Bit = new Uint8Array(bytes);
  const len = bytesTo8Bit.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytesTo8Bit[i]);
  }
  return window
    .btoa(binary)
    .replace("==", "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-");
}

/**
 * Function that takes a base64 encoded string and returns the original Guid
 * @param base64 base64 encoded guid (tote label barcode)
 * @returns original guid
 */
export function base64ToGuid(base64: string): Guid {
  const stringToDecode = base64.replace(/_/g, "/").replace(/-/g, "+");
  let decodedString = "";
  try {
    decodedString = atob(stringToDecode);
  } catch {
    return "";
  }
  const len = decodedString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = decodedString.charCodeAt(i);
  }
  const guidArr: string[] = [];
  const bytesInChar = bytes;
  bytesInChar?.forEach((byte: number) => {
    const byteString = byte.toString(16);
    guidArr.push(
      byte.toString(16).length === 1 ? `0${byteString}` : byteString
    );
  });
  const guid = `${guidArr.slice(0, 4).reverse().join("")}-${guidArr
    .slice(4, 6)
    .reverse()
    .join("")}-${guidArr.slice(6, 8).reverse().join("")}-${guidArr
    .slice(8, 10)
    .join("")}-${guidArr.slice(10, 16).join("")}`;

  return guid;
}

// Hooks

export function constructPickBinBarcode(pickBin: BinDto): string {
  return `${pickBin.aisle || ""}${pickBin.shelfBay || ""}${
    pickBin.shelf || ""
  }${pickBin.position || ""}`;
}

export const ternaryIff = <T>(condition: boolean, then: T, elseCase: T): T =>
  condition ? then : elseCase;

export function getFirstDefinedValue<T>(
  values: (number | undefined | null)[],
  defaultValue: T
) {
  for (const value of values) {
    if (value !== undefined && value !== null) {
      return value;
    }
  }
  return defaultValue;
}

export const checkIsExpiration = (dateConfig: string) => {
  switch (dateConfig.toUpperCase()) {
    case "EXPIRATION":
    case "EXPIRATIONDATE":
    case "EXPIRATION_DATE":
      return true;
    case "MANUFACTURE":
    case "MANUFACTUREDATE":
    case "MANUFACTURE_DATE":
      return false;
    default:
      return true;
  }
};

export const inventoryDateLabel = (dateConfig: string) =>
  checkIsExpiration(dateConfig) ? "expiration" : "manufacture";

export const checkIfStringIsOneWord = (word: string): boolean => {
  let isOneWord = true;
  for (let i = 0; i < word.length; i++) {
    if (word[i] === " ") {
      isOneWord = false;
      break;
    }
  }
  return isOneWord;
};

export const splitOneWord = (word: string): string => {
  let leftPart = "";
  let rightPart = "";
  if (word.length > 1) {
    leftPart = word.substring(0, word.length / 2);
    rightPart = word.substring(word.length / 2);
  }
  return `${leftPart}-\n${rightPart}`;
};

export const formatCustomerName = (word: string): string => {
  const wordSplit = word.split(" ");
  const newArray = [];
  for (let i = 0; i < wordSplit.length; i++) {
    if (wordSplit[i].length > 18 && checkIfStringIsOneWord(wordSplit[i])) {
      newArray[i] = splitOneWord(wordSplit[i]);
    } else {
      newArray[i] = wordSplit[i];
    }
  }
  return newArray.join(" ");
};

export const formatTitleCase = (
  input: string,
  splitter = " ",
  excludedWords: string[] = []
): string => {
  const excluded = new Set(excludedWords);
  return input
    .toLowerCase()
    .split(splitter)
    .map((word) =>
      excluded.has(word) ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
};

export function buildBatchStatusFilter(
  fulfillmentCenter: FulfillmentCenterDto | null
): BatchStatus[] {
  // batches are shown for consolidation steps
  const statusesForTotePicking: BatchStatus[] = [
    "Scheduled",
    "Cart Ready",
    "Processing",
    "Picked",
    "Dropped"
  ];
  // batches don't consolidate, so hide them after they are picked
  const statusesForNonTotePicking: BatchStatus[] = ["Scheduled", "Processing"];

  return fulfillmentCenter?.toteConfiguration === "NoTotes"
    ? statusesForNonTotePicking
    : statusesForTotePicking;
}

export function buildBatchStatusFilterAdmin(
  fulfillmentCenter: FulfillmentCenterDto | null
): BatchStatus[] {
  // batches are shown for consolidation steps
  const statusesForTotePicking: BatchStatus[] = [
    "Scheduled",
    "Cart Ready",
    "Processing",
    "Waiting Dependency",
    "Picked",
    "Dropped",
    "Staged",
    "Failed",
    "Suspended"
  ];

  // batches don't consolidate, so hide them after they are picked
  const statusesForNonTotePicking: BatchStatus[] = ["Scheduled", "Processing"];

  return fulfillmentCenter?.toteConfiguration === "NoTotes"
    ? statusesForNonTotePicking
    : statusesForTotePicking;
}

export function buildBatchTypeFilter(
  fulfillmentCenter: FulfillmentCenterDto | null
): BatchType[] {
  const hasAutostorePicking =
    !!fulfillmentCenter?.pickingConfigurations.includes("Autostore");
  const hasManualPicking =
    !!fulfillmentCenter?.pickingConfigurations.includes("Manual");

  let batchTypes: BatchType[] = [
    ...(hasManualPicking ? ["Bulk" as BatchType] : []),
    ...(hasManualPicking ? ["Store" as BatchType] : []),
    ...(hasManualPicking ? ["Warehouse" as BatchType] : []),
    ...(hasAutostorePicking ? ["Autostore" as BatchType] : [])
  ];

  // turn off type filters if autostore is the only choice
  if (hasAutostorePicking && !hasManualPicking) {
    batchTypes = [];
  }

  return batchTypes;
}

export const formatQuantityString = (qty: MeasuredValueDto): string =>
  `${qty.value} ${qty.units}`;

export interface IJWT {
  exp: number;
}

// Taken from https://stackoverflow.com/a/38552302
export const parseJwt = (token: string): IJWT => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload) as IJWT;
};

export const isJwtExpired = (token: string): boolean => {
  const jwt = parseJwt(token);
  return Date.now() >= jwt.exp * 1000;
};

export const getRndInteger = (
  min: number,
  max: number,
  exclude?: number
): number => {
  const rnd = Math.floor(Math.random() * (max - min)) + min;
  return rnd === exclude ? getRndInteger(min, max, exclude) : rnd;
};

/**
 * Creates a display name for the variant info.
 * E.g. "Roast Beef (12345 - Each)"
 *
 * @variant The variant to display info for.
 * @altName Alternative fallback display name for the variant.
 */
export const variantToDisplayName = (
  variant: ProductSearchVariant,
  altName: string
): string => {
  const sku = variant.sku;
  const packageUnit = variant.packageUnit;
  const name = variant.name || altName;

  if (!sku) return name;

  const packageUnitDisplay = packageUnit ? ` - ${packageUnit}` : "";

  return `${name} (${sku}${packageUnitDisplay})`;
};

export const temperatureZoneColors = {
  ambient: "ambient.main",
  chilled: "chilled.main",
  frozen: "frozen.main"
};

export const dedupeBrand = (productName: string, brandName: string) =>
  productName.startsWith(brandName)
    ? productName.replace(brandName, "")
    : productName;

export const isAutostoreView = (search: string) => {
  const query = parse(search, { ignoreQueryPrefix: true });
  const { autostore } = query;
  return !!autostore;
};

export const isExpirationValid = (
  isExpReq: boolean | undefined,
  inventoryDate: Moment | Dayjs | null | undefined
) => (!isExpReq && !inventoryDate ? true : inventoryDate?.isValid());

export type HoldTypeSource = "internal" | "external" | "system";
export interface HoldType {
  reasonCode: InventoryHoldReasonCode;
  source: HoldTypeSource;
}

/**
 * Convert list of hold strings to a hold object.
 *
 * @holdStrings The list of hold strings
 */
export const convertHolds = (holds: HoldDto[]): HoldType[] =>
  holds.map((hold) => ({
    reasonCode: hold.reasonCode as InventoryHoldReasonCode,
    source: hold.source as HoldTypeSource
  }));

/**
 * Function to show a user message based on the holdType that's being removed.
 * @param holdType - type of hold: string .
 * @param errorCb - callback that shows a user message
 * @param t - Translation function.
 * @param expirationDate - Expiration date: Moment object.
 * @returns user message.
 */
export const holdTypeCheck = (
  hold: HoldType,
  errorCb: (message: string) => void,
  expirationDate?: Moment
): boolean => {
  const currentDate = moment();

  let errMsg = null;
  let cantRemove = false;

  // checks for holds that shouldn't be removed
  if (
    hold.reasonCode === expired &&
    expirationDate != null &&
    expirationDate?.isBefore(currentDate)
  ) {
    cantRemove = true;
    errMsg = `${t(hold.reasonCode)} ${t("can't be removed")} ${t(
      "because inventory is expired"
    )}`;
  } else if (hold.source === external) {
    cantRemove = true;
    errMsg = `${t(hold.reasonCode)} ${t(
      "can't be removed because its source is external"
    )}`;
  }

  if (cantRemove) {
    const errText = errMsg ?? `${t(hold.reasonCode)} ${t("can't be removed")}`;
    errorCb(errText);
    return true;
  }
  return false;
};

/**
 * Function to get the commonHolds between selected inventory that we wish to remove.
 * @param selectedSummaries InventorySummaryDto[] | undefined .
 * @param defaltHoldsArr HoldType[].
 * @param holdsToExclude string[].
 * @returns common holds arr.
 */
export const getCommonHolds = (
  selectedSummaries: InventorySummaryDto[],
  defaltHoldsArr: HoldType[],
  holdsToExclude: string[]
) => {
  if (selectedSummaries)
    return selectedSummaries.reduce((holdOptions, inventory) => {
      const holds = inventory?.holds ?? [];
      return holdOptions.filter((hold: HoldType) => {
        const holdTextLower = hold.reasonCode.toLowerCase();
        return (
          holds.some((h) => h.reasonCode === holdTextLower) &&
          !holdsToExclude.includes(holdTextLower)
        );
      });
    }, defaltHoldsArr);
  return defaltHoldsArr;
};

export const getInventoryDateObj = (
  isExp: string,
  inventoryDate: Moment | null | undefined
) =>
  checkIsExpiration(isExp)
    ? { expiration: inventoryDate ? inventoryDate.toDate() : undefined }
    : {
        manufactureDate: inventoryDate
          ? formatUtcDate(inventoryDate)
          : undefined
      };

/** Cloned from the moment version of this function */
export const getInventoryDateObjDayjs = (
  isExp: string,
  inventoryDate: Dayjs | null | undefined
) =>
  checkIsExpiration(isExp)
    ? { expiration: inventoryDate ? inventoryDate.toDate() : undefined }
    : {
        manufactureDate: inventoryDate
          ? formatUtcDateDayjs(inventoryDate)
          : undefined
      };

export const isExpressBatch = (batchOrders: BatchOrderSummaryDto[]) => {
  for (const batchOrder of batchOrders) {
    if (batchOrder.priority.toLowerCase() === "express") {
      return true;
    }
  }

  return false;
};

export const isExpressOrder = (
  pickingState: AutostorePickingState,
  toteId: Guid
) => {
  const tote = pickingState.totes.find(
    (toteLocal) => toteLocal.toteId === toteId
  );
  if (tote) {
    const { orderId } = tote;

    for (const order of pickingState.orderPriorities) {
      if (
        order.orderId === orderId &&
        order.priority.toLowerCase() === "express"
      ) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Get Variant Name, falling back on Product Name.
 *
 * @variantName The variant name.
 * @productName The product name.
 */
export const getDisplayName = (
  variantName: string | null,
  productName: string | null
) => variantName ?? productName;

/**
 * Get Variant display name based on FC Config.
 *
 * @variant The variant.
 */
export const getDisplayNameByDto = (variant: VariantDto | undefined | null) => {
  if (!variant) return null;

  return getDisplayName(variant.name, variant.productName);
};

/**
 * Get Variant display name based on FC Config.
 *
 * @variant The variant.
 */
export const getVariantDisplayNameByDtoFe = (
  variant: VariantFrontendDto | null
) => {
  if (!variant) return null;

  return getDisplayName(variant.variantName, variant.productName);
};

export const processErrorArray = (errors: string[]): string => {
  const dedupedErrors = [...new Set(errors)];
  return dedupedErrors.reduce(
    (acc: string, errorString: string): string => `${acc} ${errorString}`,
    ""
  );
};

export const convertMillisecondsToTimeString = (milliseconds: number) => {
  const seconds = milliseconds / 1000;

  return `${seconds.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: false
  })} seconds`;
};

export const isEveryPickOutOfStocked = (
  allPicks: PickingStatePickInfoByTote[],
  pickedToteId: Guid
) => {
  let isEveryPickOutOfStockedResult = false;
  allPicks.forEach((specificTote) => {
    if (specificTote.toteId === pickedToteId) {
      const specificPicks = specificTote.picks;
      isEveryPickOutOfStockedResult = specificPicks.every(
        (pick) =>
          pick.exception !== null &&
          pick.status.toLowerCase() === "completed" &&
          pick.exception?.type.toLowerCase() === "outofstock"
      );
    }
  });
  return isEveryPickOutOfStockedResult;
};

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const configurationTypeToString = (type: number) => {
  switch (type) {
    case 1:
      return "Whole";
    case 2:
      return "Half";
    case 4:
      return "Half";
    case 5:
      return "Quarter";
    default:
      return "";
  }
};

export const mapEventColorAndLetters = (event: AndonEvent) => {
  let colorToReturn = "gray.light";
  let lettersToReturn = "";

  if (!hasEventData(event)) {
    return {
      color: colorToReturn,
      letters: lettersToReturn
    };
  }

  const { eventData } = event;

  if (hasEventData(event) && hasEventType(eventData)) {
    switch (eventData.eventType) {
      case "Selected":
        colorToReturn = mainColours.label["1"];
        lettersToReturn = "PS";
        break;
      case "LabelPlaced":
        colorToReturn = mainColours.label["9"];
        lettersToReturn = "LP";
        break;
      case "StateChange":
        colorToReturn = mainColours.label["5"];
        lettersToReturn = "PTL";
        break;
      case "Position Confirmed":
        colorToReturn = mainColours.label["6"];
        lettersToReturn = "PLA";
        break;
      case "Completed":
        colorToReturn = mainColours.label["10"];
        lettersToReturn = "C";
        break;
      default:
      // console.log(
      //   "not handled by mapEventColorAndLetters",
      //   eventData.eventType
      // );
    }
  }
  if (isAutostoreEvent(eventData)) {
    switch (eventData.case) {
      case "PortMode":
        if (eventData.event.portMode === "Open") {
          colorToReturn = "white";
          lettersToReturn = "PO";
        } else if (eventData.event.portMode === "Closed") {
          colorToReturn = "#404040";
          lettersToReturn = "PC";
        } else if (eventData.event.portMode === "MultiPortPuppet") {
          colorToReturn = "#00000017";
          lettersToReturn = "PM";
        }
        break;
      case "PortError":
        if (eventData.event.hasError) {
          colorToReturn = andonColors.safetyStopRed;
          lettersToReturn = "PE";
        } else {
          colorToReturn = andonColors.openGreen;
          lettersToReturn = "POK";
        }
        break;
      case "SystemModeChanged":
        if (
          ["ALT", "DSTP", "STP", "SVC"].includes(eventData.event.systemMode)
        ) {
          colorToReturn = andonColors.safetyStopRed;
          lettersToReturn = "SMNOK";
        } else {
          colorToReturn = andonColors.openGreen;
          lettersToReturn = "SMOK";
        }
        break;
      case "HandToggled":
        if (eventData.event.raiseHand) {
          colorToReturn = andonColors.handRaisedYellow;
          lettersToReturn = "HU";
        } else {
          colorToReturn = andonColors.handRaisedYellow;
          lettersToReturn = "HD";
        }
        break;
      case "BinRequested":
        colorToReturn = mainColours.label["2"];
        lettersToReturn = "PLR";
        break;
      case "PTLMessageRegistered":
        colorToReturn = mainColours.label["3"];
        lettersToReturn = "PLR";
        break;
      case "PTLMessageActivated":
        colorToReturn = mainColours.label["6"];
        lettersToReturn = "PLA";
        break;
      case "BinModeChange":
        switch (eventData.event.binMode) {
          case "P":
            colorToReturn = mainColours.label["4"];
            lettersToReturn = "PLR";
            break;
          case "O":
            colorToReturn = mainColours.label["7"];
            lettersToReturn = "BO";
            break;
          case "C":
            colorToReturn = mainColours.label["8"];
            lettersToReturn = "BC";
            break;
        }
        break;

      default:
      // console.log(
      //   "not handled by mapEventColorAndLetters",
      //   eventData.eventType
      // );
    }
  }

  return {
    color: colorToReturn,
    letters: lettersToReturn
  };
};

export const figureAndonColorFromStatus = ({
  handRaised,
  portOpen,
  portStatus,
  workstationStatus,
  workstationOpen,
  gridSystemMode,
  workstationActive,
  defaultColor
}: {
  handRaised?: boolean;
  gridSystemMode?: SystemMode;
  workstationStatus?: AndonStatus;
  portStatus?: AndonStatus;
  portOpen?: boolean;
  workstationOpen?: boolean;
  workstationActive?: boolean;
  defaultColor?: string;
}): string => {
  if (handRaised) return andonColors.handRaisedYellow;
  // ALT = ALERT ; DSTP = STOPPING
  if (gridSystemMode && ["ALT", "DSTP", "SVC", "STP"].includes(gridSystemMode))
    return andonColors.gridDownPurple;
  if (workstationStatus === "bad") return andonColors.portFaultAmber;
  if (portStatus === "bad") return andonColors.portFaultAmber;
  if (portOpen) return andonColors.openGreen;
  if (workstationOpen) return andonColors.openGreen;
  if (workstationActive) return andonColors.workstationActiveTeal;

  return defaultColor || "white";
};

export const generateUuid = () => v4();

export const parentPortIdFoundInWorkstation = (
  ports: WorkstationPortDto[] | undefined
) => {
  if (ports) {
    const parentPortIdFound = ports.find((port) => !!port.parentPortId);
    if (parentPortIdFound) return true;
    return false;
  }
  return false;
};

export const isWorkstationWithSinglePort = (
  workstation: WorkstationSummaryDto | null
) => {
  if (workstation?.ports.length === 1) return true;
  return false;
};

export const getAxiosErrorMessage = (error: AxiosError) =>
  Array.isArray(error?.response?.data)
    ? (error?.response?.data[0] as string)
    : (error?.response?.data as string) || "Unknown error";

/**
 * Get the different Hold Types to display
 *
 * @excludeRecalledHold Whether to exclude the Recalled hold type from displaying.
 * @isAutostorePickingPage Whether we're on the AutoStore Picking page.
 */
export const getHoldTypeOptions = (
  excludeRecalledHold: boolean,
  includeDamagedBinHold?: boolean,
  includeDirtyBinHold?: boolean,
  includeMisconfiguredBinHold?: boolean,
  isAutostorePickingPage?: boolean
): HoldType[] => {
  let options = [
    {
      text: t(damaged),
      reasonCode: damaged,
      source: "internal"
    }
  ];

  if (isAutostorePickingPage) {
    options = [
      ...options,
      {
        text: t(outOfStock),
        reasonCode: outOfStock,
        source: "internal"
      }
    ];
  }
  if (includeDirtyBinHold) {
    options = [
      ...options,
      {
        text: `${t("dirty bin")}`,
        reasonCode: dirtyBin,
        source: "internal"
      }
    ];
  }

  if (includeDamagedBinHold) {
    options = [
      ...options,
      {
        text: `${t("damaged bin")}`,
        reasonCode: damagedBin,
        source: "internal"
      }
    ];
  }

  if (includeMisconfiguredBinHold) {
    options = [
      ...options,
      {
        text: `${t("misconfigured bin")}`,
        reasonCode: misconfiguredBin,
        source: "internal"
      }
    ];
  }

  if (!excludeRecalledHold) {
    options = [
      ...options,
      {
        text: t(recalled),
        reasonCode: recalled,
        source: "internal"
      }
    ];
  }

  return options as HoldType[];
};

/**
 * Translate the Hold text to its proper language, including capitalization.
 *
 * @hold The hold object.
 *
 */
export const formatHoldText = (hold: HoldDto) => {
  const codeText = hold.reasonCode.toLowerCase() as InventoryHoldReasonCode;
  return t(codeText);
};

export const getTimeFromDatetime = (date: Date) => {
  const newDate = new Date(date);

  const year = String(newDate.getFullYear());
  const month = String(newDate.getMonth()).padStart(2, "0");
  const dayOfMonth = String(newDate.getDate()).padStart(2, "0");
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");
  const seconds = String(newDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(newDate.getMilliseconds()).padStart(3, "0");

  return {
    year,
    month,
    dayOfMonth,
    hours,
    minutes,
    seconds: String(newDate.getSeconds()).padStart(2, "0"),
    milliseconds,
    formatted: `${hours}:${minutes}:${seconds}.${milliseconds}`
  };
};

export const getCompartmentId = (
  compartmentType: number,
  compartmentNumber: number
) => {
  // CompartmentId identifies both the size and shape of the compartment and the compartment's location inside the bin
  let compartmentId = 0;
  // Compartment types 1-8 use a two-digit Compartment ID
  if (compartmentType < 9) {
    compartmentId = compartmentType * 10 + compartmentNumber;
  } else {
    compartmentId = compartmentType * 100 + compartmentNumber;
  }
  return compartmentId;
};

export const percentRegex = /^[1-9][0-9]?$|^100$/;

export const numbersRegex = /^\d+$/;

export function dateDifference(date1: Date, date2: Date) {
  let newDate1 = date1;
  let newDate2 = date2;

  // Ensure date1 is earlier than date2 for the calculation
  if (newDate1 > newDate2) {
    [newDate1, newDate2] = [newDate2, newDate1];
  }

  const differenceInMilliseconds = newDate2.getTime() - newDate1.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(differenceInMilliseconds / (1000 * 3600));
  const minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 3600)) / (1000 * 60)
  );
  const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
  const milliseconds = differenceInMilliseconds % 1000;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}:`;
  }

  if (minutes > 0 || hours > 0) {
    formattedTime +=
      hours > 0 ? `${String(minutes).padStart(2, "0")}:` : `${minutes}:`;
  }

  formattedTime +=
    minutes > 0 || hours > 0 ? String(seconds).padStart(2, "0") : `${seconds}`;

  if (milliseconds > 0) {
    formattedTime += `.${milliseconds}`;
  }

  return {
    hours,
    minutes,
    seconds,
    formattedTime,
    totalMilliseconds: differenceInMilliseconds
  };
}

export function millisecondFormatter(differenceInMilliseconds: number) {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(differenceInMilliseconds / (1000 * 3600));
  const minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 3600)) / (1000 * 60)
  );
  const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
  const milliseconds = differenceInMilliseconds % 1000;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}:`;
  }

  if (minutes > 0 || hours > 0) {
    formattedTime +=
      hours > 0 ? `${String(minutes).padStart(2, "0")}:` : `${minutes}:`;
  }

  formattedTime +=
    minutes > 0 || hours > 0 ? String(seconds).padStart(2, "0") : `${seconds}`;

  if (milliseconds > 0) {
    formattedTime += `.${Math.trunc(milliseconds)}`;
  }

  return {
    hours,
    minutes,
    seconds,
    formattedTime
  };
}

export const workstationRoles = [
  "Picking",
  "Induction",
  "Maintenance",
  "Inventory"
];

// This method accepts two parameters:
// 1. List of all roles selected for the workstation (The siteWorkstation.roles value from redux)
// 2. Role name to match
export const findRole = (
  allRoles: string[] | undefined,
  specificRole: string
) => {
  const roleFound = allRoles?.find(
    (role) => role.toLowerCase() === specificRole.toLowerCase()
  );
  return !!roleFound;
};

export function isHomePageTypeIncluded(
  types: AS_HomepageType[],
  typeToCheck: AS_HomepageType
): boolean {
  return types?.includes(typeToCheck);
}

export function groupArrayByKey<T>(
  array: T[],
  keyFn: (item: T) => string
): Record<string, T[]> {
  return array.reduce<Record<string, T[]>>((result, currentItem) => {
    const key = keyFn(currentItem);
    const currentGroup = result[key] || [];

    return {
      ...result,
      [key]: [...currentGroup, currentItem]
    };
  }, {});
}

export function createNumberRangeArr(start: number, end: number): number[] {
  if (start <= end) {
    // Generate a range increasing from start to end
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  } else {
    // Generate a range decreasing from start to end
    return Array.from({ length: start - end + 1 }, (_, index) => start - index);
  }
}

interface BoardPositions {
  x: number;
  y: number;
}

export function getEmptyPositions(
  layout: Layout[],
  gridWidth: number,
  gridHeight: number
): BoardPositions[] {
  // Create a grid representation with all possible spaces
  const allSpaces: BoardPositions[] = Array.from(
    { length: gridHeight },
    (_, y) => Array.from({ length: gridWidth }, (_, x) => ({ x, y }))
  ).flat();

  // Create a set of occupied spaces as strings for easy comparison
  const occupiedSpaces: Set<string> = new Set(
    layout.map((el) => `${el.x},${el.y}`)
  );

  // Filter out the occupied points
  const emptyPositions: BoardPositions[] = allSpaces.filter(
    (point) => !occupiedSpaces.has(`${point.x},${point.y}`)
  );

  return emptyPositions;
}

export function findClosestEmptyPositions(
  clickPositions: BoardPositions,
  emptyPositions: BoardPositions[]
): BoardPositions | null {
  if (emptyPositions.length === 0) {
    return null;
  }

  let closestPositions: BoardPositions = emptyPositions[0];
  let minimumDistance: number = Number.MAX_SAFE_INTEGER;

  emptyPositions.forEach((emptyPosition) => {
    // Calculate the distance from the clicked emptyPosition to the current emptyPosition
    // Math.pow here raises to the power of 2, for purpose of absolute difference mostly, but then leaves it multipled
    // Pythagorean Theorum, yo
    const distance = Math.sqrt(
      Math.pow(emptyPosition.x - clickPositions.x, 2) +
        Math.pow(emptyPosition.y - clickPositions.y, 2)
    );

    // If this distance is less than the current minimum distance,
    // then we have found a closer empty position
    if (distance < minimumDistance) {
      minimumDistance = distance;
      closestPositions = emptyPosition;
    }
  });

  return closestPositions;
}

export function convertPixelCoordinatesToGridCoordinates(
  pixelX: number,
  pixelY: number,
  totalWidth: number,
  rowHeight: number,
  columns: number
): BoardPositions {
  const columnWidth = totalWidth / columns;

  // Convert the x-coordinate from pixels to grid units by dividing
  // the pixel x-coordinate by the width of a single column and taking
  // the floor to get a whole number. This gives the column index.
  return {
    x: Math.floor(pixelX / columnWidth),
    y: Math.floor(pixelY / rowHeight)
  };
}

export const maybePluralize = <T extends string>(
  noun: T,
  count: number | undefined,
  suffix = "s"
): T => `${noun}${count === 1 ? "" : suffix}` as T;

export const findLowestNumber = (arr: number[]) => Math.min(...arr);

export const displayPickData = async (pickData: DisplayPickDataRequest) => {
  const { portId, pickId, batchId, gridId, quantity } = pickData;

  await warehouseService.post("api/ptl/display-pick-data", {
    portId,
    pickId,
    batchId,
    gridId,
    quantity
  });
};

export const isSiteUsingShipments = (
  recurringSchedules?: RecurringScheduleDto[]
) => {
  if (!recurringSchedules?.length) return false;
  return recurringSchedules.every(
    (recurringSchedule) =>
      recurringSchedule.shipmentConfig.toLowerCase() === "useshipments"
  );
};

export const determinePortId = (workstation: WorkstationSummaryDto) => {
  if (!workstation) return null;

  const withMultiport = workstation.ports.find(
    (port) => port.parentPortId != null
  );
  const withCleaningDirection = workstation.ports.find(
    (port) => port.cleaningDirection?.toLowerCase() === "outbound"
  );

  if (withMultiport) {
    return withMultiport.parentPortId;
  } else if (withCleaningDirection) {
    return withCleaningDirection.portId;
  } else if (workstation.ports.length > 0) {
    return workstation.ports[0].portId;
  }

  return null;
};

export const showPickToLight = async (
  compartmentType: number,
  compartmentNumber: number,
  gridId: string,
  portId: number,
  quantity: number,
  sourceBinId?: number,
  sourceCompartment?: number
) => {
  const compartmentId =
    sourceCompartment ||
    getCompartmentId(compartmentType || 1, compartmentNumber || 1);

  await warehouseService.post<null>(
    `api/autostore-grid/${gridId}/port/show-pick-to-light`,
    {
      sourcePortId: portId,
      sourceBinId,
      sourceCompartment: compartmentId,
      quantity
    }
  );
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const copyFromClipboard = async () => {
  try {
    return await navigator.clipboard.readText();
  } catch (err) {
    console.error("Failed to copy: ", err);
    return null;
  }
};

export const canPrepBatchOption = (batch: BatchSummaryDto | null) => {
  const statuses = ["scheduled", "cart ready", "waiting dependency"];
  return statuses.includes((batch && batch.status?.toLowerCase()) || "");
};

export const tempZoneBasedColor = (tempZone: string) => {
  const tempZoneToLowerCase = tempZone.toLowerCase();
  switch (tempZoneToLowerCase) {
    case "ambient":
      return temperatureZoneColors.ambient;
    case "chilled":
      return temperatureZoneColors.chilled;
    case "frozen":
      return temperatureZoneColors.frozen;
    default:
      return temperatureZoneColors.ambient;
  }
};

export const getPickStatus = (pick: PickingStateV2TotePick) => {
  if (pick.completedTimestamp) return "Completed";
  if (pick.canceledTimestamp) return "Canceled";
  return "Scheduled";
};

const extractUsernameFromAuth0Name = (auth0ProfileUsername: string) => {
  const splitAuth0ProfileUsername = auth0ProfileUsername.split("@");

  if (splitAuth0ProfileUsername.length > 1) {
    return splitAuth0ProfileUsername[0];
  }

  return auth0ProfileUsername;
};

export const extractUserNameFromAuth0Id = (auth0Id: string): string => {
  const splitAuth0UserName = auth0Id.split("|");
  let auth0UserName;

  if (splitAuth0UserName.length > 1) {
    auth0UserName = splitAuth0UserName[splitAuth0UserName.length - 1];
  }
  return auth0UserName ? extractUsernameFromAuth0Name(auth0UserName) : auth0Id;
};

// auth0Profile.name is in the format of "auth0|<username>"
export function extractUsername(auth0Profile: Auth0Profile): string {
  const auth0ProfileUsername = auth0Profile.name;

  return extractUsernameFromAuth0Name(auth0ProfileUsername);
}

export const extractDisplayName = (userId: string | null | undefined) => {
  if (typeof userId === "string") {
    const parts = userId.split("|");

    // The username is assumed to be in the last part
    return parts[parts.length - 1];
  }
  return "";
};

export const extractReceipientId = (userId: string | null | undefined) => {
  if (typeof userId === "string") {
    const parts = userId.split("|");

    return `${parts[0]}|${parts[1]}`;
  }
  return "";
};
