import CheckCircleIcon from "@locaisolutions/icons/dist/icons24px/CheckmarkCircle24Px";
import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Typography,
  styled,
  Stack,
  useTheme,
  TextField,
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { BarcodeSvg } from "~/icons/BarcodeSvg";

import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";

import {
  addEvent,
  resetWorkstationTest,
  selectCompletedTests,
  setTestCaseState
} from "./workstationTest.slice";

const BarcodeContainer = styled(Stack)(
  ({ theme: { palette, spacing, shadows } }) => ({
    border: `1px solid ${palette.gray.dark}`,
    borderRadius: 10,
    padding: spacing(3),
    boxShadow: shadows["1"],
    backgroundColor: palette.secondary.main,
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    gap: spacing(3),
    minWidth: 550
  })
);
const OverheadScannerContainer = styled(Stack)(
  ({ theme: { palette, spacing } }) => ({
    border: `1px solid ${palette.darkGray.main}`,
    borderRadius: 10,
    padding: spacing(3),
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    minWidth: 550
  })
);

const CustomTextField = styled(TextField)(({ theme: { palette } }) => ({
  minWidth: 300,
  "& .MuiInputBase-root": {
    "&.Mui-focused:after": {
      borderBottom: `1px solid ${palette.darkGray.dark}`
    }
  },
  "& .MuiInputLabel-root": {
    fontSize: "26px",
    "&.Mui-focused": {
      color: palette.text.primary
    }
  }
}));

const ScannerContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "grid",
  placeContent: "center",
  alignItems: "center",
  justifyItems: "center",
  rowGap: spacing(3),
  gridTemplateRows: "minmax(300px, 1fr) 130px minmax(150px, 0.2fr)"
}));

const ScannerTest = () => {
  const dispatch = useAppDispatch();
  const completedTests = useAppSelector(selectCompletedTests);
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [scanText, setScanText] = useState<string | null>(null);

  const scannerTestStatus = completedTests["Scanner"];
  const hasCurrentTestSuccedeed = scannerTestStatus?.state === "Success";
  const hasCurrentTestFailed = scannerTestStatus?.state === "Failed";
  const isCurrentTestCompleted =
    hasCurrentTestFailed || hasCurrentTestSuccedeed;

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (match) => match,
    processScanMatch: (match) => setScanText(match),
    deps: [setScanText],
    disabled: hasCurrentTestFailed
  });

  const failTest = () => {
    dispatch(setTestCaseState({ test: "Scanner", state: "Failed" }));
  };
  const retryTest = () => {
    if (hasCurrentTestSuccedeed) {
      setScanText("");
    }
    dispatch(resetWorkstationTest("Scanner"));
  };

  useEffect(() => {
    if (!scanText) return;

    dispatch(
      addEvent({
        text: `Success Event - code: ${scanText}`,
        state: "Success",
        testOrigin: "Scanner"
      })
    );
    dispatch(
      setTestCaseState({
        test: "Scanner",
        state: "Success",
        skipLogging: true,
        additionalProps: {
          lastScannedBarcode: scanText
        }
      })
    );
  }, [dispatch, scanText]);
  const scannedBarcodeToDisplay =
    scanText || scannerTestStatus?.additionalProps?.lastScannedBarcode || "";

  return (
    <ScannerContainer aria-label="Scanner test content">
      <BarcodeContainer>
        <BarcodeSvg sx={{ width: 400, height: 200 }} />
        <Typography variant="h5">{t("scan barcode instructions")}</Typography>
      </BarcodeContainer>
      <OverheadScannerContainer>
        <CustomTextField
          variant="standard"
          label={t("scanned code")}
          type="text"
          value={scannedBarcodeToDisplay}
          aria-readonly
          fullWidth
          size="medium"
          color="primary"
          focused={!!scanText}
          slotProps={{
            htmlInput: {
              style: {
                fontSize: "25px",
                textAlign: "center"
              }
            }
          }}
        />
      </OverheadScannerContainer>
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "center",
          gap: 3
        }}
      >
        {!isCurrentTestCompleted && (
          <Button
            onClick={failTest}
            color="error"
            sx={{ minHeight: 70, minWidth: 250, fontWeight: 600, fontSize: 20 }}
          >
            {t("unable to scan barcode")}
          </Button>
        )}
        {hasCurrentTestFailed && (
          <ErrorIcon
            style={{
              fill: palette.error.main,
              height: "70px",
              width: "70px"
            }}
            role="img"
            aria-label="error-icon"
          />
        )}
        {hasCurrentTestSuccedeed && (
          <CheckCircleIcon
            style={{
              fill: palette.success.main,
              height: "70px",
              width: "70px"
            }}
            role="img"
            aria-label="success-icon"
          />
        )}
        {isCurrentTestCompleted && (
          <Button
            onClick={retryTest}
            sx={{
              minHeight: 70,
              minWidth: 250,
              fontSize: 22,
              fontWeight: 600
            }}
            color="secondary"
            startIcon={
              <ReplayIcon
                sx={{
                  color: "#000",
                  width: 40,
                  height: 40
                }}
              />
            }
          >
            {t("retry test")}
          </Button>
        )}
      </Stack>
    </ScannerContainer>
  );
};

export default ScannerTest;
