import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "@mui/material";
import Tab from "@mui/material/Tab";
import { ErrorPage } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { useGetDashboardQuery } from "~/redux/warehouse/analytics.hooks";
import { AnalyticsDashboardDto } from "~/types/api";

type Props = { viewTitle?: ViewNameTranslation };

export function Analytics({ viewTitle }: Props) {
  const { t } = useTranslation();
  useNavbar({ viewTitle });

  const isAdmin = useAppSelector(selectUserIsAdmin);
  const [currentView, setCurrentView] = useState("picker");

  const {
    data: dashboard,
    isFetching,
    error
  } = useGetDashboardQuery(
    { dashboardType: currentView },
    { refetchOnMountOrArgChange: true }
  );

  const getDashboardUrl = (dash: AnalyticsDashboardDto | null) => {
    if (dash == null) return "";

    return `${dash.embedUrl}#titled=false`;
  };

  // This function returns an iframe.
  // We cannot inspect dimensions of it due to cross domain protections
  // so make it fill the height and width of the parent.
  // This requires that the parent container have a reasonable height set.
  const getContent = () =>
    !dashboard || isFetching ? (
      <div>Loading</div>
    ) : (
      <iframe
        title="Analytics Dashboard"
        src={getDashboardUrl(dashboard)}
        frameBorder={0}
        allowTransparency
        height="100%"
        width="100%"
      />
    );

  const getFrame = () =>
    isAdmin ? (
      <TabContext value={currentView}>
        <TabList
          onChange={(_event: React.SyntheticEvent<unknown>, newVal: string) =>
            setCurrentView(newVal)
          }
        >
          <Tab label={t("nav.link.picking")} value="picker" />
          <Tab label={t("shift management")} value="shift-manager" />
        </TabList>
        <TabPanel
          value="picker"
          style={{
            height: "660px"
          }}
        >
          {getContent()}
        </TabPanel>
        <TabPanel
          value="shift-manager"
          style={{
            height: "960px"
          }}
        >
          {getContent()}
        </TabPanel>
      </TabContext>
    ) : (
      <TabContext value="picker">
        <TabList
          onChange={(_event: React.SyntheticEvent<unknown>, newVal: string) =>
            setCurrentView(newVal)
          }
        >
          <Tab label="Picking" value="picker" />
        </TabList>
        <TabPanel
          value="picker"
          style={{
            height: "660px"
          }}
        >
          {getContent()}
        </TabPanel>
      </TabContext>
    );

  return (
    <Container>
      {error ? (
        <ErrorPage errorMessage={getMessageFromRtkError(error)} />
      ) : (
        getFrame()
      )}
    </Container>
  );
}
