import {
  ArrowDoubleRight20Px,
  CheckmarkCircle20Px,
  CloseCircle20Px
} from "@locaisolutions/icons";
import { Box, Button, Paper, Stack } from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useRequestNextBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetInventoryByAutostoreBinNumberQuery } from "~/redux/warehouse/inventory.hooks";

import { AdjustButton } from "./AdjustButton";
import { BinNotEmptyButton } from "./BinNotEmptyButton";
import { MoveInventoryButton } from "./MoveInventoryButton";
import { PulsingArrows } from "./PulsingArrows";
import { UnflagBinButton } from "./UnflagBinButton";
import { setTaskState } from "./binMaintenanceWorkstation.slice";

export const BinMaintenancePort = ({ portId }: { portId: number }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [requestNextBin] = useRequestNextBinMutation();

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { movementTaskState, movementData } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId);

  const movingProduct = movementTaskState === "MovingProduct";
  const confirmingMovement = movementTaskState === "ConfirmingMovement";
  const { selectedBin: thisBinNumber } = portStatus;
  const { sourceBin, destinationBin } = movementData?.inventoryMovements || {};
  const isCleaningBin = movementData?.binCleaningPorts?.includes(portId);
  const isSourceBin = !!(
    sourceBin &&
    thisBinNumber &&
    thisBinNumber === sourceBin.autostoreBinId
  );
  const isDestinationBin = !!(
    destinationBin &&
    thisBinNumber &&
    thisBinNumber === destinationBin.autostoreBinId
  );

  const { data: sourceBinInventory } = useGetInventoryByAutostoreBinNumberQuery(
    autostoreGridId && sourceBin
      ? {
          autostoreGridId,
          binNumber: sourceBin.autostoreBinId
        }
      : skipToken
  );

  const sourceCompartmentNumbersWithQty =
    sourceBinInventory &&
    ((isSourceBin && movingProduct) || (isDestinationBin && confirmingMovement))
      ? sourceBinInventory.map((inventory) => ({
          autostoreCompartmentNumber: inventory.bin.autostoreCompartmentNumber,
          quantity: inventory.count.value
        }))
      : undefined;

  const moveButtonDisabled = !movingProduct || !portStatus.isReady;
  const confirmButtonDisabled = !confirmingMovement || !portStatus.isReady;

  // request next bin for cleaning port
  useEffect(() => {
    if (!autostoreGridId) return;
    if (!isSourceBin && !isDestinationBin && portStatus.mode === "OPEN")
      requestNextBin({
        autostoreGridId,
        portId
      })
        .unwrap()
        .catch((err) => {
          errorToast(getMessageFromRtkError(err));
        });
  }, [
    isSourceBin,
    isDestinationBin,
    autostoreGridId,
    errorToast,
    requestNextBin,
    portId,
    portStatus.mode
  ]);

  // TODO: do we need this?
  useEffect(() => {
    if (sourceBin?.autostoreBinId || destinationBin?.autostoreBinId) {
      refetch().catch((error) => {
        errorToast(getMessageFromRtkError(error));
      });
    }
  }, [
    sourceBin?.autostoreBinId,
    destinationBin?.autostoreBinId,
    refetch,
    errorToast
  ]);

  return (
    <Stack direction="row" flex={1} position="relative">
      <Stack width={"100%"}>
        {!error && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                padding: 1,
                zIndex: 2,
                borderRadius: "0.5em",
                border: `0.625em solid`,
                borderColor:
                  (isSourceBin && movingProduct) ||
                  (isDestinationBin && confirmingMovement)
                    ? "primary.main"
                    : "transparent",
                animation:
                  isDestinationBin && confirmingMovement
                    ? "slide-in 750ms"
                    : "none",
                "@keyframes slide-in": {
                  from: {
                    transform: "translateX(-100%)"
                  },
                  to: {
                    transform: "translateX(0%)"
                  }
                }
              }}
            />
            <Paper
              role="button"
              sx={{
                backgroundColor:
                  (isSourceBin && movingProduct) ||
                  (isDestinationBin && confirmingMovement)
                    ? "background.lightBlue"
                    : "background.gray",
                p: 2,
                border: "none"
              }}
            >
              <AutostoreBin
                state={activityState}
                binId={thisBinNumber}
                pickQuantity={""}
                pickCompartment={null}
                numberOfRows={horizontalCompartmentCount ?? 1}
                numberOfColumns={verticalCompartmentCount ?? 1}
                compartmentNumberWithQuantities={
                  (isSourceBin && movingProduct) ||
                  (isDestinationBin && confirmingMovement)
                    ? sourceCompartmentNumbersWithQty
                    : undefined
                }
                activeTaskGroupCompartments={
                  sourceCompartmentNumbersWithQty?.map((c, i) =>
                    c.autostoreCompartmentNumber
                      ? c.autostoreCompartmentNumber - 1
                      : i
                  ) ?? []
                }
              />
            </Paper>
          </Box>
        )}

        {/* Sets of buttons depnding on type of bin (cleaning, source, destination) */}
        {isSourceBin && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            <Button
              variant="subtle"
              size="large"
              startIcon={<CloseCircle20Px />}
              onClick={() => dispatch(setTaskState("MovingProduct"))}
            >
              Cancel Movement
            </Button>
            {sourceBinInventory?.length && sourceBinInventory.length > 1 ? (
              <MoveInventoryButton
                sourceBinInventory={sourceBinInventory}
                disabled={moveButtonDisabled}
                portId={portId}
              />
            ) : (
              <Button
                size="large"
                startIcon={<ArrowDoubleRight20Px fill="white" />}
                disabled={moveButtonDisabled}
                onClick={() => dispatch(setTaskState("ConfirmingMovement"))}
              >
                {t("move inventory")}
              </Button>
            )}
          </Stack>
        )}
        {isDestinationBin && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            {confirmingMovement && binState ? (
              <AdjustButton
                portId={portId}
                state={activityState}
                binId={thisBinNumber}
                numberOfRows={horizontalCompartmentCount ?? 1}
                numberOfColumns={verticalCompartmentCount ?? 1}
                binInventory={sourceBinInventory}
                compartmentNumber={destinationBin.compartmentNumber}
              />
            ) : (
              <BinNotEmptyButton
                state={activityState}
                binId={thisBinNumber}
                numberOfRows={horizontalCompartmentCount ?? 1}
                numberOfColumns={verticalCompartmentCount ?? 1}
                portId={portId}
                binState={binState}
              />
            )}
            <Button
              size="large"
              startIcon={<CheckmarkCircle20Px fill="white" />}
              disabled={confirmButtonDisabled}
              // onClick={handleConfirmMove}
            >
              {t("release bin")}
            </Button>
          </Stack>
        )}
        {!!thisBinNumber && isCleaningBin && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            <BinNotEmptyButton
              state={activityState}
              binId={thisBinNumber}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              portId={portId}
              binState={binState}
            />
            <UnflagBinButton binState={binState} />
          </Stack>
        )}
      </Stack>
      {isDestinationBin && (
        <Box position="absolute" top="90px" left="-85px">
          <PulsingArrows />
        </Box>
      )}
    </Stack>
  );
};
