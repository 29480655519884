import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "~/app/store";

import { setCurrentEmptyBin } from "~/redux/actions";

import {
  clearSelectedInventoryId,
  clearSelectedVariant
} from "~/redux/actions/inventory";

import { BinOrProductResult } from "./AutostoreInventory";
import { setIsShowScanModalOpen } from "./inventory.slice";

/**
 * Returns a function that navigates to a variant when an option is set,
 * and closes the show scan modal whenever it is called.
 */
export function useAutocompleteSelect() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationInfo = useLocation();
  const { pathname, search } = locationInfo;

  const clearSelectedData = useCallback(() => {
    dispatch(clearSelectedVariant());
    dispatch(clearSelectedInventoryId());
    dispatch(setCurrentEmptyBin(null));
  }, [dispatch]);

  return useCallback(
    (option: BinOrProductResult | null) => {
      dispatch(setIsShowScanModalOpen(false));

      // don't redirect to the same page if same search result is selected
      if (option?.variantId && pathname.includes(option.variantId)) {
        return;
      }

      if (option && option.type !== "bin") {
        clearSelectedData();
        navigate({
          pathname: `/autostore-inventory/product/${option?.variantId ?? ""}`,
          search
        });
      } else if (option?.type === "bin") {
        clearSelectedData();
        navigate({
          pathname: `/autostore-inventory/bin/${option?.displayText ?? ""}`,
          search
        });
      }
    },
    [clearSelectedData, dispatch, navigate, pathname, search]
  );
}
