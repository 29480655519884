import "moment-timezone";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

const CardButton = styled(Card)(({ theme }) => {
  return {
    maxHeight: 420,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  };
});

type Props = { viewTitle?: ViewNameTranslation };

export function ManualOps({ viewTitle }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const showPickButton = useAppSelector(
    (state) =>
      !state.store.usersFulfillmentCenter?.pickingConfigurations ||
      state.store.usersFulfillmentCenter.pickingConfigurations.includes(
        "Manual"
      )
  );

  const showShipButton = useAppSelector(
    (state) =>
      !state.store.usersFulfillmentCenter?.waveCompletionConfiguration ||
      state.store.usersFulfillmentCenter.waveCompletionConfiguration ===
        "TotesVerified"
  );

  useNavbar({ viewTitle });

  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true
  });

  const rowCount = largeDisplay ? 2 : 3;

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Grid
        container
        sx={{
          marginTop: 2
        }}
        spacing={largeDisplay ? 2 : 1}
      >
        <Grid className="home_button_putaway" item xs={6} md={4}>
          <CardButton
            onClick={(): void => navigate(`/putaway`)}
            sx={{
              height: `calc((100vh - 120px) / ${rowCount})`
            }}
          >
            <CardContent>
              <Typography variant={largeDisplay ? "h5" : "h6"}>
                {t("putaway")}
              </Typography>
            </CardContent>
          </CardButton>
        </Grid>
        <Grid className="home_button_inventory" item xs={6} md={4}>
          <CardButton
            onClick={(): void => navigate(`/manual-inventory/product`)}
            sx={{
              height: `calc((100vh - 120px) / ${rowCount})`
            }}
          >
            <CardContent>
              <Typography variant={largeDisplay ? "h5" : "h6"}>
                {t("inventory lookup")}
              </Typography>
            </CardContent>
          </CardButton>
        </Grid>
        <Grid className="home_button_replenishment" item xs={6} md={4}>
          <CardButton
            className="home_button_inventory"
            onClick={(): void => navigate(`/inventory-holds`)}
            style={{
              height: `calc((100vh - 120px) / ${rowCount})`
            }}
          >
            <CardContent>
              <Typography variant={largeDisplay ? "h5" : "h6"}>
                {t("nav.link.inventory holds")}
              </Typography>
            </CardContent>
          </CardButton>
        </Grid>
        <Grid className="home_button_prep" item xs={6} md={4}>
          <CardButton
            onClick={(): void => navigate(`/cycle-count`)}
            style={{
              height: `calc((100vh - 120px) / ${rowCount})`
            }}
          >
            <CardContent>
              <Typography variant={largeDisplay ? "h5" : "h6"}>
                {t("nav.viewname.cycle counts")}
              </Typography>
            </CardContent>
          </CardButton>
        </Grid>
        {showPickButton && (
          <Grid className="home_button_pick" item xs={6} md={4}>
            <CardButton
              onClick={(): void => navigate(`/pick-batches`)}
              style={{
                height: `calc((100vh - 120px) / ${rowCount})`
              }}
            >
              <CardContent>
                <Typography variant={largeDisplay ? "h5" : "h6"}>
                  {t("nav.link.picking")}
                </Typography>
              </CardContent>
            </CardButton>
          </Grid>
        )}
        {showShipButton && (
          <Grid className="home_button_ship" item xs={6} md={4}>
            <CardButton
              onClick={(): void => navigate(`/ship/consolidation`)}
              style={{
                height: `calc((100vh - 120px) / ${rowCount})`
              }}
            >
              <CardContent>
                <Typography variant={largeDisplay ? "h5" : "h6"}>
                  {t("nav.link.order consolidation")}
                </Typography>
              </CardContent>
            </CardButton>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
