import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useBarcodeScanner } from "~/lib/barCodeScan";
import { getBarcodeValue } from "~/lib/helpers";

import {
  selectLotNumber,
  setLotNumber,
  selectIsLotNumberFieldFocused,
  setIsLotNumberFieldFocused
} from "./autostorePutaway.slice";

export function LotNumberField({ isRequired }: { isRequired: boolean }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lotNumber = useAppSelector(selectLotNumber);
  const isLotNumberFieldFocused = useAppSelector(selectIsLotNumberFieldFocused);

  useBarcodeScanner({
    findScanMatch: (buffer: string) => {
      const barcodeValue = getBarcodeValue(buffer);
      dispatch(setLotNumber(barcodeValue));
    },
    processScanMatch: () => null,
    disabled: !isLotNumberFieldFocused,
    shouldBlurInputs: false
  });

  return (
    <TextField
      label={t("lot number")}
      value={lotNumber}
      error={isRequired && !lotNumber}
      onChange={(event) => dispatch(setLotNumber(event.target.value))}
      onFocus={() => dispatch(setIsLotNumberFieldFocused(true))}
      onBlur={() => dispatch(setIsLotNumberFieldFocused(false))}
      variant="outlined"
      slotProps={{
        inputLabel: { shrink: true }
      }}
      sx={{
        ".MuiInputBase-input": {
          fontSize: "22px"
        }
      }}
    />
  );
}

export default LotNumberField;
