import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";

import { State, TestCategoryType } from "./workstationTest.slice";

export const eventFeedReportMargin = 15;

export const createEventFeedReportByTestCategory = (
  testNameOrigin: TestCategoryType | "All",
  completeEventFeed: {
    text: string;
    state: State;
    time: Date;
    testOrigin: TestCategoryType;
  }[],
  document: jsPDF,
  startingLineCoordinate: number
): { nextLineCoordinateCount: number } => {
  const pageHeight = document.internal.pageSize.height;
  const approximateRowHeight = 16;
  const eventFeedCategoryTitleHeight = 12;
  // category test subtitles, e.g: `Success events`
  const eventFeedCategorySubtitleHeight = 11;

  let eventFeedCategory = completeEventFeed.slice();
  if (testNameOrigin !== "All") {
    eventFeedCategory = completeEventFeed.filter(
      (event) => event.testOrigin === testNameOrigin && event.state !== "Info"
    );
  }

  if (!eventFeedCategory.length)
    return { nextLineCoordinateCount: startingLineCoordinate };

  const eventFeedCategoryContent = eventFeedCategory.map((eventData) => [
    moment(eventData.time).format("YYYY-MM-DD, h:mm:ss a"),
    eventData.state,
    eventData.text,
    eventData.testOrigin
  ]);

  let successEventsContent;
  let failedEventsContent;
  if (testNameOrigin !== "All") {
    successEventsContent = eventFeedCategoryContent.filter((eventContentRow) =>
      eventContentRow.includes("Success")
    );
    failedEventsContent = eventFeedCategoryContent.filter((eventContentRow) =>
      eventContentRow.includes("Failed")
    );
  }
  const headers = [
    "Date",
    "Event status",
    "Event Message",
    ...(testNameOrigin === "All" ? ["Test"] : [])
  ];
  let currentLineCoordinateCount = startingLineCoordinate;

  let eventFeedCategoryTitle;
  if (testNameOrigin !== "All") {
    eventFeedCategoryTitle = `${testNameOrigin} test report:`;
  } else {
    eventFeedCategoryTitle = "Complete event feed:";
  }

  const eventFeedCategoryContentToDisplay: {
    content: string[][];
    eventFeedContentType: string;
  }[] = [
    ...(successEventsContent && successEventsContent?.length
      ? [
          {
            content: successEventsContent,
            eventFeedContentType: "Success"
          }
        ]
      : []),
    ...(failedEventsContent && failedEventsContent?.length
      ? [
          {
            content: failedEventsContent,
            eventFeedContentType: "Failed"
          }
        ]
      : []),
    ...(testNameOrigin === "All"
      ? [
          {
            content: eventFeedCategoryContent,
            eventFeedContentType: "All"
          }
        ]
      : [])
  ];

  const eventFeedCategoryTitleHeightWithMargin =
    eventFeedCategoryTitleHeight + 5;
  //complete event feed has no subtitle(s)
  const testCategorySubtitleHeightWithMargin =
    testNameOrigin !== "All" ? eventFeedCategorySubtitleHeight + 6 : 0;
  const titleAndSubtitleTotalHeight =
    eventFeedCategoryTitleHeightWithMargin +
    testCategorySubtitleHeightWithMargin;

  //if we can't fit at least test title, subtitle and first 2 rows of a table
  //then add new page and move the text onto the newly created page
  if (
    currentLineCoordinateCount +
      titleAndSubtitleTotalHeight +
      approximateRowHeight * 2 >
    pageHeight - eventFeedReportMargin
  ) {
    document.addPage();
    currentLineCoordinateCount = eventFeedReportMargin;
  }

  document.setFontSize(eventFeedCategoryTitleHeight);
  document.setFont("helvetica", "bold");
  document.text(eventFeedCategoryTitle, 10, currentLineCoordinateCount);
  currentLineCoordinateCount += 5;

  eventFeedCategoryContentToDisplay.forEach(
    ({ content, eventFeedContentType }, eventFeedContentIndex) => {
      const currentTableRowHeights: number[] = [];

      if (eventFeedContentType !== "All") {
        if (eventFeedContentIndex === 0) currentLineCoordinateCount += 3;
        const tableTitle = `${eventFeedContentType} events:`;
        document.setFont("helvetica", "normal");
        document.setFontSize(eventFeedCategorySubtitleHeight);
        document.text(tableTitle, 12, currentLineCoordinateCount);
        currentLineCoordinateCount += 3;
      }
      let finalTableLineCoordinate = 0;
      document.autoTable({
        head: [headers],
        body: content,
        startY: currentLineCoordinateCount,
        startX: 12,
        styles: {
          halign: "center",
          cellPadding: 3,
          fontSize: 10
        },
        headStyles: {
          fillColor: [232, 232, 232],
          textColor: [33, 33, 33],
          lineWidth: 0.3,
          lineColor: [0, 0, 0, 0.2]
        },
        bodyStyles: {
          lineWidth: 0.3,
          lineColor: [0, 0, 0, 0.2]
        },
        didDrawPage: (data: DidDrawPage) => {
          finalTableLineCoordinate = data.cursor.y;
        },
        didDrawCell: (data: DidDrawCellData) => {
          if (data.section === "body" || data.section === "head") {
            const rowHeight = data.row.height;
            const rowIndex = data.row.index;
            //keep track of the exact height of each row
            if (!currentTableRowHeights[rowIndex]) {
              currentTableRowHeights[rowIndex] = rowHeight;
            }
          }
        }
      });

      currentTableRowHeights.forEach((height: number) => {
        currentLineCoordinateCount += height;
      });
      //if calculated height is greater than page height it means the table has been rendered on a new page
      //this means we have to use the coordinate of the newly created page
      if (currentLineCoordinateCount > pageHeight - eventFeedReportMargin) {
        currentLineCoordinateCount = finalTableLineCoordinate;
      }
      currentLineCoordinateCount += 20;
    }
  );

  return { nextLineCoordinateCount: currentLineCoordinateCount };
};
