import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ProductInfoTypography } from "./styles";

type Props = {
  quantity: string;
};
export function InlineQuantity({ quantity }: Props) {
  const { t } = useTranslation();
  return (
    <Box>
      <ProductInfoTypography variant="overline">
        {t("quantity")}
      </ProductInfoTypography>
      <Typography
        gutterBottom
        variant="h6"
        component="h6"
        style={{ fontWeight: 400 }}
      >
        {quantity}
      </Typography>
    </Box>
  );
}
