import { Box, Card, Container, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";

export function Loading(props: {
  height?: string | number;
  innerHeight?: string | number;
  marginTop?: string;
  disableGutters?: boolean;
}) {
  const { height, marginTop, innerHeight, disableGutters } = props;
  return (
    <Container disableGutters={disableGutters}>
      <Card
        style={{
          marginTop,
          paddingTop: 10,
          overflow: "visible",
          height
        }}
        aria-busy={true}
      >
        <Stack gap={1} padding={1}>
          <Skeleton
            height={40}
            variant="rectangular"
            animation={false}
            role="progressbar"
          />
          <Skeleton
            height={40}
            variant="rectangular"
            animation={false}
            role="progressbar"
          />
          <Skeleton
            height={40}
            variant="rectangular"
            animation={false}
            role="progressbar"
          />
        </Stack>
        <Box height={height} padding={1}>
          <Skeleton
            variant="rectangular"
            animation={false}
            height={innerHeight || 265}
          />
        </Box>
      </Card>
    </Container>
  );
}
