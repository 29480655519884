import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { orderTypesDropdownSlice } from "~/components/orderTypes/orderTypesDropdown.slice";
import { andonReducer } from "~/features/andon/andon.slice";
import { autostoreBinConfigSlice } from "~/features/autostoreBinConfigModal/autostoreBinConfigModal.slice";
import { autostoreCycleCountsSlice } from "~/features/autostoreCycleCounts/autostoreCycleCounts.slice";
import { autostoreInventoryHoldsSlice } from "~/features/autostoreInventoryHolds/autostoreInventoryHolds.slice";
import { addToteModalSlice } from "~/features/autostorePicking/addToteModal.slice";
import { scanAndApplyLabelSlice } from "~/features/autostorePicking/applyLabelDialog/scanAndApplyLabel.slice";
import { autostorePickingSlice } from "~/features/autostorePicking/autostorePicking.slice";
import { confirmPickQuantityModalSlice } from "~/features/autostorePicking/confirmPickQuantityModal/confirmPickQuantityModal.slice";
import { outOfStockDialogSlice } from "~/features/autostorePicking/outOfStockDialog/outOfStockDialog.slice";
import { problemSolveModalSlice } from "~/features/autostorePicking/problemSolveModal.slice";
import { restockPickModalSlice } from "~/features/autostorePicking/restockPickModal.slice";
import { pickingScannedBarcodeSlice } from "~/features/autostorePicking/slices/pickingScannedBarcode.slice";
import { autostorePutawaySlice } from "~/features/autostorePutaway/autostorePutaway.slice";
import { autostorePutawayOldSlice } from "~/features/autostorePutawayOld/autostorePutawayOld.slice";
import { batchSlice } from "~/features/batch/batch.slice";
import { batchCardsSlice } from "~/features/batchList/batchCards.slice";
import { adminBatchesSlice } from "~/features/batches/adminBatches.slice";
import { binMaintenanceWorkstationSlice } from "~/features/binMaintenanceWorkstation/binMaintenanceWorkstation.slice";
import { binReconfigurationSlice } from "~/features/binReconfiguration/binReconfiguration.slice";
import { cartsSlice } from "~/features/carts/carts.slice";
import { cleaningBinSlice } from "~/features/cleaningBin/cleaningBin.slice";
import { createBatchesSlice } from "~/features/createBatches/createBatches.slice";
import { cycleCountsSlice } from "~/features/cycleCounts/cycleCounts.slice";
import { cycleCountsNewSlice } from "~/features/cycleCounts2023/cycleCountsNew.slice";
import { inventorySlice } from "~/features/inventory/inventory.slice";
import { inventoryHoldsSlice } from "~/features/inventoryHolds/inventoryHolds.slice";
import { login } from "~/features/login/login.slice";
import { lowInventorySlice } from "~/features/lowInventory/lowInventory.slice";
import { manageFlaggedInventorySlice } from "~/features/manageFlaggedInventory/manageFlaggedInventory.slice";
import { manualInventorySlice } from "~/features/manualInventory/manualInventory.slice";
import { orderCreationSlice } from "~/features/orderCreation/orderCreation.slice";
import { ordersSlice } from "~/features/orders/orders.slice";
import { pickBatchesSlice } from "~/features/pickBatches/pickBatches.slice";
import { putawaySlice } from "~/features/putaway/putaway.slice";
import { serviceSupportSlice } from "~/features/serviceSupport/serviceSupport.slice";
import { devSettingsReducer } from "~/features/settings/devSettings.slice";
import { shipSlice } from "~/features/ship/ship.slice";
import { shipOrderSlice } from "~/features/shipOrder/shipOrder.slice";
import { stageTotesSlice } from "~/features/stage/stageTotes.slice";
import { startPickingSlice } from "~/features/startPickingModal/startPicking.slice";
import { statusSlice } from "~/features/status/status.slice";
import { workstationConfigSlice } from "~/features/workstationConfig/workstationConfig.slice";
import { workstationTestReducer } from "~/features/workstationTest/workstationTest.slice";
import { pepsiApi } from "~/redux/pepsi/pepsiApi";
import { publicApi } from "~/redux/public/publicApi";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";

import { autostore } from "./autostore";
import { batch } from "./batch";
import { conveyance } from "./conveyance";
import { cycleCounts } from "./cycleCounts";
import { home } from "./home";
import { inventory } from "./inventory";
import { printerSlice } from "./printer";
import { ptl } from "./ptl";
import { site } from "./site";
import { store } from "./store";
import { version } from "./version";
import { workstations } from "./workstations";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["nothing"]
};

export const rootReducer = combineReducers({
  addToteModal: addToteModalSlice.reducer,
  adminBatches: adminBatchesSlice.reducer,
  andon: andonReducer,
  autostore,
  autostoreCycleCounts: autostoreCycleCountsSlice.reducer,
  autostoreBinConfigModal: autostoreBinConfigSlice.reducer,
  autostoreInventoryHolds: autostoreInventoryHoldsSlice.reducer,
  autostorePutaway: autostorePutawaySlice.reducer,
  autostorePicking: autostorePickingSlice.reducer,
  autostorePutawayOld: autostorePutawayOldSlice.reducer,
  batch,
  batchCards: batchCardsSlice.reducer,
  batchSlice: batchSlice.reducer,
  binMaintenanceWorkstation: binMaintenanceWorkstationSlice.reducer,
  binReconfiguration: binReconfigurationSlice.reducer,
  carts: cartsSlice.reducer,
  cleaningBin: cleaningBinSlice.reducer,
  confirmPickQuantityModalSlice: confirmPickQuantityModalSlice.reducer,
  conveyance,
  createBatches: createBatchesSlice.reducer,
  cycleCounts,
  cycleCountsSlice: cycleCountsSlice.reducer,
  cycleCountsNewSlice: cycleCountsNewSlice.reducer,
  devSettings: devSettingsReducer,
  problemSolveModal: problemSolveModalSlice.reducer,
  home,
  inventory,
  inventoryHolds: inventoryHoldsSlice.reducer,
  inventoryNew: inventorySlice.reducer,
  manualInventory: manualInventorySlice.reducer,
  login,
  lowInventory: lowInventorySlice.reducer,
  manageFlaggedInventory: manageFlaggedInventorySlice.reducer,
  orderCreation: orderCreationSlice.reducer,
  ordersSlice: ordersSlice.reducer,
  orderTypesDropdown: orderTypesDropdownSlice.reducer,
  outOfStockDialog: outOfStockDialogSlice.reducer,
  pickBatches: pickBatchesSlice.reducer,
  pickingScannedBarcode: pickingScannedBarcodeSlice.reducer,
  printer: printerSlice.reducer,
  ptl,
  putaway: putawaySlice.reducer,
  restockPickModal: restockPickModalSlice.reducer,
  scanAndApplyLabel: scanAndApplyLabelSlice.reducer,
  serviceSupport: serviceSupportSlice.reducer,
  shipOrder: shipOrderSlice.reducer,
  ship: shipSlice.reducer,
  site,
  stageTotes: stageTotesSlice.reducer,
  startPicking: startPickingSlice.reducer,
  status: statusSlice.reducer,
  store,
  version,
  workstations,
  workstationConfig: workstationConfigSlice.reducer,
  workstationTest: workstationTestReducer,
  [warehouseApi.reducerPath]: warehouseApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [pepsiApi.reducerPath]: pepsiApi.reducer
});

export const mainReducer = persistReducer(rootPersistConfig, rootReducer);

export type StoreState = ReturnType<typeof rootReducer>;
