import { chipClasses, Components, Theme } from "@mui/material";

import { CloseSmall20pxIcon } from "../icons/closeSmall20pxIcon/CloseSmall20pxIcon";

import { mainColours } from "./colours";

/**
 * The autostore design system names this component a Tag, and does not mention button behaviour of the chip
 * at large, but does specify a clickable x to remove the chip.
 *
 * https://www.figma.com/design/zdhfFjAIGP8jW6tibpOlHs/ASDS-%E2%80%93-Components?node-id=462-5043&m=dev
 */
export const muiChip: Components<Omit<Theme, "components">> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.color &&
          Object.hasOwn(mainColours.label, ownerState.color) && {
            // @ts-expect-error the typescript compiler does not narrow on hasOwn
            backgroundColor: mainColours.label[ownerState.color]
          }),
        ...(ownerState.color &&
          ["ambient", "chilled", "frozen"].includes(ownerState.color) && {
            backgroundColor: `${ownerState.color}.main`
          }),
        borderRadius: "999px",
        [`& .${chipClasses.deleteIcon}`]: {
          color: theme.palette.text.primary,
          borderRadius: "999px",
          margin: "0 5px 0 -10px"
        },
        [`& .${chipClasses.deleteIcon}:hover`]: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.hover
        }
      }),
      sizeMedium: ({ theme }) => ({
        fontSize: theme.typography.pxToRem(14)
      })
    },
    defaultProps: {
      deleteIcon: <CloseSmall20pxIcon />,
      variant: "filled"
    }
  }
};
