import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Stack,
  Box,
  InputLabel,
  Tooltip,
  Typography,
  ClickAwayListener,
  Chip,
  TextField,
  Autocomplete,
  FormControl
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { workstationRoles } from "~/lib/helpers";
import { selectDropdownWorkstation } from "~/redux/selectors/workstationConfigSelectors";
import { UpdateWorkstation } from "~/types/api";

export function WorkstationRoles() {
  const { t } = useTranslation();
  const [showRolesInfo, setShowRolesInfo] = useState(false);
  const { control } = useFormContext<UpdateWorkstation>();
  const workstation = useAppSelector(selectDropdownWorkstation);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        minHeight: "60px"
      }}
    >
      <Box width="100%">
        <Stack direction="row" justifyContent="space-between">
          <InputLabel shrink>{t("roles")}</InputLabel>
          <ClickAwayListener onClickAway={() => setShowRolesInfo(false)}>
            <Tooltip
              title={
                <Typography variant="body2">
                  {t("workstation roles tooltip")}
                </Typography>
              }
              open={showRolesInfo}
              disableHoverListener
              placement="right"
            >
              <HelpOutlineIcon
                sx={{
                  cursor: "pointer",
                  color: "gray"
                }}
                onClick={() => setShowRolesInfo(true)}
              />
            </Tooltip>
          </ClickAwayListener>
        </Stack>

        <FormControl fullWidth>
          <Controller
            name="roles"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                fullWidth
                multiple
                autoComplete={false}
                disableCloseOnSelect
                disableClearable
                getOptionDisabled={(option) =>
                  workstation?.roles.length === 1 &&
                  workstation.roles[0].toLowerCase() === option.toLowerCase()
                }
                options={workstationRoles}
                value={value}
                onChange={(_, values) => onChange(values)}
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    {...params}
                    onChange={onChange}
                    variant="standard"
                    slotProps={{
                      htmlInput: {
                        ...inputProps,
                        readOnly: true
                      }
                    }}
                    sx={{
                      input: { cursor: "pointer" },
                      minWidth: "150px"
                    }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`role-${index}`}
                      label={option}
                      color="1"
                      disabled={
                        workstation?.roles.length === 1 &&
                        workstation.roles[0].toLowerCase() ===
                          option.toLowerCase()
                      }
                    />
                  ))
                }
              />
            )}
          />
        </FormControl>
      </Box>
    </Stack>
  );
}
