import { Alert, Box, Typography, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { AutostoreBin } from "~/features/autostoreBin";
import { addEvent } from "~/features/workstationTest/workstationTest.slice";
import { usePortStatusQuery } from "~/hooks/usePortStatus";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  useCloseBinMutation,
  useRequestNextBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";

type PortInheritedProps = {
  portId: number;
  portSideIndex: number;
  binToPortTestLoopingEnabled?: boolean;
};

const Port = ({
  portId,
  portSideIndex,
  binToPortTestLoopingEnabled
}: PortInheritedProps) => {
  const dispatch = useAppDispatch();
  const workstation = useAppSelector(selectThisWorkstation);
  const [closeBin] = useCloseBinMutation();
  const [openBin] = useRequestNextBinMutation();
  const {
    binPortSideIndex,
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId, portSideIndex);
  const selectedBinNumber = portStatus?.selectedBin;

  usePromiseInterval(
    async () => {
      if (!workstation || !selectedBinNumber || !portStatus?.isReady) return;

      await closeBin({
        autostoreGridId: workstation.autostoreGridId,
        portId,
        binNumber: selectedBinNumber
      }).unwrap();

      setTimeout(() => {
        void (async () => {
          await openBin({
            autostoreGridId: workstation.autostoreGridId,
            portId
          }).unwrap();
        })();
      }, 2000);
    },
    3000,
    binToPortTestLoopingEnabled || false
  );

  useEffect(() => {
    if (error && portSideIndex === 0) {
      dispatch(
        addEvent({
          text: `Bin to Port: failed getting port status for port ${portId}`,
          state: "Failed",
          testOrigin: "Bin to Port"
        })
      );
    }
  }, [dispatch, error, portId, portSideIndex]);

  return (
    <Stack
      sx={{
        flexDirection: workstation?.multiPortEnabled ? "row" : "column",
        gap: 2,
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          width: "100%",
          margin: 1
        }}
      >
        <Typography variant="h5" fontWeight={500} m={1} textAlign="center">
          Port {portId}
        </Typography>
        {!error && (
          <AutostoreBin
            state={activityState}
            binId={selectedBinNumber}
            pickQuantity={""}
            pickCompartment={null}
            numberOfRows={
              (portSideIndex === binPortSideIndex &&
                horizontalCompartmentCount) ||
              1
            }
            numberOfColumns={
              (portSideIndex === binPortSideIndex &&
                verticalCompartmentCount) ||
              1
            }
          />
        )}
      </Box>
      {!!error && portSideIndex === 0 && (
        <Alert
          sx={{
            maxWidth: "300px"
          }}
          variant="outlined"
          severity="error"
          action={<Button onClick={refetch}>Retry</Button>}
        >
          {getMessageFromRtkError(error)}
        </Alert>
      )}
    </Stack>
  );
};

export default Port;
