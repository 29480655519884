import { Box, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "~/app/store";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";

import WorkstationTest from "./WorkstationTest";

import { resetWorkstationTestState } from "./workstationTest.slice";

const WorkstationTestView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const validTabs = ["workstation-test-functionality"];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "workstation-test-functionality",
    validTabs,
    key: "testing-tools-tab"
  });

  useEffect(() => {
    return () => {
      dispatch(resetWorkstationTestState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(_event, newValue) => {
          handleTabIndexChange(newValue as string);
        }}
        sx={{
          ml: 2
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={`${t("workstation test functionality")}`}
          value="workstation-test-functionality"
        />
      </Tabs>

      <Box
        id="tab-content"
        sx={{
          height: `calc(100% - 48px)`,
          overflow: "scroll",
          "&::-webkit-scrollbar": {
            display: "none"
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        {tabIndex === "workstation-test-functionality" && <WorkstationTest />}
      </Box>
    </>
  );
};

export default WorkstationTestView;
