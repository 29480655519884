import { FormControl, FormLabel, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

import { Auth0Profile } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { LanguageCode, languageCodes } from "~/localization_i18n";
import { setLanguage } from "~/redux/actions/site";

import { selectAuth0Profile } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

export const UserSettings = () => {
  const siteLanguageCode = useAppSelector((state) => state.site.languageCode);
  const clientConfig = useAppSelector(selectClientConfig);
  const profile = useAppSelector(selectAuth0Profile);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLanguageSelect = (langCode: LanguageCode): void => {
    dispatch(setLanguage(langCode));
  };

  const hasRequiredRoles = (profile: Auth0Profile | null): string => {
    if (profile) {
      const requiredRoles = [
        "powerpick-picker",
        "powerpick-inventory-manager",
        "powerpick-receiver",
        "client-administrator",
        "qubit-workstation-configurer"
      ];
      return profile.roles.some((role) => requiredRoles.includes(role))
        ? ""
        : "No sufficient permissions set for this profile";
    } else {
      return "";
    }
  };

  const languageCodeMap: Partial<Record<LanguageCode, string>> = {
    ...(clientConfig.site_defaultLanguageCode === "bs"
      ? { bs: "English" }
      : { en: "English" }),
    ko: "Korean",
    es: "Spanish",
    fr: "French",
    ja: "Japanese"
  };

  const selectedLanguage = languageCodeMap[siteLanguageCode];

  return (
    <>
      <Typography>{hasRequiredRoles(profile)}</Typography>
      <FormControl sx={{ maxWidth: "sm" }}>
        <FormLabel id="user-language-label">{t("language")}</FormLabel>
        <Select
          labelId="user-language-label"
          variant="outlined"
          value={selectedLanguage || ""}
          size="small"
        >
          {languageCodes.map((langCode) => (
            <MenuItem
              key={langCode}
              value={languageCodeMap[langCode]}
              onClick={() => {
                handleLanguageSelect(langCode);
              }}
            >
              {languageCodeMap[langCode]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
