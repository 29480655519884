import { ArrowDoubleRight20Px } from "@locaisolutions/icons";
import {
  Box,
  Container,
  Stack,
  SvgIcon,
  Typography,
  styled
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import { useStartBinMaintenanceMutation } from "~/redux/warehouse/autostoreBinMaintenance.hooks";

import { BinMaintenancePort } from "./BinMaintenancePort";
import { FlagBinButton } from "./FlagBinButton";
import {
  setMovementData,
  setTaskState
} from "./binMaintenanceWorkstation.slice";

interface BinMaintenanceWorkstation {
  viewTitle: ViewNameTranslation;
}

const ActionHeader = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(14)} auto`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  width: "fit-content"
}));

export const BinMaintenanceWorkstation: React.FC<BinMaintenanceWorkstation> = (
  props
) => {
  const { viewTitle } = props;
  useNavbar({ viewTitle });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const ports = useAppSelector(selectWorkstationAvailablePorts);
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const [startBinMaintenance] = useStartBinMaintenanceMutation();

  // initiate bin maintenance on landing
  useEffect(() => {
    if (!autostoreGridId || !selectedWorkstation?.id) return;
    const startInventoryMovements = async () => {
      try {
        const startMovementData = await startBinMaintenance({
          autostoreGridId,
          workstationId: selectedWorkstation?.id
        }).unwrap();
        dispatch(setMovementData(startMovementData));
      } catch (error) {
        errorToast(getMessageFromRtkError(error));
      }
    };
    void startInventoryMovements();
  }, [
    autostoreGridId,
    selectedWorkstation?.id,
    startBinMaintenance,
    errorToast,
    dispatch
  ]);

  // get list of inventory movements
  // const {
  //   data: tasks,
  //   isLoading: isLoadingGetMovementsState,
  //   isSuccess: isGetMovementsStateSuccess,
  //   refetch
  // } = useGetInventoryMovementsStateQuery(
  //   autostoreGridId && selectedWorkstation
  //     ? { autostoreGridId, workstationId: selectedWorkstation.id }
  //     : skipToken
  // );

  useEffect(() => {
    if (selectedWorkstation?.ports.length) {
      dispatch(setTaskState("MovingProduct"));
    }
  }, [selectedWorkstation?.ports, dispatch]);

  return (
    <Container maxWidth="xl">
      <Stack direction="column" width="1360px" justifySelf="center">
        <ActionHeader>
          <Stack direction="row">
            <SvgIcon
              component={ArrowDoubleRight20Px}
              sx={{ fontSize: "48px", alignSelf: "end" }}
            />
            <Typography variant="h3">
              {movementTaskState === "MovingProduct"
                ? t("begin inventory move")
                : t("confirm inventory move")}
            </Typography>
          </Stack>
        </ActionHeader>

        <Stack direction="row" justifyContent="space-between" spacing={10}>
          {ports.map((portId) => {
            return <BinMaintenancePort key={portId} portId={portId} />;
          })}
        </Stack>
        <Box display="flex" alignSelf="center" mt={18}>
          <FlagBinButton />
        </Box>
      </Stack>
    </Container>
  );
};
