import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const TestsTypes = ["Scanner", "Bin to Port"] as const;
export type TestCategoryType = (typeof TestsTypes)[number];

export type State = "Success" | "Info" | "Failed";

export type EventFeedFiltersTypes =
  | "success events"
  | "error events"
  | "info events";
export type EventFeedFilters = { [event in State]: EventFeedFiltersTypes };
export const eventFeedFiltersList: EventFeedFilters = {
  Success: "success events",
  Failed: "error events",
  Info: "info events"
};

type AdditionalTestProperties = {
  lastScannedBarcode?: string | null;
};
export type WorkstationTestState = {
  activeTest: TestCategoryType;
  testsCompleted: {
    [Property in TestCategoryType]+?: {
      state: State;
      additionalProps?: AdditionalTestProperties;
    };
  };
  events: {
    text: string;
    state: State;
    time: Date;
    testOrigin: TestCategoryType;
  }[];
  eventsFeedFilters: string[];
};

export const initialState: WorkstationTestState = {
  activeTest: "Scanner",
  testsCompleted: {},
  events: [],
  eventsFeedFilters: []
};

export const workstationTestSlice = createSlice({
  name: "workstationTest",
  initialState,
  reducers: {
    setTestCaseState(
      state,
      {
        payload
      }: PayloadAction<{
        test: TestCategoryType;
        state: State;
        skipLogging?: boolean;
        additionalProps?: AdditionalTestProperties;
      }>
    ) {
      state.testsCompleted[payload.test] = {
        state: payload.state,
        ...(payload.additionalProps && {
          additionalProps: payload.additionalProps
        })
      };

      if (payload.state && !payload.skipLogging)
        state.events.push({
          text: `${payload.test} test: ${payload.state}`,
          state: payload.state,
          time: new Date(),
          testOrigin: payload.test
        });
    },
    setActiveTest(state, { payload }: PayloadAction<TestCategoryType>) {
      state.activeTest = payload;
    },
    addEvent(
      state,
      {
        payload
      }: PayloadAction<{
        text: string;
        state: State;
        testOrigin: TestCategoryType;
      }>
    ) {
      state.events.push({ ...payload, time: new Date() });
    },
    resetWorkstationTestState(state) {
      state.activeTest = "Scanner";
      state.events = [];
      state.testsCompleted = {};
    },
    resetWorkstationTest(state, { payload }: PayloadAction<TestCategoryType>) {
      state.testsCompleted[payload] = undefined;
    },
    setEventsFeedFilters(state, { payload }: PayloadAction<string[]>) {
      state.eventsFeedFilters = payload;
    },
    clearEventsFeedFilters(state) {
      state.eventsFeedFilters = [];
    }
  },
  selectors: {
    selectActiveTest: (state: WorkstationTestState) => state.activeTest,
    selectCompletedTests: (state: WorkstationTestState) => state.testsCompleted,
    selectEventFeed: (state: WorkstationTestState) => state.events,
    selectEventsFeedFilters: (state: WorkstationTestState) =>
      state.eventsFeedFilters
  }
});
export const {
  setTestCaseState,
  setActiveTest,
  addEvent,
  resetWorkstationTestState,
  resetWorkstationTest,
  setEventsFeedFilters,
  clearEventsFeedFilters
} = workstationTestSlice.actions;

export const {
  selectActiveTest,
  selectEventFeed,
  selectCompletedTests,
  selectEventsFeedFilters
} = workstationTestSlice.selectors;

export const workstationTestReducer = workstationTestSlice.reducer;
