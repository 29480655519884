import InfoIcon from "@locaisolutions/icons/dist/icons20px/Attention20Px";
import CheckCircleIcon from "@locaisolutions/icons/dist/icons24px/CheckmarkCircle24Px";
import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Card,
  Icon,
  Stack,
  Typography,
  styled,
  Box,
  useTheme,
  IconButton,
  Palette
} from "@mui/material";
import { NavbarFilters } from "@qubit/autoparts";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  clearEventsFeedFilters,
  eventFeedFiltersList,
  selectEventFeed,
  selectEventsFeedFilters,
  setEventsFeedFilters,
  State,
  TestCategoryType,
  TestsTypes
} from "./workstationTest.slice";
import {
  createEventFeedReportByTestCategory,
  eventFeedReportMargin
} from "./workstationTestUtils";

const EventFeedContainer = styled(Stack)(
  ({ theme: { palette, shadows, spacing } }) => ({
    boxShadow: shadows["3"],
    backgroundColor: palette.secondary.light,
    borderRadius: 10,
    alignSelf: "baseline",
    gridRow: "span 3",
    justifySelf: "stretch",
    margin: spacing(1),
    gap: spacing(1),
    flexDirection: "column",
    padding: spacing(2),
    height: "90%"
  })
);

const EventFeedContent = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(1),
  height: "100%",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none"
}));

const EventFeedMessagesContainer = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(1.5),
  flexDirection: "column-reverse",
  justifyContent: "flex-end"
}));

export const getEventMessageStyling = (state: State, palette: Palette) => {
  switch (state) {
    case "Success":
      return {
        icon: (
          <CheckCircleIcon
            style={{
              fill: palette.success.main,
              height: "25px",
              width: "25px"
            }}
          />
        ),
        backgroundColor: "success.light"
      };
    case "Failed":
      return {
        icon: (
          <ErrorIcon
            style={{
              fill: palette.error.main,
              height: "25px",
              width: "25px"
            }}
          />
        ),
        backgroundColor: "error.light"
      };
    case "Info":
    default:
      return {
        icon: (
          <InfoIcon
            style={{
              fill: palette.primary.main,
              height: "25px",
              width: "25px"
            }}
          />
        ),
        backgroundColor: "primary.light"
      };
  }
};

const EventFeed = () => {
  const dispatch = useAppDispatch();
  const events = useAppSelector(selectEventFeed);
  const selectedEventFeedFilters = useAppSelector(selectEventsFeedFilters);
  const { t } = useTranslation();
  const { palette } = useTheme();

  const eventFilterStateTypes = Object.keys(eventFeedFiltersList) as State[];
  const eventFilterMenuList = eventFilterStateTypes.map(
    (filterOptionKey: State) => {
      const filterOptionValue = eventFeedFiltersList[filterOptionKey];
      return {
        translation: t(filterOptionValue),
        filter: filterOptionValue,
        icon: getEventMessageStyling(filterOptionKey, palette).icon
      };
    }
  );

  const eventsToDisplay = useMemo(() => {
    if (selectedEventFeedFilters.length)
      return events.filter((event) => {
        return (
          event.state &&
          selectedEventFeedFilters.includes(eventFeedFiltersList[event.state])
        );
      });
    return events;
  }, [events, selectedEventFeedFilters]);

  const createEventFeedReport = () => {
    const eventFeedReportDocument = new jsPDF({
      orientation: "portrait",
      format: "a4"
    });
    let lineCoordinateCount = eventFeedReportMargin;

    eventFeedReportDocument.setFontSize(16);
    eventFeedReportDocument.setFont("helvetica", "bold");
    eventFeedReportDocument.text(
      [
        `Qubit - Workstation functionality testing report`,
        `${moment().format("YYYY-MM-DD")}`
      ],
      100,
      lineCoordinateCount,
      {
        align: "center"
      }
    );

    lineCoordinateCount += 25;

    const testCategoryTypesToDisplay: (TestCategoryType | "All")[] = [
      ...TestsTypes,
      "All"
    ];
    eventFeedReportDocument.setFont("helvetica", "normal");
    testCategoryTypesToDisplay.forEach((testNameOrigin) => {
      const { nextLineCoordinateCount } = createEventFeedReportByTestCategory(
        testNameOrigin,
        events,
        eventFeedReportDocument,
        lineCoordinateCount
      );

      if (
        lineCoordinateCount < nextLineCoordinateCount &&
        testNameOrigin !== "All"
      )
        lineCoordinateCount = nextLineCoordinateCount + 5;
      else lineCoordinateCount = nextLineCoordinateCount;
    });
    const pageCount = eventFeedReportDocument.getNumberOfPages();

    const pageWidth = eventFeedReportDocument.internal.pageSize.width;
    const pageHeight = eventFeedReportDocument.internal.pageSize.height;

    for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
      eventFeedReportDocument.setPage(pageNumber);
      eventFeedReportDocument.setFontSize(9);
      eventFeedReportDocument.setFont("helvetica", "normal");
      eventFeedReportDocument.text(
        `Page ${pageNumber} of ${pageCount}`,
        pageWidth / 2,
        pageHeight - 5,
        {
          align: "center"
        }
      );
    }
    const pdfBlob = eventFeedReportDocument.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    const eventFeedReportTab = window.open(pdfUrl, "_blank");

    if (eventFeedReportTab) {
      const revokeUrlOnClose = () => {
        URL.revokeObjectURL(pdfUrl);
      };
      eventFeedReportTab.addEventListener("beforeunload", revokeUrlOnClose);
      //revoke object url when user closes tab
      eventFeedReportTab.onload = () => {
        eventFeedReportTab.removeEventListener(
          "beforeunload",
          revokeUrlOnClose
        );
      };
    }
  };

  return (
    <EventFeedContainer>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          justifyItems: "center",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            gridColumn: "2/5",
            margin: "0 auto"
          }}
        >
          <Typography
            sx={{
              color: "darkGray.dark"
            }}
            variant="h6"
          >
            {t("event feed")}
          </Typography>
        </Box>
        <Stack
          sx={{
            gridColumn: "-1",
            gap: 1,
            flexDirection: "row"
          }}
        >
          <NavbarFilters
            menuItemList={eventFilterMenuList}
            selectedMenuItems={selectedEventFeedFilters}
            menuItemsTitle={t("event type")}
            onFilterData={(filters) => {
              dispatch(setEventsFeedFilters(filters));
            }}
            onResetFilters={() => {
              dispatch(clearEventsFeedFilters());
            }}
          />
          <IconButton
            aria-label="event feed report export button"
            onClick={createEventFeedReport}
            disabled={!events.length}
          >
            <OpenInNewIcon sx={{ color: "secondary.contrastText" }} />
          </IconButton>
        </Stack>
      </Box>
      <EventFeedContent>
        {eventsToDisplay.length > 0 ? (
          <EventFeedMessagesContainer>
            {eventsToDisplay.map((event, i) => {
              const { icon, backgroundColor } = getEventMessageStyling(
                event.state || "Info",
                palette
              );
              return (
                <Card
                  sx={{ p: 1, backgroundColor, minHeight: 50 }}
                  key={`event-message-${i}`}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Icon
                      sx={{
                        height: 40,
                        width: 40,
                        m: "10px 5px 0px 0px"
                      }}
                    >
                      {icon}
                    </Icon>
                    <Stack flexDirection="column">
                      <Typography variant="body1">{event.text}</Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "darkGray.main",
                          fontWeight: "400"
                        }}
                      >
                        {moment(event.time).format("MMMM Do, h:mm a")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              );
            })}
          </EventFeedMessagesContainer>
        ) : (
          <Typography
            sx={{
              fontStyle: "italic"
            }}
          >
            {t("no events found")}
          </Typography>
        )}
      </EventFeedContent>
    </EventFeedContainer>
  );
};

export default EventFeed;
