import { Components, Theme } from "@mui/material";

export const muiFormLabel: Components<Omit<Theme, "components">> = {
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary
      })
    }
  }
};
