import { warehouseApi } from "./warehouseApi";

export type ClientConfigResponse = {
  client_config: string | null; // JSON string
};

export const clientConfigApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientConfig: build.query<ClientConfigResponse, void>({
      query: () => ({
        url: `/clients/configuration`,
        method: "GET"
      }),
      providesTags: ["client config"]
    })
  }),
  overrideExisting: false
});
