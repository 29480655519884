import { parse, stringify } from "qs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { setSelectedSummaries } from "~/features/inventory/inventory.slice";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import {
  selectThisWorkstation,
  selectWorkstationHasInventoryRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export function InventoryHoldsButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search }: { search: string } = useLocation();
  const trackedPageName = useMixpanelPageName();
  const isInventoryRoleFound = useAppSelector(
    selectWorkstationHasInventoryRole
  );

  const hasWorkstation = !!useAppSelector(selectThisWorkstation);

  const onInventoryHoldsClicked = () => {
    if (isInventoryRoleFound || !hasWorkstation) {
      navigate({
        pathname: "/autostore-inventory/holds",
        search: stringify({
          ...parse(search, { ignoreQueryPrefix: true }),
          autostore: true
        })
      });
      mixpanelTrack({
        type: "Button Click",
        label: "Autostore Inventory Holds Button",
        trackedPageName
      });
      dispatch(setSelectedSummaries([]));
    }
  };

  return (
    <MainButton
      alert={
        (!isInventoryRoleFound && t("no inventory role found")) || undefined
      }
      alertKind="workstation"
      disabled={!isInventoryRoleFound && hasWorkstation}
      onClick={onInventoryHoldsClicked}
      variant="md"
    >
      {t("nav.link.inventory holds")}
    </MainButton>
  );
}
