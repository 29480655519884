import { Components, Theme, outlinedInputClasses } from "@mui/material";

export const muiFormControl: Components<Omit<Theme, "components">> = {
  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& > .${outlinedInputClasses.root}`]: {
          marginTop: theme.spacing(0.5)
        }
      })
    },
    defaultProps: {
      // the "standard" form control places the label above the input, and the FormHelperText
      // will render without a 14px left & right margin
      variant: "standard"
    }
  }
};
