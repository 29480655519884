import { Tabs } from "@mui/base/Tabs";
import { Box, styled, Typography, Stack, Icon, useTheme } from "@mui/material";
import { ContainedTab, ContainedTabsList } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import EventFeed, { getEventMessageStyling } from "./EventFeed";
import ScannerTest from "./ScannerTest";
import BinToPortTest from "./binToPortTest/BinToPortTest";
import {
  selectActiveTest,
  selectCompletedTests,
  setActiveTest,
  TestCategoryType,
  TestsTypes
} from "./workstationTest.slice";

const WorkstationTestContainer = styled(Box)(({ theme: { spacing } }) => ({
  height: "calc(100vh - 160px)",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
  gridAutoFlow: "column",
  gridTemplateColumns: "1fr minmax(350px, 0.3fr)",
  gridTemplateRows: "minmax(600px, 1fr) 100px",
  gap: spacing(3),
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr",
    gap: spacing(2),
    gridAutoFlow: "row",
    gridTemplateRows: "repeat(3, auto)"
  }
}));

const WorkstationTest = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const activeTest = useAppSelector(selectActiveTest);
  const activeTestIndex = TestsTypes.indexOf(activeTest);
  const testsCompleted = useAppSelector(selectCompletedTests);

  return (
    <WorkstationTestContainer>
      {activeTest === "Scanner" && <ScannerTest />}
      {activeTest === "Bin to Port" && <BinToPortTest />}
      <Stack
        sx={{
          flexDirection: "row",
          gap: 3,
          alignItems: "center"
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600
          }}
        >
          {`${t("available tests")}:`}
        </Typography>
        <Tabs
          defaultValue={"Scanner"}
          onChange={(_e, testName) => {
            if (activeTestIndex >= 0)
              dispatch(setActiveTest(testName as TestCategoryType));
          }}
        >
          <ContainedTabsList>
            {TestsTypes.map((currentTestName) => {
              const currentTestsType = testsCompleted[currentTestName];
              const successState = currentTestsType?.state === "Success";
              const failedState = currentTestsType?.state === "Failed";
              const { icon } = getEventMessageStyling(
                currentTestsType?.state || "Info",
                palette
              );
              return (
                <ContainedTab
                  value={currentTestName}
                  key={`${currentTestName}-category-test`}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Icon
                      style={{
                        margin: "0 20% 0 5px",
                        height: 26,
                        width: 26
                      }}
                    >
                      {successState || failedState ? icon : <></>}
                    </Icon>
                    <Typography
                      sx={{
                        marginRight: "auto"
                      }}
                    >
                      {currentTestName}
                    </Typography>
                  </Stack>
                </ContainedTab>
              );
            })}
          </ContainedTabsList>
        </Tabs>
      </Stack>
      <EventFeed />
    </WorkstationTestContainer>
  );
};

export default WorkstationTest;
