import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InventoryMovementResponse,
  StartBinMaintenanceResponse
} from "~/types/api";

type MovementTaskState = "MovingProduct" | "ConfirmingMovement";

type BinMaintenanceWorkstationState = {
  movementTaskState: MovementTaskState;
  isBinReconfigurationModalOpen: boolean;
  movementData: StartBinMaintenanceResponse | undefined;
};

const initialState: BinMaintenanceWorkstationState = {
  movementTaskState: "MovingProduct",
  isBinReconfigurationModalOpen: false,
  movementData: undefined
};

export const binMaintenanceWorkstationSlice = createSlice({
  name: "binMaintenanceWorkstation",
  initialState,
  reducers: {
    setTaskState(state, { payload }: PayloadAction<MovementTaskState>) {
      state.movementTaskState = payload;
    },
    toggleBinReconfigurationModal(state) {
      state.isBinReconfigurationModalOpen =
        !state.isBinReconfigurationModalOpen;
    },
    setMovementData(
      state,
      { payload }: PayloadAction<StartBinMaintenanceResponse>
    ) {
      state.movementData = payload;
    },
    setSourceDestinationBins(
      state,
      { payload }: PayloadAction<InventoryMovementResponse>
    ) {
      state.movementData = {
        binCleaningPorts: state.movementData?.binCleaningPorts ?? [],
        inventoryMovements: payload
      };
    }
  }
});
export const {
  setTaskState,
  toggleBinReconfigurationModal,
  setMovementData,
  setSourceDestinationBins
} = binMaintenanceWorkstationSlice.actions;
