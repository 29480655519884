import WorkstationIcon from "@locaisolutions/icons/dist/icons24px/Workstation24Px";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import { AlertBanner } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { addEvent } from "~/features/workstationTest/workstationTest.slice";
import { useGridV2Subscription } from "~/lib/signalr";
import {
  selectThisWorkstation,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import WorkstationPort from "./WorkstationPort";

const BinToPortTest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [binToPortTestLoopingEnabled, enableBinToPortTestLooping] =
    useState<boolean>(false);
  const workstation = useAppSelector(selectThisWorkstation);
  const workstationAvailablePorts = useAppSelector(
    selectWorkstationAvailablePorts
  );
  const columns = workstation?.multiPortEnabled
    ? workstationAvailablePorts.length + 1
    : workstationAvailablePorts.length;

  const ports = useAppSelector(selectWorkstationAvailablePorts);

  useGridV2Subscription((event) => {
    if (
      event.case === "PortError" &&
      event.event.hasError &&
      event.event.portId &&
      ports.includes(event.event.portId)
    ) {
      dispatch(
        addEvent({
          text: `Log Publisher: Port error for port ${event.event.portId}`,
          state: "Failed",
          testOrigin: "Bin to Port"
        })
      );
    }
    if (
      event.case === "BinModeChange" &&
      event.event.binMode === "O" &&
      event.event.portId &&
      ports.includes(event.event.portId)
    ) {
      dispatch(
        addEvent({
          text: `Log Publisher: Bin ${event.event.binId} arrived at port ${event.event.portId}`,
          state: "Info",
          testOrigin: "Bin to Port"
        })
      );
    }
  });
  const warning = !workstation ? t("no workstation selected") : null;
  return (
    <>
      {warning ? (
        <Stack
          sx={{
            flexDirection: "column",
            gap: 1,
            alignItems: "center"
          }}
        >
          <AlertBanner sx={{ minWidth: 350, borderRadius: 0 }}>
            {warning}
          </AlertBanner>
          <Button
            color="info"
            sx={{
              minWidth: 250,
              minHeight: 70,
              fontSize: "1.5rem",
              p: 2,
              mt: 5
            }}
            onClick={() =>
              navigate("/settings?settings-tab=workstation-config")
            }
            startIcon={<WorkstationIcon fill="white" />}
          >
            {t("assign workstation")}
          </Button>
          <Typography variant="h6" fontStyle={"italic"} fontWeight={400}>
            {t("workstation warning info")}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            gap: 4,
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              display: "grid",
              placeContent: "center",
              gridAutoFlow: "column",
              gridTemplateColumns: `repeat(${columns}, minmax(250px, 350px))`,
              gap: 4,
              "@media (max-width: 900px)": {
                gridTemplateColumns: "1fr",
                gap: 2,
                gridAutoFlow: "row"
              }
            }}
            aria-label="Bin to port test content"
          >
            {ports.map((portId) => (
              <WorkstationPort
                key={portId}
                portId={portId}
                binToPortTestLoopingEnabled={binToPortTestLoopingEnabled}
              />
            ))}
          </Box>
          <Stack
            sx={{
              gap: 1,
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <Typography
              sx={{
                fontSize: 20
              }}
            >
              {t("enable bin to port looping")}
            </Typography>
            <Switch
              checked={binToPortTestLoopingEnabled}
              onChange={(e) =>
                enableBinToPortTestLooping(e.target.checked || false)
              }
              disableRipple
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default BinToPortTest;
