import {
  ConfirmInventoryMovementRequest,
  InventoryMovementResponse,
  StartBinMaintenanceResponse
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

type PostConfirmInventoryMovementParams = {
  autostoreGridId: string;
  workstationId: string;
  sourceBinId: number;
  destinationBinId: number;
};

export const autostoreBinMaintenanceApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    startBinMaintenance: build.mutation<
      StartBinMaintenanceResponse,
      { autostoreGridId: string; workstationId: string }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-bin-maintenance/grid/${autostoreGridId}/workstation/${workstationId}/start-bin-maintenance`,
        method: "POST"
      })
    }),
    confirmInventoryMovement: build.mutation<
      InventoryMovementResponse,
      PostConfirmInventoryMovementParams
    >({
      query: ({
        autostoreGridId,
        workstationId,
        sourceBinId,
        destinationBinId
      }) => {
        const request: ConfirmInventoryMovementRequest = {
          sourceBinId,
          destinationBinId
        };

        return {
          url: `/autostore-bin-maintenance/grid/${autostoreGridId}/workstation/${workstationId}/confirm-inventory-movement`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: ["inventory movements"]
    })
  }),
  overrideExisting: false
});
