export type Auth0Profile = {
  sub: string;
  // eslint-disable-next-line camelcase
  client_access?: {
    [clientId: string]: {
      clientName: string;
    };
  };
  name: string;
  userId: Guid;
  roles: string[];
  "/client_ids": Guid[];
  // eslint-disable-next-line camelcase
  login_path: string;
  // eslint-disable-next-line camelcase
  custom_login_path?: string;
};

// Permissions and roles should match backend roles/permissions
// However, it's not concrete yet
type Permission =
  | "bin-maintenance"
  | "order-batches"
  | "picking"
  | "goods-in"
  | "configure-workstation"
  | "cycle-count";
type UserRole =
  | "admin"
  | "picker"
  | "receiver"
  | "inventory-manager"
  | "workstation-configurer";

// Move to config eventually
const roleToPermissionMap: Record<UserRole, Permission[]> = {
  admin: [
    "picking",
    "goods-in",
    "configure-workstation",
    "order-batches",
    "bin-maintenance",
    "cycle-count"
  ],
  picker: ["picking", "configure-workstation", "cycle-count"],
  receiver: ["goods-in", "configure-workstation", "cycle-count"],
  "inventory-manager": ["order-batches", "bin-maintenance", "cycle-count"],
  "workstation-configurer": ["configure-workstation"]
};

export const mapTokenRoleToDomainRole = (
  tokenRole: string
): UserRole | null => {
  switch (tokenRole) {
    case "admin":
    case "client-administrator":
      return "admin";
    case "powerpick-picker":
    case "picker":
      return "picker";
    case "powerpick-receiver":
      return "receiver";
    case "powerpick-inventory-manager":
      return "inventory-manager";
    case "qubit-workstation-configurer":
      return "workstation-configurer";
    default:
      return null;
  }
};

export const roleToPermissions = (role: UserRole): Permission[] => {
  return roleToPermissionMap[role] || [];
};

export const hasPermissions = (
  profile: Auth0Profile,
  permissions: Permission[]
): boolean => {
  const userRoles = profile.roles
    .map(mapTokenRoleToDomainRole)
    .filter((r) => r != null);
  const userPermissions = userRoles.flatMap(roleToPermissions);
  return permissions.every((permission) =>
    userPermissions.includes(permission)
  );
};

export function isClientAdmin(profile: Auth0Profile): boolean {
  return profile.roles.some((role) => role === "client-administrator");
}

export const canPick = (
  profile: Auth0Profile,
  usePermissionFeatureFlag: boolean
): boolean => {
  if (usePermissionFeatureFlag) {
    return hasPermissions(profile, ["picking"]);
  } else {
    return profile.roles.some(
      (role) =>
        role === "powerpick-picker" ||
        role === "admin" ||
        role === "client-administrator"
    );
  }
};

export const canDecant = (
  profile: Auth0Profile,
  usePermission: boolean
): boolean => {
  if (usePermission) {
    return hasPermissions(profile, ["goods-in"]);
  } else {
    return profile.roles.some(
      (role) =>
        role === "powerpick-receiver" ||
        role === "admin" ||
        role === "client-administrator"
    );
  }
};

export const canConfigureWorkstation = (
  profile: Auth0Profile,
  usePermission: boolean
): boolean => {
  if (usePermission) {
    return hasPermissions(profile, ["configure-workstation"]);
  } else {
    return profile.roles.some(
      (role) =>
        role === "admin" ||
        role === "client-administrator" ||
        role === "qubit-workstation-configurer"
    );
  }
};

export const canCycleCount = (
  profile: Auth0Profile,
  usePermission: boolean
): boolean => {
  if (usePermission) {
    return hasPermissions(profile, ["cycle-count"]);
  } else {
    return profile.roles.some(
      (role) =>
        role === "powerpick-inventory-manager" ||
        role === "admin" ||
        role === "client-administrator"
    );
  }
};

export function getUserClientId(profile: Auth0Profile | null): string | null {
  if (
    profile &&
    profile["/client_ids"] &&
    Array.isArray(profile["/client_ids"]) &&
    profile["/client_ids"].length
  ) {
    return profile["/client_ids"][0];
  }

  return null;
}
