import { useFlags, camelCaseKeys } from "launchdarkly-react-client-sdk";

type ExpectedFlagMap = {
  lowInventoryPage: boolean;
  cycleCountOverhaul: boolean;
  flagBinAndInventoryAdjustButtons: boolean;
  qubitSignalrEnableLongpolling: boolean;
  useStartPickingOnWorkstationEndpoint: boolean;
  serverSidePickingBin: boolean;
  simulateUpcScan: boolean;
  // pickingStatePolling: boolean;
  skipMovementButtonEnabled: boolean;
  binNotEmptyInventoryMovementButtonEnabled: boolean;
  unflagBinButton: boolean;
  qubitSignalrEnableServerSentEvents: boolean;
  releaseInprogressByPage: string[];
  enableApiKeysView: boolean;
  feUsePermissions: boolean;
  enableUnifyConnectToasts: boolean;
  enableTestingTools: boolean;
};

export const useFlag: () => ExpectedFlagMap = () => {
  const flags = camelCaseKeys(useFlags());
  return {
    lowInventoryPage: (flags.lowInventoryPage as boolean) ?? false,
    cycleCountOverhaul: (flags.cycleCountOverhaul as boolean) ?? false,
    flagBinAndInventoryAdjustButtons:
      (flags.flagBinAndInventoryAdjustButtons as boolean) ?? false,
    qubitSignalrEnableLongpolling:
      (flags.qubitSignalrEnableLongpolling as boolean) ?? false,
    useStartPickingOnWorkstationEndpoint:
      (flags.useStartPickingOnWorkstationEndpoint as boolean) ?? false,
    serverSidePickingBin: (flags.serverSidePickingBin as boolean) ?? false,
    simulateUpcScan: (flags.simulateUpcScan as boolean) ?? true,
    // pickingStatePolling: (flags.pickingStatePolling as boolean) ?? false,
    skipMovementButtonEnabled:
      (flags.skipMovementButtonEnabled as boolean) ?? false,
    binNotEmptyInventoryMovementButtonEnabled:
      (flags.binNotEmptyInventoryMovementButtonEnabled as boolean) ?? false,
    unflagBinButton: (flags.unflagBinButton as boolean) ?? false,
    qubitSignalrEnableServerSentEvents:
      (flags.qubitSignalrEnableServerSentEvents as boolean) ?? false,
    releaseInprogressByPage: (flags.releaseInprogressByPage as string[]) ?? [],
    bevClientLogoutFlowDecoupling:
      (flags.bevClientLogoutFlowDecoupling as boolean) ?? false,
    enableApiKeysView: (flags.enableApiKeysView as boolean) ?? false,
    feUsePermissions: (flags.feUsePermissions as boolean) ?? false,
    enableUnifyConnectToasts: (flags.unifyConnectToasts as boolean) ?? false,
    enableTestingTools: (flags.enableTestingTools as boolean) ?? false
  };
};

export default useFlag;
