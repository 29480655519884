import { CardHeader, Grid, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ProductCard } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { BlindCount } from "~/features/cycleCounts2023/BlindCount";
import { InventoryWithVariantsResponse } from "~/types/api";

type Props = {
  compartmentData?: InventoryWithVariantsResponse;
};

const CompartmentDataTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main
}));

function CompartmentDataCard(props: Props) {
  const { compartmentData } = props;
  const { t } = useTranslation();
  const { inventory, variant } = compartmentData || {};
  const { count } = inventory || {};
  const { productName, sku, upc } = variant || {};
  const selectedTasks = useAppSelector(
    (state) => state.cycleCounts.selectedCountingTasks
  );
  const matchingTask = selectedTasks?.find((task) =>
    task.countingTasks.includes(inventory?.inventoryId ?? "")
  );

  return (
    <ProductCard.ProductCardContainer sx={{ p: 2 }}>
      <>
        <CardHeader
          title={productName || <Skeleton animation={false} width="80%" />}
          sx={{
            padding: 0,
            marginBottom: "12px",
            fontSize: "20px"
          }}
        />
        <Grid container gap="40px">
          <Grid item xs={5}>
            <CompartmentDataTitle variant="overline">
              {t("upc")}
            </CompartmentDataTitle>
            <Typography
              variant="h6"
              component="h6"
              sx={{ marginBottom: "8px" }}
            >
              {upc || <Skeleton animation={false} />}
            </Typography>

            <CompartmentDataTitle variant="overline">
              {t("sku")}
            </CompartmentDataTitle>
            <Typography variant="h6" component="h6">
              {sku || <Skeleton animation={false} />}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CompartmentDataTitle variant="overline">
              {t("quantity")}
            </CompartmentDataTitle>
            <Typography
              variant="h6"
              component="h6"
              sx={{ marginBottom: "8px" }}
            >
              {count?.value && count.units ? (
                <BlindCount task={matchingTask} count={count} />
              ) : (
                <Skeleton animation={false} />
              )}
            </Typography>
          </Grid>
        </Grid>
      </>
    </ProductCard.ProductCardContainer>
  );
}

export default CompartmentDataCard;
