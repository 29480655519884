import { Stack } from "@mui/material";
import { useState } from "react";

import Messenger, { Recipient } from "~/features/andon/Messenger";

import AdminSummariesTable from "./AdminSummariesTable";
import WorkstationSelectionContainer from "./WorkstationSelectionContainer";

export default function AdminSummariesView() {
  const [messengerIsOpen, setMessengerIsOpen] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<Recipient[]>([]);

  const openMessageComponent = (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => {
    event.stopPropagation();

    setRecipients(newRecipients);
    setMessengerIsOpen(true);
  };

  return (
    <Stack
      id="workstation-summaries-container"
      sx={{
        flexDirection: "column",
        gap: 2
      }}
    >
      <WorkstationSelectionContainer />
      <AdminSummariesTable openMessageComponent={openMessageComponent} />
      {messengerIsOpen && !!recipients.length && (
        <Messenger
          recipients={recipients}
          onCloseCb={() => {
            setMessengerIsOpen(false);
            setRecipients([]);
          }}
        />
      )}
    </Stack>
  );
}
