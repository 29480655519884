import { Box, Chip } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

const FulfillmentCenterChip = (props: BoxProps) => {
  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const { t } = useTranslation();
  const isFCSelected = !!selectedFulfillmentCenter;
  const fcName = isFCSelected
    ? selectedFulfillmentCenter.name
    : t("no fc selected");

  return (
    <Box {...props}>
      <Chip disabled={!isFCSelected} label={fcName} color="6" />
    </Box>
  );
};

export default FulfillmentCenterChip;
