import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WorkstationSummaryDto } from "~/types/api";

export const unifyErrorTypes = ["Port Errors", "Robot Errors"] as const;
export type UnifyErrorType = (typeof unifyErrorTypes)[number];

export type WorkstationStatusType = WorkstationSummaryDto["status"];
export const workstationStatusTypes: WorkstationStatusType[] = [
  "Active",
  "Inactive",
  "Faulted"
] as const;

type ServiceSupportState = {
  selectedGridId: Guid;
  selectedAdminSummariesGridId: Guid;
  selectedWorkstationId: Guid;
  selectedErrorType: UnifyErrorType;
  selectedWorkstationStatus: WorkstationStatusType | "";
};

const initialState: ServiceSupportState = {
  selectedGridId: "",
  selectedAdminSummariesGridId: "",
  selectedWorkstationId: "",
  selectedErrorType: "Port Errors",
  selectedWorkstationStatus: ""
};

export const serviceSupportSlice = createSlice({
  name: "serviceSupport",
  initialState,
  reducers: {
    setSelectedGridId(state, { payload }: PayloadAction<Guid>) {
      state.selectedGridId = payload;
    },
    setSelectedAdminSummariesGridId(state, { payload }: PayloadAction<Guid>) {
      state.selectedAdminSummariesGridId = payload;
    },
    setSelectedWorkstationId(state, { payload }: PayloadAction<Guid>) {
      state.selectedWorkstationId = payload;
    },
    setSelectedErrorType(state, { payload }: PayloadAction<UnifyErrorType>) {
      state.selectedErrorType = payload;
    },
    setSelectedWorkstationStatus(
      state: ServiceSupportState,
      action: PayloadAction<WorkstationStatusType | "">
    ) {
      state.selectedWorkstationStatus = action.payload;
    }
  },
  selectors: {
    selectAdminSummariesGrid: (state: ServiceSupportState) =>
      state.selectedAdminSummariesGridId,
    selectWorkstationStatus: (state: ServiceSupportState) =>
      state.selectedWorkstationStatus
  }
});
export const {
  setSelectedGridId,
  setSelectedWorkstationId,
  setSelectedErrorType,
  setSelectedWorkstationStatus,
  setSelectedAdminSummariesGridId
} = serviceSupportSlice.actions;

export const { selectAdminSummariesGrid, selectWorkstationStatus } =
  serviceSupportSlice.selectors;
