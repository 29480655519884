import { Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowEmpty
} from "@qubit/autoparts";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { Recipient } from "~/features/andon/Messenger";
import {
  AndonWorkstation,
  selectAndonWorkstations
} from "~/features/andon/andon.slice";
import {
  selectAdminSummariesGrid,
  selectWorkstationStatus
} from "~/features/serviceSupport/serviceSupport.slice";
import { useLazyGetUnifyPortErrorsQuery } from "~/redux/warehouse/unify.openApi";

import AdminSummariesContentRow from "./AdminSummariesContentRow";

interface AdminSummariesTableProps {
  openMessageComponent: (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => void;
}
const AdminSummariesTable = (props: AdminSummariesTableProps) => {
  const { t } = useTranslation();
  const pageRowLimit = 10;
  const selectedGridId = useAppSelector(selectAdminSummariesGrid);
  const selectedWorkstationStatus = useAppSelector(selectWorkstationStatus);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  const [currentPage, setPage] = useState<number>(1);
  const [portErrorsData, setPortErrorsData] = useState<{
    [key: string]: number;
  } | null>(null);

  const [triggerGetUnifyPortsErrors, { isFetching }] =
    useLazyGetUnifyPortErrorsQuery();

  const workstations = useMemo(() => {
    if (selectedWorkstationStatus.length === 0 || selectedGridId.length === 0)
      return [];
    return Object.values(andonWorkstations).filter(
      (ws) =>
        ws.workstation.autostoreGridId === selectedGridId &&
        selectedWorkstationStatus === ws.workstation.status
    );
  }, [andonWorkstations, selectedGridId, selectedWorkstationStatus]);

  const sortedWorkstations = useMemo(() => {
    if (!portErrorsData) return workstations;
    return [...workstations].sort(
      (a, b) =>
        (portErrorsData[b.workstation.id] || 0) -
        (portErrorsData[a.workstation.id] || 0)
    );
  }, [workstations, portErrorsData]);

  const workstationsToDisplay = useMemo(() => {
    return sortedWorkstations.slice(
      (currentPage - 1) * pageRowLimit,
      pageRowLimit * currentPage
    );
  }, [currentPage, sortedWorkstations, pageRowLimit]);

  const totalPageCount = workstations.length
    ? Math.ceil(workstations.length / pageRowLimit)
    : 0;

  const getUnifyPortsErrors = useCallback(
    async (workstationId: string, autostoreGridId: string): Promise<number> => {
      let data = [];
      try {
        data = await triggerGetUnifyPortsErrors({
          workstationId,
          autostoreGridId,
          limit: null
        }).unwrap();
      } catch {
        return 0;
      }
      return data.length;
    },
    [triggerGetUnifyPortsErrors]
  );
  const fetchPortErrors = useCallback(async () => {
    if (workstations.length) {
      try {
        const portErrorsResponse: { [key: string]: number }[] =
          await Promise.all(
            workstations.map(async (aws) => {
              const portErrors = await getUnifyPortsErrors(
                aws.id,
                aws.workstation.autostoreGridId
              );
              return { [aws.id]: portErrors };
            })
          );

        const portErrorsMap = Object.assign({}, ...portErrorsResponse) as {
          [key: string]: number;
        };

        setPortErrorsData(portErrorsMap);
      } catch {
        /* empty */
      }
    }
  }, [workstations, getUnifyPortsErrors]);

  useEffect(() => {
    if (workstations.length) {
      void fetchPortErrors();
    }
  }, [workstations, fetchPortErrors]);

  return (
    <ASTableV2
      gridTemplateColumns="repeat(8, auto)"
      gridTemplateRows="auto"
      isLoading={false}
      pagination={{
        pageCount: totalPageCount,
        page: currentPage,
        setPage: (pageNumber) => {
          setPage(pageNumber);
        }
      }}
    >
      <ASTableV2Header>
        <ASTableV2Row aria-label="active workstations header">
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("device id")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("user")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("parent port id")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("ports")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("mode")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("roles")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell />
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("port errors")}</Typography>
          </ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {!workstationsToDisplay?.length && (
          <ASTableV2RowEmpty>{t("no workstations selected")}</ASTableV2RowEmpty>
        )}
        {workstationsToDisplay.map((activeWorkstation: AndonWorkstation) => {
          return (
            <AdminSummariesContentRow
              {...props}
              activeWorkstation={activeWorkstation}
              portErrorsCount={portErrorsData?.[activeWorkstation.id]}
              isFetchingPortErrors={isFetching}
              key={activeWorkstation.id}
            />
          );
        })}
      </ASTableV2Body>
    </ASTableV2>
  );
};

export default AdminSummariesTable;
