import { Button, ButtonGroup, Toolbar, Typography } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { UnselectButton } from "~/features/navbar/Navbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  useLoadLoosePicksMutation,
  useVerifyLoosePicksMutation
} from "~/redux/warehouse/loosePicks.openApi";
import {
  useLoadTotesMutation,
  useVerifyTotesMutation
} from "~/redux/warehouse/totes.hooks";
import { ConsolidationLoosePickDto, ConsolidationToteDto } from "~/types/api";

import { areAllTotesInStatus } from "./areAllTotesInStatus";
import { selectConsolidationSummary } from "./ship.slice";

const atLeastOneToteInStatus = (
  totesOrPicks: (ConsolidationLoosePickDto | ConsolidationToteDto)[],
  statuses: string[]
) => totesOrPicks.some((toteOrPick) => statuses.includes(toteOrPick.status));

export function ShipToolbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const [loadTotes] = useLoadTotesMutation();
  const [loadLoosePicks] = useLoadLoosePicksMutation();
  const [verifyLoosePicks] = useVerifyLoosePicksMutation();
  const [verifyTotes] = useVerifyTotesMutation();

  const selectedOrder = useAppSelector(
    (state) => state.ship.selectedConsolidationSummary
  );

  const selectedOrderTotesAndPicks = selectedOrder && [
    ...(selectedOrder.totes || []),
    ...(selectedOrder.loosePicks || [])
  ];

  // are the totes/picks all at least staged (but not all verified)
  const isSelectedOrderStaged =
    !!selectedOrderTotesAndPicks?.length &&
    atLeastOneToteInStatus(selectedOrderTotesAndPicks, ["Staged"]) &&
    areAllTotesInStatus(selectedOrderTotesAndPicks, [
      "Staged",
      "Verified",
      "Canceled",
      "Loaded"
    ]);

  // are the totes/picks all verified
  const isSelectedOrderVerified =
    !!selectedOrderTotesAndPicks?.length &&
    areAllTotesInStatus(selectedOrderTotesAndPicks, ["Verified", "Canceled"]) &&
    !areAllTotesInStatus(selectedOrderTotesAndPicks, ["Canceled"]);

  const verify = useCallback(async () => {
    try {
      const toteIds =
        selectedOrder?.totes
          ?.filter((tote) => tote.status === "Staged")
          .map((tote) => tote.toteId) || [];
      const loosePickIds =
        selectedOrder?.loosePicks
          ?.filter((pick) => pick.status === "Staged")
          .map((pick) => pick.loosePickId) || [];

      if (toteIds.length > 0) {
        await verifyTotes({
          toteIds
        }).unwrap();
      }
      if (loosePickIds.length > 0) {
        await verifyLoosePicks({
          body: loosePickIds
        }).unwrap();
      }

      successToast("Verified", {
        description: `${toteIds.length} ${
          toteIds.length === 1 ? "tote" : "totes"
        } and ${loosePickIds.length} loose ${
          loosePickIds.length === 1 ? "pick" : "picks"
        }`
      });
      dispatch(selectConsolidationSummary(null));
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  }, [
    dispatch,
    errorToast,
    selectedOrder,
    successToast,
    verifyLoosePicks,
    verifyTotes
  ]);

  const load = useCallback(async () => {
    try {
      const toteIds = selectedOrder?.totes?.map((tote) => tote.toteId) ?? [];
      const loosePickIds =
        selectedOrder?.loosePicks?.map((pick) => pick.loosePickId) ?? [];

      if (toteIds.length > 0) {
        await loadTotes({
          toteIds
        }).unwrap();
      }

      if (loosePickIds.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        loadLoosePicks({
          body: loosePickIds
        }).unwrap();
      }

      successToast("Loaded", {
        description: `${toteIds.length} ${
          toteIds.length === 1 ? "tote" : "totes"
        } and ${loosePickIds.length} loose ${
          loosePickIds.length === 1 ? "pick" : "picks"
        }`
      });
      dispatch(selectConsolidationSummary(null));
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  }, [
    dispatch,
    errorToast,
    loadLoosePicks,
    loadTotes,
    selectedOrder,
    successToast
  ]);

  return (
    <Toolbar>
      <UnselectButton
        onClick={() => dispatch(selectConsolidationSummary(null))}
      />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{ justifyContent: "center", width: "100%" }}
      >
        <Button
          color="secondary"
          onClick={() => {
            if (selectedOrder) {
              navigate(`/ship/order/${selectedOrder.orderId || ""}`);
            }
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("view order")}
          </Typography>
        </Button>
        {selectedOrder && isSelectedOrderStaged && (
          <Button color="secondary" onClick={verify}>
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("verify")}
            </Typography>
          </Button>
        )}
        {selectedOrder && isSelectedOrderVerified && (
          <Button color="secondary" onClick={load}>
            <Typography variant="body2" style={{ color: "#fff" }}>
              Load
            </Typography>
          </Button>
        )}
      </ButtonGroup>
    </Toolbar>
  );
}
