import { Box, Paper, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

type SettingsInheritedProps = {
  panelTitle: string;
  children: ReactNode;
  enableFullHeight?: boolean;
  sx?: SxProps<Theme>;
};
type SettingsProps = SettingsInheritedProps;

function SettingsPanel(props: SettingsProps) {
  const { panelTitle, children, enableFullHeight, sx = [] } = props;
  const normalizedSx: SxProps<Theme> = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      className="settings-panel"
      sx={[
        {
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: enableFullHeight ? "100%" : "auto",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2
        },
        ...normalizedSx
      ]}
    >
      <Paper
        sx={{
          width: "100%",
          height: enableFullHeight ? "100%" : "auto",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          padding: 3
        }}
      >
        <Box sx={{ marginBottom: "10px" }}>
          <Typography variant="h6">{panelTitle}</Typography>
        </Box>
        {children}
      </Paper>
    </Box>
  );
}

export default SettingsPanel;
