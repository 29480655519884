import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "~/app/store";
import { determinePortId } from "~/lib/helpers";
import { selectAuth0Profile } from "~/redux/selectors/authSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  usePostActivateWorkstationMutation,
  usePostDeactivateWorkstationMutation
} from "~/redux/warehouse/workstation.hooks";
import { WorkstationSummaryDto } from "~/types/api";

export const useSetThisWorkstation = () => {
  const dispatch = useDispatch();
  const [postActivateWorkstation, { isLoading: isActivateLoading }] =
    usePostActivateWorkstationMutation();
  const [postDeactivateWorkstation, { isLoading: isDeactivateLoading }] =
    usePostDeactivateWorkstationMutation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const profile = useAppSelector(selectAuth0Profile);

  const activateWorkstation = useCallback(
    async (id: string) => postActivateWorkstation(id).unwrap(),
    [postActivateWorkstation]
  );
  const deactivateWorkstation = useCallback(
    async (id: string) => postDeactivateWorkstation(id).unwrap(),
    [postDeactivateWorkstation]
  );

  const setThisWorkstation = useCallback(
    async (
      workstation: WorkstationSummaryDto | null,
      workstationChange?: boolean
    ) => {
      if (workstationChange && profile) {
        if (siteWorkstation) {
          await deactivateWorkstation(siteWorkstation.id);
        }
        if (workstation) {
          await activateWorkstation(workstation.id);
        }

        dispatch({
          type: "workstations/SET_THIS_WORKSTATION",
          payload: workstation
        });

        if (workstation) {
          const portId = determinePortId(workstation);
          dispatch({ type: "workstations/SET_SITE_PORT_ID", payload: portId });
        }
      }
    },
    [
      activateWorkstation,
      deactivateWorkstation,
      dispatch,
      profile,
      siteWorkstation
    ]
  );

  return {
    setThisWorkstation,
    isLoading: isActivateLoading || isDeactivateLoading
  };
};
