import BackHandIcon from "@mui/icons-material/BackHand";
import {
  SelectChangeEvent,
  Button,
  Box,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Stack
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { andonColors } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { figureAndonColorFromStatus, groupArrayByKey } from "~/lib/helpers";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { selectAndonGrids, selectAndonWorkstations } from "./andon.slice";

type WorkstationSelector = {
  selectedWorkstationIds: string[];
  setNewIdsCb: (newIds: string[]) => void;
};

const portColumnWidth = "40px";
const portBoxHeight = "25px";

const ColumnButton = styled("button")(() => ({
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  border: "none",
  width: "100%",
  backgroundColor: "gray.light",
  padding: 0
}));

export function WorkstationSelector(props: WorkstationSelector) {
  const { selectedWorkstationIds } = props;

  const { data: fetchedWorkstations = [] } = useGetWorkstationsQuery();

  const andonGrids = useAppSelector(selectAndonGrids);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);
  const { t } = useTranslation();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 200
      }
    }
  };

  const groupedWorkstations = groupArrayByKey(
    Object.values(andonWorkstations),
    (aws) => aws.workstation.autostoreGridId
  );

  return (
    <Stack spacing={2} my={2}>
      {/* buttons row container */}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="subtle"
          onClick={() =>
            props.setNewIdsCb(
              Object.values(andonWorkstations).map((aws) => aws.id)
            )
          }
        >
          {t("all")}
        </Button>
        <Button
          variant="subtle"
          onClick={() =>
            props.setNewIdsCb(
              Object.values(andonWorkstations)
                .filter((aws) => aws.workstation.status === "Active")
                .map((aws) => aws.id)
            )
          }
        >
          {t("active")}
        </Button>
        <Button variant="subtle" onClick={() => props.setNewIdsCb([])}>
          {t("none")}
        </Button>
      </Stack>

      {/* grid boxes */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          minHeight: "150px",
          overflowX: "scroll"
        }}
      >
        {/* each grid has a group of workstations */}
        {Object.entries(groupedWorkstations).map(([gridId, workstations]) => {
          return (
            <Box
              key={gridId}
              sx={{ display: "flex", flexDirection: "column", margin: "10px" }}
            >
              {/* top row is the grid name and ability to select */}
              <Box
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid black"
                }}
              >
                <Button
                  onClick={() => {
                    props.setNewIdsCb(workstations.map((ws) => ws.id));
                  }}
                >
                  <Typography sx={{ color: "black" }}>
                    {andonGrids[gridId]?.gridName || ""}
                  </Typography>
                </Button>
              </Box>

              {/* second row containes all workstation columns */}
              <Box sx={{ display: "flex" }}>
                {workstations
                  .sort(
                    (a, b) =>
                      a.workstation.autostoreGridName.localeCompare(
                        b.workstation.autostoreGridName
                      ) ||
                      a.workstation.deviceId.localeCompare(
                        b.workstation.deviceId
                      )
                  )
                  .map((aws, i) => {
                    const { ports } = aws;
                    const ws = aws.workstation;

                    // revisit after parentPortId is on workstationSummaryDTO
                    const parentPortId = aws.workstation.ports[0]?.parentPortId;

                    return (
                      // a column of port info
                      <Box
                        key={`${aws.id}-${i}`}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: portColumnWidth,
                          boxSizing: "border-box"
                        }}
                      >
                        {/* each column is wrapped by a tooltip */}
                        <Tooltip
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "200px",
                                alignItems: "center"
                              }}
                            >
                              <Typography noWrap sx={{ fontSize: 12 }}>
                                {ws.deviceId}
                              </Typography>
                            </Box>
                          }
                          arrow
                          enterDelay={500}
                          slotProps={{
                            tooltip: {
                              sx: { backgroundColor: "primary.main" }
                            }
                          }}
                          placement="top"
                        >
                          {/* each column is a clickable button  */}
                          <ColumnButton
                            aria-label={`workstation-${ws.deviceId}`}
                            onClick={() => {
                              if (selectedWorkstationIds.includes(ws.id)) {
                                props.setNewIdsCb(
                                  selectedWorkstationIds.filter(
                                    (did) => did !== ws.id
                                  )
                                );
                              } else {
                                props.setNewIdsCb([
                                  ...selectedWorkstationIds,
                                  ws.id
                                ]);
                              }
                            }}
                          >
                            <Box
                              sx={{
                                minHeight: "180px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: `1px solid`,
                                borderColor: "gray.main",
                                width: "100%",
                                backgroundColor: "gray.light"
                              }}
                            >
                              {/* top rows - parent port ids, ports */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%"
                                }}
                              >
                                {/* parent port id */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: portBoxHeight,
                                    backgroundColor: figureAndonColorFromStatus(
                                      {
                                        gridSystemMode:
                                          andonGrids[
                                            aws.workstation.autostoreGridId
                                          ].systemMode || undefined,
                                        workstationStatus: aws.status,
                                        workstationOpen: aws.isOpen
                                      }
                                    ),
                                    boxSizing: "border-box",
                                    border: "1px solid black",
                                    borderBottom: "4px solid black"
                                  }}
                                >
                                  <Typography sx={{ cursor: "pointer" }}>
                                    {parentPortId?.toString() || ""}
                                  </Typography>
                                </Box>

                                {/* ports */}
                                {ports.map((ap, i2) => (
                                  <Box
                                    key={`${ap.portId}-${i2}`}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      height: portBoxHeight,
                                      boxSizing: "border-box",
                                      backgroundColor:
                                        figureAndonColorFromStatus({
                                          portStatus: ap.status,
                                          portOpen: ap.isOpen
                                        }),
                                      border: "1px solid black"
                                    }}
                                  >
                                    <Typography sx={{ cursor: "pointer" }}>
                                      {ap.port.portId}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                              {/* bottom rows */}
                              <Box
                                sx={{
                                  width: "100%"
                                }}
                              >
                                <Box
                                  sx={{
                                    height: portBoxHeight,
                                    backgroundColor: aws.handRaised
                                      ? "black"
                                      : undefined
                                  }}
                                >
                                  {/* hand raised */}
                                  {aws.handRaised && (
                                    <BackHandIcon
                                      sx={{
                                        width: "90%",
                                        height: "90%",
                                        color: andonColors.handRaisedYellow
                                      }}
                                    />
                                  )}
                                </Box>
                                {/* active status */}
                                <Box
                                  sx={{
                                    height: portBoxHeight,
                                    boxSizing: "border-box",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor:
                                      ws.status === "Active"
                                        ? "success.main"
                                        : "gray.light"
                                  }}
                                >
                                  {/* checkmark when ws is already selected */}
                                  {selectedWorkstationIds.includes(ws.id) &&
                                    "✔"}
                                </Box>
                              </Box>
                            </Box>
                          </ColumnButton>
                        </Tooltip>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <InputLabel id="add-workstation-label">
          {t("add workstation by name")}
        </InputLabel>
        <Select
          id="add-workstation"
          labelId="add-workstation-label"
          style={{
            width: 400,
            marginBottom: 10
          }}
          value=""
          onChange={(e: SelectChangeEvent) =>
            props.setNewIdsCb([...selectedWorkstationIds, e.target.value])
          }
          MenuProps={MenuProps}
          disabled={
            selectedWorkstationIds.length === fetchedWorkstations.length
          }
        >
          {fetchedWorkstations
            .filter((ws) => !selectedWorkstationIds.includes(ws.id))
            .slice()
            .sort(
              (a, b) =>
                a.autostoreGridName.localeCompare(b.autostoreGridName) ||
                a.deviceId.localeCompare(b.deviceId)
            )
            .map((workstation, i) => (
              <MenuItem
                id={`${workstation.id}-workstationName-${i}`}
                key={workstation.deviceId}
                value={workstation.id}
              >
                {`${workstation.deviceId}`}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </Stack>
  );
}

export default WorkstationSelector;
