import {
  Box,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "~/app/store";

import {
  selectAdminSummariesGrid,
  selectWorkstationStatus,
  setSelectedAdminSummariesGridId,
  setSelectedWorkstationStatus,
  WorkstationStatusType,
  workstationStatusTypes
} from "~/features/serviceSupport/serviceSupport.slice";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

const WorkstationSelectionContainer = () => {
  const selectedGridId = useAppSelector(selectAdminSummariesGrid);
  const selectedWorkstationStatus = useAppSelector(selectWorkstationStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(150px, 300px)) 1fr",
        gap: 2
      }}
    >
      <FormControl>
        <FormLabel id="grid-selector">{t("autostore grid")}</FormLabel>
        <Select
          labelId="grid-select"
          variant="outlined"
          value={selectedGridId ?? ""}
          onChange={(e: SelectChangeEvent) =>
            dispatch(setSelectedAdminSummariesGridId(e.target.value))
          }
          displayEmpty
        >
          <MenuItem value="">{t("none")}</MenuItem>
          {autostoreGrids?.map((grid) => (
            <MenuItem key={grid.autostoreGridId} value={grid.autostoreGridId}>
              {grid.autostoreGridName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel id="workstations-status-selector">
          {t("workstation status")}
        </FormLabel>
        <Select
          labelId="workstation-status-select"
          variant="outlined"
          value={selectedWorkstationStatus ?? ""}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setSelectedWorkstationStatus(
                e.target.value as WorkstationStatusType
              )
            )
          }
          displayEmpty
        >
          <MenuItem value="">{t("none")}</MenuItem>
          {workstationStatusTypes.map((workstationStatusType) => (
            <MenuItem
              key={`workstation-status-${workstationStatusType}`}
              value={workstationStatusType}
            >
              {workstationStatusType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WorkstationSelectionContainer;
