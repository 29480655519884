import { Box, Grid, Pagination, Typography, styled } from "@mui/material";
import { ErrorPanel, AutostoreTable } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { usePagination } from "~/hooks/usePagination";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetInventoryMovementsStateQuery,
  usePostStartInventoryMovementsQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { BinMovementAssignment } from "~/types/api";

const TruncatedText = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

type Props = {
  selectedRow: BinMovementAssignment | undefined;
  isLoading: boolean;
};

const MovementsTasksTable = (props: Props) => {
  const { isLoading, selectedRow } = props;
  const { t } = useTranslation();
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const workstationId = useAppSelector(selectWorkstationId);

  const { error: startMovementsError, isLoading: isLoadingStartMovements } =
    usePostStartInventoryMovementsQuery(
      autostoreGridId && workstationId
        ? { autostoreGridId, workstationId }
        : skipToken
    );
  const { data: tasks, error: getMovementsStateError } =
    useGetInventoryMovementsStateQuery(
      autostoreGridId && workstationId && !isLoadingStartMovements
        ? { autostoreGridId, workstationId }
        : skipToken
    );

  const shouldShowErrorPanel =
    !!startMovementsError || !!getMovementsStateError;
  const {
    currentPage,
    setCurrentPage,
    totalPages,
    currentPageRows: currentRowTasks
  } = usePagination({ rows: tasks, rowsPerPage: 7 });

  const tableColumnNames = [
    t("product"),
    t("bin"),
    t("quantity"),
    t("flag type")
  ];

  const tableColumns: ((row: BinMovementAssignment) => React.ReactElement)[] = [
    (row) => (
      <TruncatedText variant="h6" sx={{ fontWeight: 400 }}>
        {row.variantName}
      </TruncatedText>
    ),
    (row) => <Typography>{row.binId}</Typography>,
    (row) => (
      <Typography>
        {row.quantity.value} {row.quantity.units}
      </Typography>
    ),
    (row) => (
      <Box>
        {row.flags?.map((flag) => (
          <Typography
            key={`${row.id}-${flag}`}
            sx={{ textTransform: "capitalize" }}
          >
            {flag}
          </Typography>
        ))}
      </Box>
    )
  ];

  return (
    <Box minHeight="700px" mb="12px">
      {shouldShowErrorPanel ? (
        <ErrorPanel
          message={getMessageFromRtkError(
            startMovementsError || getMovementsStateError
          )}
        />
      ) : (
        <>
          <AutostoreTable<BinMovementAssignment>
            headerColNames={tableColumnNames}
            rowId={(row) => row.id}
            renderColumns={tableColumns}
            widthOfCols={["50%", "15%", "15%", "20%"]}
            headerVariant="overline"
            bodyVariant="body2"
            rowData={currentRowTasks}
            selectedRow={selectedRow}
            loading={isLoading}
          />
          <Grid justifyContent="center" sx={{ display: "flex", pt: 1 }}>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_e, p) => {
                  setCurrentPage(p);
                  if (window.scrollTo) window.scrollTo(0, 0);
                }}
                shape="rounded"
              />
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default MovementsTasksTable;
