import {
  Grid,
  Typography,
  Pagination,
  useMediaQuery,
  Container,
  Box
} from "@mui/material";
import {
  mobileWidth,
  formatUtcDate,
  useToast,
  AutostoreTable,
  ProductCard
} from "@qubit/autoparts";
import moment from "moment";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";
import { InventoryHoldModal } from "~/features/inventoryHoldModal/InventoryHoldModal";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import {
  getVariantDisplayNameByDtoFe,
  generateLocationNameFromInventorySummary,
  checkIsExpiration,
  getInventoryDateObj,
  holdTypeCheck,
  HoldType
} from "~/lib/helpers";

import {
  getInventorySummaries,
  deleteInventoryHold,
  getVariantByVariantId
} from "~/redux/actions/inventory";
import { setUserMessage } from "~/redux/actions/site";
import { InventoryHoldReasonCode } from "~/redux/public/inventory.openApi";
import { selectInventoryDateLabelEnabled } from "~/redux/selectors/siteSelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import { InventorySummaryDto } from "~/types/api";

import { InventoryHoldsToolbar } from "./InventoryHoldsToolbar";
import {
  selectCommonHoldsForSelectedSummary,
  selectSelectedRow,
  selectSelectedSummary,
  setIsInventoryAdjustDialogOpen,
  setIsBinHoldModalOpen,
  setSelectedRow
} from "./inventoryHolds.slice";

type Props = { viewTitle?: ViewNameTranslation };

/** Manual Ops Inventory Holds */
export function InventoryHolds(props: Props) {
  const { viewTitle } = props;
  const { errorToast } = useToast();

  const isMobile = useMediaQuery(mobileWidth);
  const { successToast } = useToast();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setToolbar } = useNavbar({ viewTitle });

  const inv_inventoryDateLabel = useAppSelector(
    selectInventoryDateLabelEnabled
  );
  const inventorySummaries = useAppSelector(
    (state) => state.inventory.inventorySummaries
  );
  const inventorySummariesCount = useAppSelector(
    (state) => state.inventory.inventorySummariesCount
  );
  const isInventoryAdjustDialogOpen = useAppSelector(
    (state) => state.inventoryHolds.isInventoryAdjustDialogOpen
  );
  const isBinHoldModalOpen = useAppSelector(
    (state) => state.inventoryHolds.isBinHoldModalOpen
  );
  const loadingInventory = useAppSelector(
    (state) => state.inventory.loadingInventory
  );
  const selectedRow = useAppSelector(selectSelectedRow);
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const thisWorkstationId = useAppSelector(selectWorkstationId);
  const selectedSummary = useAppSelector(selectSelectedSummary);
  const commonHolds = useAppSelector(selectCommonHoldsForSelectedSummary);

  const [page, setPage] = useState<number>(1);
  const limit = 5;
  const offset = page === 0 ? 0 : (page - 1) * limit;
  const totalPageCount = inventorySummariesCount
    ? Math.ceil(inventorySummariesCount / limit)
    : 0;

  const handleGetInventorySummaries = () => {
    dispatch(setSelectedRow(null));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    dispatch(
      getInventorySummaries({
        binTypesExcluded: "autostore",
        hasHold: true,
        limit,
        offset
      })
    );
  };

  useEffect(() => {
    handleGetInventorySummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  useEffect(() => {
    if (selectedSummary) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(getVariantByVariantId(selectedSummary.variantId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSummary]);

  const selectedInventoryToModify: InventoryToModify = selectedSummary
    ? {
        binType: selectedSummary.binType,
        binId: selectedSummary.binId,
        inventoryId: selectedSummary.inventoryId,
        locationName: generateLocationNameFromInventorySummary(selectedSummary),
        count: selectedSummary.count,
        committedCount: selectedSummary.committedCount,
        ...getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(
            checkIsExpiration(inv_inventoryDateLabel)
              ? selectedSummary.expiration
              : selectedSummary.manufactureDate
          )
        ),

        lotNumber: selectedSummary.lotNumber,
        variantId: selectedSummary.variantId,
        holds: selectedSummary.holds
      }
    : null;

  useEffect(() => {
    setToolbar(selectedSummary && <InventoryHoldsToolbar />);
  }, [selectedSummary, setToolbar]);

  const handleRemoveFlags = (
    inventoryToRemove: InventoryToModify[] | InventorySummaryDto[],
    holdType?: HoldType
  ) => {
    for (const inv of inventoryToRemove) {
      if (holdType) {
        if (
          inv?.holds &&
          inv.holds.some((hold) => hold.reasonCode === holdType.reasonCode)
        ) {
          if (holdTypeCheck(holdType, errorToast, moment(inv.expiration)))
            return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          dispatch(
            deleteInventoryHold(
              inv.inventoryId,
              thisWorkstationId || null,
              holdType.reasonCode
            )
          ).then(() => {
            successToast(t("inventory hold removed"));
            if (isInventoryAdjustDialogOpen) {
              dispatch(setIsInventoryAdjustDialogOpen(false));
            }
            dispatch(setSelectedRow(null));
            dispatch(setIsBinHoldModalOpen(false));
            // calculate the current page after some holds are removed
            if (inventorySummaries.length === 1) {
              // if removing the last row on the page, go back a page
              setPage(page === 0 ? 0 : page - 1);
            } else handleGetInventorySummaries();
          });
        } else if (
          inv?.holds &&
          !inv.holds.some((hold) => hold.reasonCode === holdType.reasonCode)
        ) {
          dispatch(
            setUserMessage({
              title: `${t(holdType.reasonCode)} ${t("doesn't exist on inventory record")}`,
              severity: "error",
              autohideDuration: 2000,
              origin: "inventory/HOLD_DOESNT_EXIST"
            })
          );
        }
      }
    }
  };

  const removeHoldCb = (holdType: HoldType | null | undefined) => {
    if (holdType && selectedInventoryToModify) {
      handleRemoveFlags([selectedInventoryToModify], holdType);
    }
  };

  let fourthColumnName = "";

  switch (inv_inventoryDateLabel) {
    case "manufacture":
      fourthColumnName = t("manufacture date");
      break;
    case "expiration":
    default:
      fourthColumnName = t("expiration");
      break;
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item container>
          {/* product information */}
          <Grid item xs={12} lg={4} style={{ margin: "16px 0 0 0" }}>
            {selectedVariant && selectedSummary ? (
              <ProductCard.Root
                productName={
                  getVariantDisplayNameByDtoFe(selectedVariant) ?? ""
                }
                imageFileName={selectedVariant.imageFilename}
                sx={{ height: "480px" }}
              >
                <ProductCard.Sku sku={selectedVariant.sku} />
                <ProductCard.ProductCount count={selectedSummary.count} />
              </ProductCard.Root>
            ) : (
              <ProductCard.Loading height={480} />
            )}
          </Grid>
          <Grid item xs={12} lg={8} style={{ margin: "16px 0 16px 0" }}>
            {!loadingInventory && inventorySummaries.length > 0 && (
              <AutostoreTable<InventorySummaryDto>
                widthOfCols={["40%", "10%", "15%", "15%", "20%"]}
                headerVariant="overline"
                bodyVariant="body2"
                headerColNames={[
                  t("product"),
                  t("quantity"),
                  t("bin"),
                  isMobile ? null : fourthColumnName,
                  t("hold type")
                ].filter((el) => el !== null)}
                rowId={(row: InventorySummaryDto) => row.inventoryId}
                renderColumns={
                  [
                    (row: InventorySummaryDto) => row.productName,
                    (row: InventorySummaryDto) =>
                      row.count?.value.toString() || "",
                    (row: InventorySummaryDto) =>
                      generateLocationNameFromInventorySummary(row),
                    isMobile
                      ? null
                      : (row: InventorySummaryDto) =>
                          formatUtcDate(row.expiration),
                    (row: InventorySummaryDto) => {
                      return (
                        <Box>
                          {row.holds?.map((hold) => (
                            <Box key={hold.reasonCode}>
                              <Typography>
                                {t(
                                  hold.reasonCode.toLowerCase() as InventoryHoldReasonCode
                                )}
                              </Typography>
                            </Box>
                          )) || ""}
                        </Box>
                      );
                    }
                  ].filter((el) => el !== null) as ((
                    row: InventorySummaryDto
                  ) => string | React.ReactElement)[]
                }
                rowData={inventorySummaries}
                selectedRows={selectedRow ? [selectedRow] : undefined}
                selectRowCallback={(row: InventorySummaryDto) => {
                  if (row.inventoryId === selectedRow) {
                    dispatch(setSelectedRow(null));
                  } else {
                    dispatch(setSelectedRow(row.inventoryId));
                  }
                }}
                loading={loadingInventory}
              />
            )}
            {!loadingInventory && !inventorySummaries.length && (
              <Typography variant="h5" align="center" style={{ padding: 10 }}>
                {t("no inventory holds")}
              </Typography>
            )}
            {/* only show pagination for the initial holds list */}
            {totalPageCount > 1 && (
              <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                <Pagination
                  count={totalPageCount}
                  page={page}
                  onChange={(_e, p) => {
                    setPage(p);
                    if (window.scrollTo) window.scrollTo(0, 0);
                  }}
                  shape="rounded"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!selectedInventoryToModify && (
          <InventoryAdjustDialog
            open={isInventoryAdjustDialogOpen}
            onClose={() => dispatch(setIsInventoryAdjustDialogOpen(false))}
            invToModify={selectedInventoryToModify}
            selectedVariant={selectedVariant || null}
            refreshCb={() => {
              handleGetInventorySummaries();
              dispatch(setIsInventoryAdjustDialogOpen(false));
            }}
            canceledReason=""
          />
        )}

        <InventoryHoldModal
          confirmCb={(holdType) => removeHoldCb(holdType)}
          open={isBinHoldModalOpen}
          closeModalCallback={() => dispatch(setIsBinHoldModalOpen(false))}
          maxWidth="sm"
          options={commonHolds}
          confirmButtonText={t("remove hold")}
          currentHold=""
        />
      </Grid>
    </Container>
  );
}
