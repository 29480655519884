import {
  Bin20Px,
  BookmarkFill20Px,
  Close20Px,
  Upload20Px
} from "@locaisolutions/icons";

import { Button, Grid, Stack, SvgIcon, useTheme } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { t } from "i18next";

import { useState } from "react";
import { useLocation } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";
import FlagBinModal from "~/features/autostorePutaway/modals/FlagBinModal";

import usePortStatus from "~/hooks/usePortStatus";

import { isAutostoreView } from "~/lib/helpers";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectSelectedInventoryBin } from "~/redux/selectors/autostoreSelectors";
import { selectSelectedInventoryRows } from "~/redux/selectors/inventorySelectors";

import { useLazyGetInventorySummaryListQuery } from "~/redux/warehouse/inventory.hooks";
import { InventorySummaryDto } from "~/types/api";

import {
  setIsAdjustingBins,
  setIsInventoryAdjustDialogOpen,
  setIsGetBinsLoading,
  updateSelectedSummaries,
  setSelectedSummaries
} from "./inventory.slice";

interface BottomActionsProps {
  isAdjustingBins: boolean;
  isGetBinsDisabled: boolean;
  selectedSummaries: InventorySummaryDto[];
  inventorySummaryToDisplay: InventorySummaryDto | null;
  selectedInventoryAtPort?: InventorySummaryDto;
  handleGetBinsMultiPort: (specificBinIds?: number[]) => Promise<void>;
  handleGetInventorySummaries: (args: {
    variantId?: Guid;
    searchedBinIds?: Guid | Guid[];
    offsetZero?: boolean;
    holds?: string[];
  }) => Promise<void>;
  searchedBinIds: Guid[] | null;
}

export const BottomActionButtons: React.FC<BottomActionsProps> = (props) => {
  const {
    isAdjustingBins,
    isGetBinsDisabled,
    selectedSummaries,
    handleGetBinsMultiPort
  } = props;
  const locationInfo = useLocation();
  const trackedPageName = useMixpanelPageName();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { search } = locationInfo;
  const isAutostoreInventoryView = isAutostoreView(search);

  const { successToast, errorToast } = useToast();
  const selectedRows = useAppSelector(selectSelectedInventoryRows);
  const currentSelectedBin = useAppSelector(selectSelectedInventoryBin);

  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );
  const siteWorkstation = useAppSelector(
    (state) => state.workstations.siteWorkstation
  );
  const nextBinInventoryByPort = useAppSelector(
    (state) => state.autostore.nextBinInventoryByPort
  );
  const nextEmptyBinByPort = useAppSelector(
    (state) => state.autostore.nextEmptyBinByPort
  );
  const siteAllPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );

  const { areSomePortsReady, isSelectedBinReady } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedBin?.portId
  );
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);
  const [getUpdatedInventorySummary] = useLazyGetInventorySummaryListQuery();
  const isSingleSelect: boolean = selectedRows.length === 1;

  return (
    <>
      {isAutostoreInventoryView && (
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {!isAdjustingBins && !isSelectedBinReady ? (
            <Stack direction="row" spacing={2}>
              <Button
                style={{ minWidth: "200px" }}
                disabled={isGetBinsDisabled}
                startIcon={<Bin20Px fill="white" />}
                onClick={async () => {
                  mixpanelTrack({
                    trackedPageName,
                    type: "Button Click",
                    label: "Get Bin"
                  });
                  dispatch(setIsAdjustingBins(true));
                  try {
                    await handleGetBinsMultiPort();
                    dispatch(setIsGetBinsLoading(false));
                  } catch (error) {
                    errorToast(getMessageFromRtkError(error));
                  }
                }}
              >
                {isSingleSelect || selectedRows.length === 0
                  ? t("get bin")
                  : `${t("get bins")} (${selectedRows.length})`}
              </Button>
              <Button
                style={{ minWidth: "200px" }}
                variant="subtle"
                disabled={!selectedSummaries.length}
                startIcon={
                  <Close20Px
                    fill={
                      !selectedSummaries.length
                        ? palette.darkGray.light
                        : "black"
                    }
                  />
                }
                onClick={() => {
                  mixpanelTrack({
                    trackedPageName,
                    type: "Button Click",
                    label: "Clear Bin Selection"
                  });
                  dispatch(setSelectedSummaries([]));
                }}
              >
                {t("clear bin selection")}
              </Button>
            </Stack>
          ) : (
            <>
              <Button
                id="adjust-button"
                style={{ minWidth: "200px" }}
                size="small"
                onClick={() => {
                  dispatch(setIsInventoryAdjustDialogOpen(true));
                  mixpanelTrack({
                    trackedPageName,
                    type: "Button Click",
                    label: "Adjust",
                    eventProperties: currentSelectedBin
                  });
                }}
                disabled={!isSelectedBinReady}
                startIcon={<Upload20Px fill="white" />}
              >
                {t("adjust")}
              </Button>
              {binFlaggingEnabled && (
                <Button
                  id="flag-button"
                  style={{ minWidth: "200px" }}
                  size="small"
                  variant="subtle"
                  onClick={() => {
                    setIsFlagBinModalOpen(true);
                    mixpanelTrack({
                      trackedPageName,
                      type: "Button Click",
                      label: "Flag Bin",
                      eventProperties: currentSelectedBin
                    });
                  }}
                  disabled={!areSomePortsReady}
                  startIcon={
                    <SvgIcon sx={{ mt: 0.5 }} component={BookmarkFill20Px} />
                  }
                >
                  {t("flag bin")}
                </Button>
              )}
            </>
          )}
        </Grid>
      )}
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextBinInventoryByPort={nextBinInventoryByPort}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        shouldCloseBinUponSubmit={false}
        onSuccess={async (binNumber) => {
          try {
            const inventoryToUpdate = selectedSummaries?.filter(
              (inv) => inv.autostoreBinNumber === binNumber
            );
            const query = getUpdatedInventorySummary({
              inventoryIds: [
                ...inventoryToUpdate.map((inv) => inv.inventoryId)
              ],
              limit: inventoryToUpdate.length,
              offset: 0
            });
            const queryResult = await query.unwrap();
            if (queryResult?.inventory.length > 0) {
              dispatch(updateSelectedSummaries(queryResult.inventory));
            }
            successToast(t("flag set successfully"));
          } catch (error) {
            errorToast(getMessageFromRtkError(error));
          }
        }}
      />
    </>
  );
};
