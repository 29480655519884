import { useLoading } from "@agney/react-loading";
import ThreeDots from "@agney/react-loading/src/svg-loaders/three-dots.svg?react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlagIcon from "@mui/icons-material/Flag";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useTheme
} from "@mui/material";

import { CardMediaWithFallback } from "@qubit/autoparts";

export const ShadedImageOverlay = styled("div")(
  (props: { backgroundColor: string }) => ({
    height: "220px",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    background: props.backgroundColor,
    opacity: "0.5",
    top: 0,
    textAlign: "center"
  })
);

function StatusOverlay({
  className,
  color,
  icon,
  statusText,
  textOffset = false,
  completionPercentage
}: {
  className: string;
  color: string;
  icon?: React.ReactElement;
  statusText: string | null;
  textOffset?: boolean;
  completionPercentage?: number;
}) {
  return (
    <div className={className}>
      <ShadedImageOverlay
        backgroundColor={color}
        style={{ width: `${(completionPercentage || 100).toString()}%` }}
        data-testid="img-overlay"
      />
      <div
        style={{
          marginTop: "15px",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          position: "absolute",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          style={{
            height: 100,
            width: 100,
            display: icon || textOffset ? "inherit" : "none"
          }}
        >
          {icon}
        </Box>

        {statusText && (
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: "#fff"
            }}
          >
            {statusText}
          </Typography>
        )}
      </div>
    </div>
  );
}

export interface ProductImageProps {
  imageFilename: string | null;
  modalStatus: "loading" | "checked" | "notice" | "error" | "flagged" | "none";
  modalStatusText: string | null;
  completionPercentage?: number;
  upc: string;
  sku: string;
}

/**
 * Displays a upc, sku, & product image with a status overlay, if specified.
 */
export function ProductImage(props: ProductImageProps) {
  const {
    imageFilename,
    modalStatus,
    modalStatusText,
    completionPercentage,
    upc,
    sku
  } = props;

  const { palette } = useTheme();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <ThreeDots
        color="white"
        width="50"
        style={{ top: 115, left: "45%", position: "absolute" }}
      />
    )
  });

  return (
    <>
      <Container
        className="upc-sku-container"
        sx={{
          zndex: 2,
          position: "absolute",
          bgcolor: "background.default",
          pb: "1px",
          userSelect: "text"
        }}
      >
        <Typography
          variant="overline"
          color="initial"
          component="div"
          align="center"
          sx={{
            whiteSpace: "normal",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box>{`UPC: ${upc}`}</Box>
          <Box>{`SKU: ${sku}`}</Box>
        </Typography>
      </Container>

      {modalStatus === "checked" && (
        <StatusOverlay
          className="checked-overlay"
          color={palette.success.main}
          statusText={modalStatusText || null}
          icon={
            <CheckCircleIcon
              style={{ fill: "white", width: 100, height: 100 }}
            />
          }
        />
      )}

      {modalStatus === "notice" && (
        <StatusOverlay
          className="notice-overlay"
          color={palette.warning.main}
          statusText={modalStatusText || null}
          textOffset
          completionPercentage={completionPercentage}
        />
      )}

      {modalStatus === "error" && (
        <StatusOverlay
          className="error-overlay"
          color={palette.error.dark}
          statusText={modalStatusText || null}
          icon={
            <CancelIcon style={{ fill: "white", width: 100, height: 100 }} />
          }
        />
      )}

      {modalStatus === "flagged" && (
        <StatusOverlay
          className="flagged-overlay"
          color={palette.warning.dark}
          statusText={modalStatusText || null}
          icon={<FlagIcon style={{ fill: "white", width: 100, height: 100 }} />}
        />
      )}

      {modalStatus === "loading" && (
        <StatusOverlay
          className="loading-overlay"
          color={palette.gray.dark}
          statusText={modalStatusText || null}
          textOffset
        />
      )}

      {modalStatus === "loading" && (
        <Grid
          className="loading-progress-container"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <section {...containerProps} style={{ zIndex: 10 }}>
            {indicatorEl}
          </section>
        </Grid>
      )}

      {/* problem here if image is missing but is substituion */}
      <Box className="image-box">
        <CardMediaWithFallback
          image={imageFilename ?? undefined}
          sx={{
            height: "220px",
            textAlign: "center"
          }}
        />
      </Box>
    </>
  );
}
