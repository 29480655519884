import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { AxiosError } from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import usePortStatus from "~/hooks/usePortStatus";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";

import { getInventoryDateObj, getAxiosErrorMessage } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { nextEmptyBin, fetchPortStatus } from "~/redux/actions";
import {
  selectCurrentlySelectedBinId,
  selectIsInventoryDateValid,
  selectSelectedRow
} from "~/redux/selectors/autostorePutawaySelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { fixedCacheKeys } from "~/redux/warehouse/fixedCacheKeys";
import {
  usePatchDecantingRateMutation,
  usePostPutItemAwayMutation
} from "~/redux/warehouse/putAwayTasks.hooks";
import {
  GridTargetPort,
  Guid,
  MeasuredValueDto,
  PutAwayItemRequest,
  SetDecantingRateRequest
} from "~/types/api";

import {
  setSearchData,
  setIsPutawayTaskTableRefreshed,
  setPortPollingActive,
  setClosePortBtnDisabled,
  reset,
  selectLotNumber
} from "./autostorePutaway.slice";
import { useGetPutawayTasks } from "./useGetPutawayTasks";

type PutAwayItem = {
  putAwayTaskId: Guid;
  quantity: MeasuredValueDto;
  targetBinId: Guid;
  targetPort?: GridTargetPort;
  expiration?: Date | undefined;
  lotNumber?: string;
  manufactureDate?: string | undefined;
  workstationId: Guid | undefined;
  decantingRate?: MeasuredValueDto;
  shouldForceUpdateDecantingRate?: boolean;
};

export function ConfirmInductionButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errorToast, successToast } = useToast();

  const intervalRef = useRef(-1);

  const searchData = useAppSelector(
    (state) => state.autostorePutaway.searchData
  );

  const pageLimit = 7;
  const { putawayTasks } = useGetPutawayTasks(pageLimit, searchData);

  const selectedRow = useAppSelector((state) =>
    selectSelectedRow(state, putawayTasks)
  );

  const currentSelectedBin = useAppSelector(
    (state) => state.autostore.currentEmptyBin
  );

  const selectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.selectedCompartment
  );

  const selectedCompartmentBinId = useAppSelector(selectCurrentlySelectedBinId);

  const changedQuantity = useAppSelector(
    (state) => state.autostorePutaway.changedQuantity
  );

  const siteWorkstation = useAppSelector(selectThisWorkstation);

  const { inv_inventoryDateLabel, putaway_multipleSearchTerms } =
    useAppSelector(selectClientConfig);

  const inventoryDate = useAppSelector(
    (state) => state.autostorePutaway.inventoryDate
  );
  const lotNumber = useAppSelector(selectLotNumber);

  const closePortBtnDisabled = useAppSelector(
    (state) => state.autostorePutaway.closePortBtnDisabled
  );

  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );

  const rowHasDate =
    selectedRow?.expirationDate || selectedRow?.manufactureDate;
  const hasDateBeenRemoved = !inventoryDate && rowHasDate;
  const isInventoryDateValid =
    useAppSelector((state) =>
      selectIsInventoryDateValid(state, putawayTasks)
    ) && !hasDateBeenRemoved;

  const suggestBinConfigurationError = useAppSelector(
    (state) => state.autostorePutaway.suggestBinConfigurationError
  );

  const suggestBinConfigurationIsLoading = useAppSelector(
    (state) => state.autostorePutaway.suggestBinConfigurationIsLoading
  );

  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const currentSelectedPortId = currentSelectedBin?.openBinResponse.portId;
  const siteAllPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );

  const { isSelectedBinReady } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedPortId
  );

  const [
    patchDecantingRate,
    {
      isLoading: isUpdatingDecantingRate,
      isSuccess: hasUpdatedDecantingRate,
      reset: resetPatchDecantingRateState
    }
  ] = usePatchDecantingRateMutation({
    fixedCacheKey: fixedCacheKeys.confirmInductionButtonDecantingRate
  });

  const [
    postPutItemAway,
    {
      isLoading: isPuttingItemAway,
      isSuccess: hasPutItemAway,
      reset: resetPutItemAwayState
    }
  ] = usePostPutItemAwayMutation({
    fixedCacheKey: fixedCacheKeys.confirmInductionButtonPutItemAway
  });

  // clear the interval on unmount, if it is set
  useEffect(() => {
    return () => {
      if (intervalRef.current != -1) {
        clearInterval(intervalRef.current);
        intervalRef.current = -1;
      }
    };
  }, []);

  const putAwayItem = useCallback(
    async (args: PutAwayItem) => {
      const {
        putAwayTaskId,
        quantity,
        targetBinId,
        targetPort,
        workstationId,
        decantingRate,
        shouldForceUpdateDecantingRate
      } = args;

      const request: PutAwayItemRequest = {
        quantity,
        targetBinId,
        targetPort,
        targetExpiration: args.expiration,
        targetLotNumber: args.lotNumber,
        targetManufactureDate: args.manufactureDate,
        workstationId
      };

      const decantingRateRequest: SetDecantingRateRequest = { quantity };

      if (
        (decantingRate && decantingRate?.value !== quantity.value) ||
        shouldForceUpdateDecantingRate
      ) {
        await patchDecantingRate({
          putAwayTaskId,
          request: decantingRateRequest
        }).unwrap();
      }

      await postPutItemAway({ putAwayTaskId, request }).unwrap();
    },
    [patchDecantingRate, postPutItemAway]
  );

  const handleConfirmPutaway = useCallback(async () => {
    if (!selectedRow || !selectedCompartmentBinId) return;

    const putAwayItemArgs: PutAwayItem = {
      putAwayTaskId: selectedRow.putAwayTaskId,
      quantity: {
        value: changedQuantity || selectedRow.remaining.value,
        units: selectedRow.remaining.units
      },
      decantingRate: selectedRow.decantingRate,
      targetBinId: selectedCompartmentBinId,
      targetPort: {
        gridId: siteWorkstation?.autostoreGridId || "",
        portId: currentSelectedPortId || 0
      },
      lotNumber,
      ...getInventoryDateObj(inv_inventoryDateLabel, moment(inventoryDate)),
      workstationId: siteWorkstation?.id,
      // If the product currently has no decant rate, we want to save the quantity as the the
      // decant rate for the future
      shouldForceUpdateDecantingRate: !selectedRow?.decantingRate
    };

    try {
      await putAwayItem(putAwayItemArgs);

      successToast(t("putaway complete"));
      dispatch(reset());
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
      resetPutItemAwayState();
      resetPatchDecantingRateState();
      return;
    }

    if (putaway_multipleSearchTerms)
      dispatch(setSearchData({ ...searchData, scannedBarcodes: [] }));

    // TODO: This is essentially copy/pasted from fetchNextEmptyBin in useStartInduction.ts.
    //       Prime candidate to be refactored and consolidated.
    // For now, we don't have something like 'close bin' event
    // So there is no way to know when this operation will be completed
    // Because of that, we periodically check if bin is closed
    const fetchNextEmptyBin = () => {
      const fetchBinPoll = async () => {
        const portStatus = await dispatch(
          fetchPortStatus({
            portId: currentSelectedPortId
          })
        );

        if (
          portStatus &&
          portStatus.mode === "OPEN" &&
          portStatus.selectedBin === 0
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = -1;
          try {
            await dispatch(
              nextEmptyBin({
                portId: currentSelectedBin?.openBinResponse.portId
              })
            );
          } catch (err: unknown) {
            errorToast(
              getAxiosErrorMessage(err as AxiosError) ??
                "No more empty bins found"
            );
            if (portStatus.mode === "OPEN") {
              if (!shouldListenToGridEvents) {
                dispatch(setPortPollingActive(false));
              }
              if (!closePortBtnDisabled) {
                dispatch(setClosePortBtnDisabled(true));
              }
            }
          }

          if (!shouldListenToGridEvents) {
            dispatch(setPortPollingActive(true));
          }
          dispatch(reset());
        }
      };
      intervalRef.current = window.setInterval(() => {
        void fetchBinPoll();
      }, 500);
    };

    fetchNextEmptyBin();

    // last task on selected page but not a partial putaway - clear search, refresh list
    if (
      putawayTasks.length === 1 &&
      !(changedQuantity && changedQuantity !== selectedRow.remaining.value)
    ) {
      if (searchData.offset !== 1) {
        // user is not on first page, send them back a page
        dispatch(
          setSearchData({ ...searchData, offset: searchData.offset - 1 })
        );
      } else {
        // user is on first page, clear search, then refetch putaways
        if (searchData.searchInputValue || searchData.scannedBarcodes.length) {
          dispatch(
            setSearchData({
              scannedBarcodes: [],
              offset: 1
            })
          );
        }
        dispatch(setIsPutawayTaskTableRefreshed(true));
      }
      // not the last task or any type of partial putaway - keep search term, refetch results
    }

    resetPutItemAwayState();
    resetPatchDecantingRateState();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [
    changedQuantity,
    closePortBtnDisabled,
    currentSelectedBin?.openBinResponse.portId,
    currentSelectedPortId,
    dispatch,
    errorToast,
    inv_inventoryDateLabel,
    inventoryDate,
    lotNumber,
    putAwayItem,
    putawayTasks.length,
    putaway_multipleSearchTerms,
    resetPatchDecantingRateState,
    resetPutItemAwayState,
    searchData,
    selectedCompartmentBinId,
    selectedRow,
    shouldListenToGridEvents,
    siteWorkstation?.autostoreGridId,
    siteWorkstation?.id,
    successToast,
    t
  ]);

  const isLotNumberValid =
    !!lotNumber || !selectedRow?.product.isLotNumberRequired;

  const isConfirmButtonEnabled =
    !!selectedRow &&
    selectedCompartment !== undefined &&
    isSelectedBinReady &&
    !!(changedQuantity || selectedRow?.quantity.value) &&
    isInventoryDateValid &&
    !!changedQuantity &&
    !suggestBinConfigurationError &&
    !suggestBinConfigurationIsLoading &&
    isLotNumberValid;

  // If we're in the middle of calling the Put Item Away or Decanting Rate APIs,
  // make sure the button is disabled. This will "debounce" it.
  const isConfirmButtonDisabled =
    isUpdatingDecantingRate ||
    hasUpdatedDecantingRate ||
    isPuttingItemAway ||
    hasPutItemAway;

  return (
    <Button
      style={{ width: "100%" }}
      size="large"
      startIcon={<CheckIcon />}
      onClick={handleConfirmPutaway}
      disabled={!isConfirmButtonEnabled || isConfirmButtonDisabled}
    >
      {t("confirm induction")}
    </Button>
  );
}
