import { Typography } from "@mui/material";
import {
  ErrorPanel,
  ASTableV2,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2Cell,
  ASTableV2Body,
  ASTableV2RowSkeleton,
  ASTableV2RowEmpty,
  globalDateTimeFormat,
  RelativeTime
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import { t } from "i18next";

import { useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetUnifyPortErrorsQuery } from "~/redux/warehouse/unify.openApi";

export const UnifyPortErrors = () => {
  const selectedGridId = useAppSelector(
    (state) => state.serviceSupport.selectedGridId
  );
  const selectedWorkstationId = useAppSelector(
    (state) => state.serviceSupport.selectedWorkstationId
  );
  const {
    data: portErrors,
    isFetching: isUnifyFetching,
    error: unifyFetchError,
    refetch: refetchPortErrors
  } = useGetUnifyPortErrorsQuery(
    selectedGridId
      ? {
          autostoreGridId: selectedGridId,
          workstationId: selectedWorkstationId,
          limit: null
        }
      : skipToken
  );

  return (
    <>
      {unifyFetchError && (
        <ErrorPanel
          message={getMessageFromRtkError(unifyFetchError)}
          tryAgain={{
            onTryAgain: async () => {
              await refetchPortErrors();
            }
          }}
        />
      )}
      {!unifyFetchError && (
        <ASTableV2 gridTemplateColumns={"repeat(5, auto)"} isLoading={false}>
          <ASTableV2Header>
            <ASTableV2Row>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {t("date and time")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("error code")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {t("error description")}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("port id")}</Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <Typography variant="tableBody">{t("status")}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Header>
          <ASTableV2Body>
            {isUnifyFetching && <ASTableV2RowSkeleton columns={5} rows={1} />}
            {!isUnifyFetching && !portErrors?.length && (
              <ASTableV2RowEmpty>{t("no results")}</ASTableV2RowEmpty>
            )}
            {!isUnifyFetching &&
              portErrors
                ?.toSorted((a, b) =>
                  dayjs(a.item.timestamp).isBefore(dayjs(b.item.timestamp))
                    ? 1
                    : -1
                )
                .map((row, i) => (
                  <ASTableV2Row key={i}>
                    <ASTableV2Cell
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <RelativeTime
                        time={row.item.timestamp}
                        variant="tableBody"
                      />
                      <Typography>
                        {dayjs(row.item.timestamp).format(globalDateTimeFormat)}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portErrorCode}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portError}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.portId}
                      </Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="tableBody">
                        {row.item.status}
                      </Typography>
                    </ASTableV2Cell>
                  </ASTableV2Row>
                ))}
          </ASTableV2Body>
        </ASTableV2>
      )}
    </>
  );
};
