import { Box } from "@mui/system";
import { ErrorPanel } from "@qubit/autoparts";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { addEvent } from "~/features/workstationTest/workstationTest.slice";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  useClosePortMutation,
  useOpenPortForContentMutation,
  useRequestNextBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";

import Port from "./Port";

type WorkstationPortInheritedProps = {
  portId: number;
  binToPortTestLoopingEnabled?: boolean;
};

const WorkstationPort = ({
  portId,
  binToPortTestLoopingEnabled = false
}: WorkstationPortInheritedProps) => {
  const dispatch = useAppDispatch();
  const [openPortForContent, { error: openPortError }] =
    useOpenPortForContentMutation();
  const [openBin, { error: openBinError }] = useRequestNextBinMutation();
  const [closePort] = useClosePortMutation();
  const workstation = useAppSelector(selectThisWorkstation);
  const targetPorts = workstation?.ports.filter(
    (port) => port.parentPortId === portId || port.portId === portId
  );

  useEffect(() => {
    if (!workstation) return;

    void (async () => {
      try {
        await openPortForContent({
          autostoreGridId: workstation.autostoreGridId,
          portId,
          configurationTypes: [1]
        }).unwrap();

        await openBin({
          autostoreGridId: workstation.autostoreGridId,
          portId
        }).unwrap();
      } catch {
        // error handled by error panel
      }
    })();

    return () => {
      void closePort({
        autostoreGridId: workstation.autostoreGridId,
        portId
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openPortError) {
      dispatch(
        addEvent({
          text: `Bin to Port: failed opening port ${portId}`,
          state: "Failed",
          testOrigin: "Bin to Port"
        })
      );
    }
    if (openBinError) {
      dispatch(
        addEvent({
          text: `Bin to Port: failed getting next bin for port ${portId}`,
          state: "Failed",
          testOrigin: "Bin to Port"
        })
      );
    }
  }, [openPortError, openBinError, dispatch, portId]);
  const shouldShowErrorPanel = !!openPortError || !!openBinError;

  return (
    <Box
      sx={{
        gridColumn: workstation?.multiPortEnabled ? "span 2" : "unset"
      }}
    >
      {targetPorts?.map((p, i) => (
        <Port
          key={p.portId}
          portId={portId}
          portSideIndex={i}
          binToPortTestLoopingEnabled={binToPortTestLoopingEnabled}
        />
      ))}
      {shouldShowErrorPanel && (
        <ErrorPanel
          style={{
            maxWidth: "300px",
            margin: "10px auto"
          }}
          message={getMessageFromRtkError(openPortError || openBinError)}
        />
      )}
    </Box>
  );
};

export default WorkstationPort;
