import { warehouseApi as api } from "./warehouseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchBin: build.query<SearchBinApiResponse, SearchBinApiArg>({
      query: (queryArg) => ({ url: `/bins/search/${queryArg.searchText}` })
    }),
    getBinsByFlaggedReason: build.query<
      GetBinsByFlaggedReasonApiResponse,
      GetBinsByFlaggedReasonApiArg
    >({
      query: (queryArg) => ({
        url: `/bins/autostore/grid/${queryArg.autostoreGridId}/by-flagged-reason/${queryArg.flaggedReason}`
      })
    }),
    getBinCompartments: build.query<
      GetBinCompartmentsApiResponse,
      GetBinCompartmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/bins/autostore/compartments`,
        params: {
          gridId: queryArg.gridId,
          searchText: queryArg.searchText,
          limit: queryArg.limit
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as binsApi };
export type SearchBinApiResponse = /** status 200 OK */ SearchBinRecord[];
export type SearchBinApiArg = {
  searchText: string;
};
export type GetBinsByFlaggedReasonApiResponse =
  /** status 200 OK */ AutostoreBin[];
export type GetBinsByFlaggedReasonApiArg = {
  /** Autostore grid Id */
  autostoreGridId: string;
  flaggedReason: FlaggedReason;
};
export type GetBinCompartmentsApiResponse =
  /** status 200 OK */ GetCompartmentsRecord[];
export type GetBinCompartmentsApiArg = {
  /** Autostore Grid Id */
  gridId: string;
  /** The search text to filter bins by */
  searchText: string;
  /** How many search results to return */
  limit?: number | null;
};
export type SearchBinRecord = {
  bin_id: string;
  bin_type: string;
  location?: string;
  temperature_zone?: string;
  autostore_compartment_number?: number | null;
};
export type FlaggedReason =
  | "dirty"
  | "damaged"
  | "misconfigured"
  | "scheduled"
  | "bin locked"
  | "bin removed"
  | "exterior maintenance";
export type AutostoreBin = {
  BinId: string;
  AutostoreBinId: number;
  AutostoreGridId: string;
  BinConfigurationId: string;
  Content: number;
  IsFlagged: boolean;
  FlaggedReason: FlaggedReason[];
  FlaggedTime?: string | null;
};
export type GetCompartmentsRecord = {
  autostore_bin_number: number;
  warehouse_bin_ids: string[];
};
export const {
  useSearchBinQuery,
  useGetBinsByFlaggedReasonQuery,
  useGetBinCompartmentsQuery
} = injectedRtkApi;
