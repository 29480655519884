import { Tabs } from "@mui/base/Tabs";
import { Refresh } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";

import {
  ContainedTab,
  ContainedTabPanel,
  ContainedTabsList,
  RelativeTime
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import {
  useGetUnifyPortErrorsQuery,
  useGetUnifyRobotErrorsQuery
} from "~/redux/warehouse/unify.openApi";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { UnifyPortErrors } from "./UnifyPortErrors";
import { UnifyRobotErrors } from "./UnifyRobotErrors";
import {
  setSelectedErrorType,
  setSelectedGridId,
  setSelectedWorkstationId,
  UnifyErrorType,
  unifyErrorTypes
} from "./serviceSupport.slice";

dayjs.extend(relativeTime);

export const UnifyView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const workstation = useAppSelector(selectThisWorkstation);
  const selectedGridId = useAppSelector(
    (state) => state.serviceSupport.selectedGridId
  );
  const selectedWorkstationId = useAppSelector(
    (state) => state.serviceSupport.selectedWorkstationId
  );
  const selectedErrorType = useAppSelector(
    (state) => state.serviceSupport.selectedErrorType
  );
  const { sortedWorkstations } = useGetWorkstationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      sortedWorkstations: data?.toSorted(
        (a, b) =>
          a.autostoreGridName.localeCompare(b.autostoreGridName) ||
          a.deviceId.localeCompare(b.deviceId)
      )
    })
  });

  const {
    fulfilledTimeStamp: portErrorsFulfilledTimeStamp,
    refetch: refetchPortErrors,
    isFetching: isPortErrorsFetching,
    isUninitialized: isPortErrorsUninitialized
  } = useGetUnifyPortErrorsQuery(
    selectedGridId
      ? {
          autostoreGridId: selectedGridId,
          workstationId: selectedWorkstationId,
          limit: null
        }
      : skipToken,
    { pollingInterval: 60000 }
  );

  const {
    fulfilledTimeStamp: robotErrorsFulfilledTimeStamp,
    refetch: refetchRobotErrors,
    isFetching: isRobotErrorsFetching,
    isUninitialized: isRobotErrorsUninitialized
  } = useGetUnifyRobotErrorsQuery(
    selectedGridId
      ? {
          autostoreGridId: selectedGridId,
          workstationId: selectedWorkstationId,
          limit: null
        }
      : skipToken,
    { pollingInterval: 60000 }
  );

  useEffect(() => {
    if (workstation || autostoreGrids?.length !== 1) return;

    dispatch(setSelectedGridId(autostoreGrids[0].autostoreGridId));
  }, [workstation, autostoreGrids, dispatch]);

  useEffect(() => {
    if (!workstation) return;

    dispatch(setSelectedGridId(workstation.autostoreGridId));
    dispatch(setSelectedWorkstationId(workstation.id));
  }, [dispatch, workstation]);

  return (
    <Stack sx={{ m: 3, gap: 2 }}>
      <Paper
        sx={{
          p: 2
        }}
      >
        <Typography variant="h6">{t("unify")}</Typography>
        <Typography>{t("unify description")}</Typography>
        <Tabs
          value={selectedErrorType}
          onChange={(_event, newValue) =>
            dispatch(setSelectedErrorType(newValue as UnifyErrorType))
          }
        >
          <Box
            my={2}
            display="grid"
            gridTemplateColumns="repeat(2, minmax(250px, auto)) 1fr"
            gridTemplateRows="repeat(2, auto)"
            gridAutoFlow="column"
            gap={2}
          >
            <FormControl sx={{ gridRow: "span 2" }}>
              <FormLabel id="grid-select">{t("autostore grid")}</FormLabel>
              <Select
                labelId="grid-select"
                variant="outlined"
                value={selectedGridId ?? ""}
                onChange={(e: SelectChangeEvent) =>
                  dispatch(setSelectedGridId(e.target.value))
                }
                size="medium"
                displayEmpty
              >
                <MenuItem value="">{t("none")}</MenuItem>
                {autostoreGrids?.map((grid) => (
                  <MenuItem
                    key={grid.autostoreGridId}
                    value={grid.autostoreGridId}
                  >
                    {`${grid.autostoreGridName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ gridRow: "span 2" }}>
              <FormLabel id="workstation-select">{t("workstation")}</FormLabel>
              <Select
                labelId="workstation-select"
                variant="outlined"
                value={sortedWorkstations ? selectedWorkstationId : ""}
                onChange={(e: SelectChangeEvent) =>
                  dispatch(setSelectedWorkstationId(e.target.value))
                }
                size="medium"
                displayEmpty
              >
                <MenuItem value="">
                  <em>{t("all")}</em>
                </MenuItem>
                {sortedWorkstations?.map((workstation) => (
                  <MenuItem key={workstation.deviceId} value={workstation.id}>
                    {`${workstation.deviceId}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              justifySelf="right"
            >
              {(selectedErrorType === "Port Errors"
                ? !isPortErrorsUninitialized
                : !isRobotErrorsUninitialized) && (
                <>
                  <Typography>{t("last updated")}: </Typography>
                  <RelativeTime
                    time={
                      selectedErrorType === "Port Errors"
                        ? portErrorsFulfilledTimeStamp
                        : robotErrorsFulfilledTimeStamp
                    }
                  />
                  <Button
                    startIcon={<Refresh />}
                    variant="subtle"
                    disabled={isPortErrorsFetching || isRobotErrorsFetching}
                    onClick={async () => {
                      if (selectedErrorType === "Port Errors") {
                        await refetchPortErrors();
                      } else {
                        await refetchRobotErrors();
                      }
                    }}
                  >
                    {t("refresh")}
                  </Button>
                </>
              )}
            </Stack>
            <Box alignSelf="end" justifySelf="right" my={1}>
              <ContainedTabsList>
                {unifyErrorTypes.map((errorType) => (
                  <ContainedTab key={errorType} value={errorType}>
                    {t(errorType as Lowercase<UnifyErrorType>)}
                  </ContainedTab>
                ))}
              </ContainedTabsList>
            </Box>
          </Box>
          {!!autostoreGrids && !selectedGridId && (
            <Alert sx={{ mb: 2 }} severity="info">
              {t("no autostore grid selected")}
            </Alert>
          )}
          <ContainedTabPanel value="Port Errors">
            <UnifyPortErrors />
          </ContainedTabPanel>
          <ContainedTabPanel value="Robot Errors">
            <UnifyRobotErrors />
          </ContainedTabPanel>
        </Tabs>
      </Paper>
    </Stack>
  );
};
