import * as Sentry from "@sentry/react";

import { isAxiosError } from "~/api/shared";

import { isFetchBaseQueryError } from "./isFetchBaseQueryError";

/**
 * Returns a status code for either Axios or fetch errors
 */
export const getStatusCodeFromError = (error: unknown): number | undefined => {
  if (isFetchBaseQueryError(error)) {
    if (typeof error.status === "number") {
      return error.status;
    } else if (error.status === "FETCH_ERROR") {
      return undefined;
    } else {
      Sentry.captureMessage(
        `Error was not known: ${JSON.stringify(error)}`,
        "warning"
      );
      return undefined;
    }
  } else if (isAxiosError(error)) {
    return error.status || 404;
  } else {
    Sentry.captureMessage(
      `Error was not known: ${JSON.stringify(error)}`,
      "warning"
    );
    return undefined;
  }
};
