import ThreeDots from "@agney/react-loading/src/svg-loaders/three-dots.svg?react";
import MessageUserIcon from "@locaisolutions/icons/dist/icons20px/Chat20Px";

import { Chip, IconButton, Typography, Box } from "@mui/material";
import { ASTableV2Cell, ASTableV2Row } from "@qubit/autoparts";

import { Fragment } from "react/jsx-runtime";

import { Recipient } from "~/features/andon/Messenger";
import { AndonWorkstation } from "~/features/andon/andon.slice";
import {
  extractDisplayName,
  extractReceipientId,
  extractUserNameFromAuth0Id
} from "~/lib/helpers";

interface AdminSummariesTableProps {
  activeWorkstation: AndonWorkstation;
  openMessageComponent: (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => void;
  portErrorsCount?: number;
  isFetchingPortErrors?: boolean;
}
const AdminSummariesContentRow = (props: AdminSummariesTableProps) => {
  const { activeWorkstation, openMessageComponent } = props;

  const { workstation, parentPort, ports, id } = activeWorkstation;
  const { deviceId, userId, mode, roles } = workstation;

  const parentPortsDisplay = parentPort?.portId || "-";
  const portsDisplay = ports?.map((port) => port.portId).join(", ") || "-";
  const workstationMode = mode !== "Undefined" ? mode : "-";

  return (
    <Fragment key={id}>
      <ASTableV2Row aria-label="active workstation row">
        <ASTableV2Cell>
          <Typography variant="tableBody">{deviceId}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">
            {userId ? extractUserNameFromAuth0Id(userId) : "-"}
          </Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{parentPortsDisplay}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{portsDisplay}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{workstationMode}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Box gap={0.5} display="grid" gridTemplateColumns="repeat(2, auto)">
            {roles.map((role) => (
              <Chip label={role} color="1" key={`aws-${id}-${role}`} />
            ))}
          </Box>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <IconButton
            aria-label="message user"
            onClick={(event) => {
              openMessageComponent(event, [
                {
                  workstationUserId: userId,
                  recipientId: extractReceipientId(userId),
                  displayName: extractDisplayName(userId)
                }
              ]);
            }}
          >
            <MessageUserIcon width={30} height={30} />
          </IconButton>
        </ASTableV2Cell>
        <ASTableV2Cell aria-label="port error count">
          <Chip
            color="5"
            label={
              props.isFetchingPortErrors ? (
                <ThreeDots color="white" width="18" />
              ) : (
                <Typography variant="tableBody">
                  {props.portErrorsCount || "-"}
                </Typography>
              )
            }
            sx={{
              borderRadius: "4px",
              minWidth: "40px",
              height: "45px",
              fontSize: "18px"
            }}
          />
        </ASTableV2Cell>
      </ASTableV2Row>
    </Fragment>
  );
};

export default AdminSummariesContentRow;
