import {
  CompleteCountingTaskRequest,
  IncompleteCycleCountsResponse,
  SetCycleCountFrequencyRequest,
  StartCycleCountingRequestDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const cycleCountsOverhaulApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getOverhaulIncompleteCycleCounts: build.query<
      IncompleteCycleCountsResponse,
      {
        limit?: number;
        offset?: number;
        gridId?: Guid;
        AutoStoreBins: boolean;
        variantId?: Guid;
      }
    >({
      query: ({ limit, offset, gridId, AutoStoreBins, variantId }) => {
        return {
          url: "/cycle-counts-overhaul/incomplete-cycle-counts",
          params: { limit, offset, gridId, AutoStoreBins, variantId }
        };
      },
      providesTags: ["cycle count data"]
    }),
    postStartCycleCounting: build.mutation<
      void,
      {
        cycleCountIds: Guid[] | undefined;
        gridId: Guid | undefined;
        workstationId: Guid | undefined;
      }
    >({
      query: ({ cycleCountIds, gridId, workstationId }) => {
        const request: StartCycleCountingRequestDto = {
          cycleCountIds: cycleCountIds ?? []
        };

        return {
          url: `/autostore-grid/${gridId}/workstation/${workstationId}/start-cycle-counting`,
          method: "POST",
          body: request
        };
      }
    }),
    postCompleteCountingTask: build.mutation<void, CompleteCountingTaskRequest>(
      {
        query: ({
          cycleCountId,
          inventoryId,
          actualCount,
          actualExpiration,
          actualManufactureDate,
          autostoreGridId,
          autostorePortId,
          workstationId
        }) => {
          return {
            url: `/cycle-counts-overhaul/complete-counting-task`,
            method: "POST",
            body: {
              CycleCountId: cycleCountId,
              InventoryId: inventoryId,
              ActualCount: actualCount,
              ActualExpiration: actualExpiration,
              ActualManufactureDate: actualManufactureDate,
              AutostoreGridId: autostoreGridId,
              AutostorePortId: autostorePortId,
              WorkstationId: workstationId
            }
          };
        }
      }
    ),
    putCycleCountFrequency: build.mutation<
      void,
      {
        variantId: Guid;
        frequency: number;
        workstationId?: Guid;
      }
    >({
      query: ({ variantId, frequency, workstationId }) => {
        const args: SetCycleCountFrequencyRequest = {
          variantId,
          frequency,
          ...(workstationId && { workstationId })
        };
        return {
          url: `/cycle-counts-overhaul/set-frequency`,
          method: "PUT",
          body: args
        };
      }
    })
  }),
  overrideExisting: false
});
