import { AppDispatch } from "~/app/store";

import { fetchPortStatus, NextEmptyBinSuccessAction } from "~/redux/actions";
import { BinStateDto, Point2D, PortStateDto } from "~/types/api";

import { autostoreGridApi } from "./autostoreGrid";
import {
  streamGetAutostoreGridConnectionModeUpdates,
  streamGetBinsOutsideGridUpdates,
  streamGetLogPublisherStateUpdates,
  streamGetPortStatusUpdates
} from "./autostoreGrid.streaming";
import {
  transformGetAutostoreGridStatus,
  transformGetLogPublisherState
} from "./autostoreGrid.transforms";

export type LogPublisherGridStateResponseTransformed = {
  cells: (Point2D & { isPort: boolean })[];
  rows: number;
  columns: number;
  binsByPos: Record<string, BinStateDto[]>;
  portsByPos: Record<string, PortStateDto[]>;
};

export const {
  useGetNumberOfFlaggedBinsQuery,
  useGetBinsOutsideGridQuery,
  usePostStartInventoryMovementsQuery,
  useLazyPostStartInventoryMovementsQuery,
  useGetInventoryMovementsStateQuery,
  useLazyGetInventoryMovementsStateQuery,
  usePostConfirmInventoryMovementMutation,
  usePostSkipInventoryMovementMutation,
  usePostBinNotEmptyInventoryMovementMutation,
  useGetAutostoreGridQuery,
  useGetAutostoreGridsQuery,
  useGetAutostoreGridStatusQuery,
  useGetAutostoreGridConnectionModeQuery,
  useEditCleaningScheduleMutation,
  useGetBinCleaningReportQuery,
  useLazyGetAutostoreGridStatusQuery,
  useStartBinCleaningMutation,
  useReconfigureBinCompartmentsMutation,
  useGetBinQuery,
  useLazyGetBinQuery,
  useDeleteBinFlagMutation,
  useGetFlaggedBinsQuery,
  useGetPortStatusQuery,
  useGetAutostoreTasksQuery,
  useDeleteTaskGroupMutation,
  useDeleteTaskMutation,
  usePostFlagBinMutation,
  usePostFlagBinRemovedMutation,
  useCloseBinMutation,
  useRequestNextBinMutation,
  useSkipInductionBinMutation,
  useClosePortMutation,
  useNextEmptyBinMutation,
  useOpenPortMutation,
  useOpenPortForContentMutation,
  useShowPickToLightMutation,
  useGetLogPublisherStateQuery,
  useEmptyBinReportQuery,
  useAvailableBinReportQuery,
  useStartPickingOnWorkstationMutation,
  useLazySyncGridStateQuery
} = autostoreGridApi.enhanceEndpoints({
  addTagTypes: [],
  endpoints: {
    getBinsOutsideGrid: {
      onCacheEntryAdded: streamGetBinsOutsideGridUpdates
    },
    getAutostoreGridStatus: {
      transformResponse: transformGetAutostoreGridStatus
    },
    getAutostoreGridConnectionMode: {
      onCacheEntryAdded: streamGetAutostoreGridConnectionModeUpdates
    },
    getPortStatus: {
      onCacheEntryAdded: streamGetPortStatusUpdates
    },
    getLogPublisherState: {
      transformResponse: transformGetLogPublisherState,
      onCacheEntryAdded: streamGetLogPublisherStateUpdates
    },
    reconfigureBinCompartments: {
      invalidatesTags: ["autostore bin", "flagged bins"]
    },
    skipInductionBin: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        // This action is needed for the bin to show the new bin number
        (dispatch as AppDispatch)<NextEmptyBinSuccessAction>({
          type: "autostore/NEXT_EMPTY_BIN_SUCCESS",
          payload: {
            nextEmptyBin: data,
            portId: data.openBinResponse.portId
          }
        });

        // The responses's `isReady: false` will kick off port polling
        await dispatch(
          fetchPortStatus({ portId: data.openBinResponse.portId })
        );
      }
    }
  }
});
