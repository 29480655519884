import { ArrowRight24Px } from "@locaisolutions/icons";
import { Stack, styled } from "@mui/material";

type PulsingArrowProps = {
  delay: number;
};

const PulsingArrow = styled(ArrowRight24Px)<PulsingArrowProps>(
  ({ theme, delay }) => ({
    "@keyframes pulse": {
      from: {
        opacity: 0.1
      },
      to: { opacity: 1 }
    },
    animation: "pulse 800ms infinite steps(3, jump-none)",
    animationDirection: "alternate",
    animationDelay: `${delay}ms`,
    fill: theme.palette.primary.main,
    height: "40px",
    width: "20px"
  })
);

export const PulsingArrows = () => {
  return (
    <Stack direction="row" alignItems="center" m={2}>
      <PulsingArrow delay={0} />
      <PulsingArrow delay={500} />
      <PulsingArrow delay={1000} />
    </Stack>
  );
};
