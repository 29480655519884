import { createSvgIcon } from "@mui/material";

export const BarcodeSvg = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="400"
    height="200"
    viewBox="0 0 229 110"
  >
    <g>
      <rect x="0" y="0" width="229" height="100" fill="white" rx={5} ry={5} />
      <g transform="translate(10 10)">
        <g>
          <rect x="10" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="13" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="16" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="21" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="26" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="29" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="32" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="35" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="39" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="43" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="46" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="48" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="54" y="0" width="4" height="80" fill="url(#black)" />
          <rect x="59" y="0" width="3" height="80" fill="url(#black)" />
          <rect x="63" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="65" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="68" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="72" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="76" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="79" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="81" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="87" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="90" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="94" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="98" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="100" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="103" y="0" width="4" height="80" fill="url(#black)" />
          <rect x="109" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="111" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="115" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="120" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="123" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="125" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="131" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="136" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="138" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="142" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="146" y="0" width="4" height="80" fill="url(#black)" />
          <rect x="151" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="153" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="158" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="160" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="164" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="170" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="173" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="175" y="0" width="4" height="80" fill="url(#black)" />
          <rect x="180" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="184" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="186" y="0" width="2" height="80" fill="url(#black)" />
          <rect x="191" y="0" width="3" height="80" fill="url(#black)" />
          <rect x="195" y="0" width="1" height="80" fill="url(#black)" />
          <rect x="197" y="0" width="2" height="80" fill="url(#black)" />
          <text
            x="104.5"
            y="90"
            style={{
              fontSize: "12px",
              fontFamily: "monospace",
              textAnchor: "middle",
              margin: "5px"
            }}
          >
            i am a peacock
          </text>
        </g>
        <defs>
          <linearGradient id="black" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#000" />
            <stop offset="100%" stopColor="#000" />
          </linearGradient>
          <linearGradient id="navyBlue" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#00379c" />
            <stop offset="100%" stopColor="#00379c" />
          </linearGradient>
          <linearGradient id="midnightGreen" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#008000" />
            <stop offset="100%" stopColor="#008000" />
          </linearGradient>
          <linearGradient id="holo" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#ae00ff" />
            <stop offset="100%" stopColor="#009dff" />
          </linearGradient>
          <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#d85bc6" />
            <stop offset="100%" stopColor="#5c4cff" />
          </linearGradient>
          <linearGradient id="neon" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#00c400" />
            <stop offset="100%" stopColor="#00612a" />
          </linearGradient>
        </defs>
      </g>
    </g>
  </svg>,
  "BarcodeSvg"
);
