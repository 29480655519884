import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  FormLabel
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import { useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { clearAndonWorkstationsAndGrids } from "~/features/andon/andon.slice";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { updateUsersFulfillmentCenter } from "~/redux/actions/store";
import { setSiteFcId, setThisWorkstation } from "~/redux/actions/workstations";
import { useGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";

/**
 * Renders a dropdown to set the device's fulfillment center from a list of all
 * fulfillment centers belonging to an organization.
 */
export const FulfillmentCenterSelect = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const fulfillmentCenterId = useAppSelector((state) =>
    state.workstations.siteFulfillmentCenterId
      ? state.workstations.siteFulfillmentCenterId
      : state.store.usersFulfillmentCenter
        ? state.store.usersFulfillmentCenter.fulfillmentCenterId
        : null
  );
  const {
    isFetching: fulfillmentCentersFetching,
    data: fulfillmentCenters,
    error: fulfillmentCentersFetchError,
    refetch: refetchFulfillmentCenters
  } = useGetAllFulfillmentCentersQuery();

  const handleSelectionSelected = async (fcId: string) => {
    try {
      // redux state, probably belongs in reducer?
      dispatch(setSiteFcId(fcId));
      dispatch(clearAndonWorkstationsAndGrids());
      await dispatch(setThisWorkstation(null, true));
      await dispatch(updateUsersFulfillmentCenter(fcId));

      dispatch(
        warehouseApi.util.invalidateTags(["autostore grid", "workstation"])
      );
      // toast
      const selectedFc = fulfillmentCenters
        ? fulfillmentCenters.find((fc) => fc.fulfillmentCenterId === fcId)
        : null;

      const successMessage: string = selectedFc
        ? `Your Device's Fulfillment Center has been updated to ${selectedFc.name}`
        : // odd case where we couldn't actually find the fulfillment center
          "Your Device's Fulfillment Center has been updated";

      successToast("Fulfillment Center Updated", {
        description: successMessage
      });
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  if (fulfillmentCentersFetching) {
    return (
      <Grid item xs={6}>
        <Skeleton variant="rectangular" />
      </Grid>
    );
  }

  if (fulfillmentCentersFetchError) {
    return (
      <Grid item xs={6}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <Button onClick={refetchFulfillmentCenters}>{t("retry")}</Button>
          }
        >
          {getMessageFromRtkError(fulfillmentCentersFetchError)}
        </Alert>
      </Grid>
    );
  }

  return (
    <>
      {fulfillmentCenters?.length && (
        <FormControl sx={{ maxWidth: "sm" }}>
          <FormLabel id="fulfillment-center-select-label">
            {t("fulfillment center")}
          </FormLabel>
          <FormHelperText>
            {t("selected fulfillment center description")}
          </FormHelperText>
          <Select
            id="fulfillment-center-select"
            labelId="fulfillment-center-select-label"
            size="small"
            variant="outlined"
            value={fulfillmentCenterId ? fulfillmentCenterId : ""}
          >
            {fulfillmentCenters.map((fc) => (
              <MenuItem
                key={fc.fulfillmentCenterId}
                value={fc.fulfillmentCenterId}
                onClick={() => handleSelectionSelected(fc.fulfillmentCenterId)}
              >
                {fc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
